import React from 'react';
import styles from './link-view.module.scss';
import { ElementDto } from 'api';
import { cutTextAtLength } from 'utils/text';
import { convertJsonToLinkElement } from './utils/convert-json-to-link-element';
import { Icon } from 'components/svg/icons';
import { useClassName } from 'hooks/use-class-name';

interface ILinkView {
	element: ElementDto;
	userCanEdit?: boolean;
	setEditMode: () => void;
}

export function LinkView({ element, userCanEdit, setEditMode }: ILinkView) {
	const {
		data: { emne, beskrivelse, url, image },
	} = convertJsonToLinkElement(element);

	const wrapperClassName = useClassName(
		styles.wrapper,
		userCanEdit ? styles.editable : null,
	);

	const descriptionCuttingLength = 100;
	const descriptionLength = beskrivelse?.length || 0;
	const isShortDescriptionCutted =
		descriptionLength > descriptionCuttingLength;

	const shortDescription = isShortDescriptionCutted
		? cutTextAtLength(beskrivelse, descriptionCuttingLength)
		: beskrivelse;

	return (
		<div className={wrapperClassName}>
			<div className={styles.preview}>
				<div className={styles.iconPreviewContainer}>
					{image?.length ? (
						<a
							href={url}
							target='_blank'
							rel='noopener noreferrer'
							title={`Gå til ${url}`}
							className={styles.image}
							style={{ backgroundImage: `url('${image}')` }}
						>
							{url}
						</a>
					) : (
						<a
							href={url}
							target='_blank'
							rel='noopener noreferrer'
							title={`Gå til ${url}`}
							className={styles.url}
						>
							<Icon icon='link' size='48px' />
						</a>
					)}
				</div>
				<div>
					<a
						href={url}
						target='_blank'
						rel='noopener noreferrer'
						title={`Gå til ${url}`}
						className={styles.url}
					>
						{url}
					</a>
					<div
						className={styles.element}
						onClick={userCanEdit ? setEditMode : undefined}
					>
						<div className={styles.title}>{emne}</div>
						<div
							title={beskrivelse}
							className={styles.shortDescription}
						>
							{shortDescription}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
