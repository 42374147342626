import React, { useState } from 'react';
import { ForfatterDto } from 'api';
import { Editor } from 'components/editor/Editor';
import { Author } from './components/author/Author';
import { SearchAuthors } from './components/search-authors/SearchAuthors';

import { useRecoilValue } from 'recoil';
import { userState, UserStateType } from '../../../../coils/useRecoilUser';

import styles from './authors-edit.module.scss';
import { Button } from 'components/buttons/button/Button';
import { EditorTitle } from 'components/editor/components/editor-title/EditorTitle';

type AuthorsEditprops = {
	authors?: ForfatterDto[];
	addAuthor?: (author: ForfatterDto) => void;
	changeAuthors: (authors: ForfatterDto[]) => void;
	leaveEditMode: () => void;
};

export function AuthorsEdit(props: AuthorsEditprops) {
	const userData: UserStateType = useRecoilValue(userState);

	const [selectedAuthors, setSelectedAuthors] = useState<ForfatterDto[]>(
		props.authors || [],
	);

	const handleAddAuthor = (author: ForfatterDto) => {
		const indexOfAuthor = selectedAuthors
			.map(author => author.userId)
			.indexOf(author.userId);

		if (indexOfAuthor === -1) {
			setSelectedAuthors([...selectedAuthors, author]);
		}
	};

	const handleRemoveAuthor = (author: ForfatterDto) => {
		setSelectedAuthors(
			selectedAuthors.filter(item => item.userId !== author.userId),
		);
	};

	const isAuthorCurrentUser = (author: ForfatterDto) => {
		return author.email === userData.user?.name;
	};

	const handleSave = () => {
		props.changeAuthors(selectedAuthors);
	};

	return (
		<Editor
			title={<EditorTitle title='Forfattere' />}
			onCancel={props.leaveEditMode}
			onSave={handleSave}
		>
			<div className={styles.selectedAuthors}>
				{selectedAuthors?.map((author, key) => (
					<div key={key}>
						<Author
							className={styles.author}
							key={key}
							name={author.email}
							email={author.email}
						/>{' '}
						{!isAuthorCurrentUser(author) && (
							<Button
								className={styles.removeAuthorButton}
								variant='inline'
								onClick={() => handleRemoveAuthor(author)}
							>
								Fjern
							</Button>
						)}
					</div>
				))}
			</div>
			<SearchAuthors addAuthor={handleAddAuthor} />
		</Editor>
	);
}
