import React from 'react';
import styles from './subjects.module.scss';
import { SubjectsView } from './SubjectsView';
import { SubjectsEdit } from './SubjectsEdit';
import { FagDto } from '../../../../api';
import { EditControlsType, SaveType } from '../../course.types';

export const Subjects = React.memo(function Subjects({
	name,
	selectedSubjects = [],
	changeSubjects,
	editControls,
	save,
}: {
	name: string;
	selectedSubjects: FagDto[] | undefined;
	changeSubjects: (subjects: FagDto[]) => void;
	editControls: EditControlsType;
	save: SaveType;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (!userCanEdit && !selectedSubjects.length) {
		return null;
	}

	const subjectsEditor = editMode ? (
		<SubjectsEdit
			leaveEditMode={leaveEditMode}
			name={name}
			selectedSubjects={selectedSubjects}
			changeSubjects={changeSubjects}
			save={save}
		/>
	) : null;

	return (
		<div className={styles.subjects}>
			<SubjectsView
				setEditMode={setEditMode}
				name={name}
				subjects={selectedSubjects}
				userCanEdit={userCanEdit}
			/>
			{subjectsEditor}
		</div>
	);
});
