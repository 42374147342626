import React from 'react';
import styles from './youtube.module.scss';

import { YouTubeEdit } from './YouTubeEdit';
import { YouTubeView } from './YouTubeView';

import { ElementDto } from 'api';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { ActionTypes } from 'modules/course/state/actions/course/types';

type YouTubeProps = {
	chapterId: string;
	pageId: string;
	element: ElementDto;
	editControls: EditControlsType;
	save: SaveType;
	changeElement: ActionTypes['changeElement'];
	deleteElement: ActionTypes['deleteElement'];
	videoControls: any;
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.youTube}>{children}</div>;
};

export function YouTube({
	chapterId,
	pageId,
	element,
	editControls,
	save,
	changeElement,
	deleteElement,
	videoControls,
}: YouTubeProps) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(
		element.id,
	);

	const handleChangeElement = (content: string) => {
		changeElement(chapterId, pageId, element.id, content);
	};

	const handleDeleteElement = () => {
		deleteElement(chapterId, pageId, element.id);
		save(element.id);
	};

	if (editMode) {
		return (
			<Wrapper>
				<YouTubeEdit
					name={element.id}
					element={element}
					changeElement={handleChangeElement}
					deleteElement={handleDeleteElement}
					leaveEditMode={leaveEditMode}
					videoControls={videoControls}
					save={save}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<YouTubeView
				userCanEdit={userCanEdit}
				videoControls={videoControls}
				element={element}
				name={element.id}
				setEditMode={setEditMode}
			/>
		</Wrapper>
	);
}
