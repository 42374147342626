import React from 'react';

export function Checkmark({ className }: { className?: string }) {
	return (
		<svg width='32px' height='32px' viewBox='0 0 128 128' version='1.1' className={className}>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/checkmark' fill='#FF5A5D'>
				<path
         			d="m 80.658248,48.358452 -24.505091,24.374746 -8.941752,-8.99389 c -1.251324,-1.251324 -3.258655,-1.251324 -4.509979,-0.02607 -1.251324,1.225255 -1.251324,3.258656 -0.02607,4.50998 l 11.183707,11.261914 c 0.625662,0.625662 1.433809,0.938493 2.268025,0.938493 0.808146,0 1.616293,-0.312831 2.241955,-0.938493 l 26.773116,-26.6167 c 1.251324,-1.251324 1.251324,-3.258656 0.02607,-4.50998 -1.251324,-1.251324 -3.258656,-1.251324 -4.50998,0 z"/>
				</g>
			</g>
		</svg>
	);
}
