import { YoutubeResponseItemDto } from '../../../../../../../api';

export const formatYoutubeToMuData = (
	youtubeElement?: YoutubeResponseItemDto,
) => {
	return {
		data: {
			id: {
				kind: 'youtube#video',
				videoId: youtubeElement?.id,
			},
			kind: 'youtube#searchResult',
			snippet: {
				channelId: '',
				channelTitle: '',
				description: youtubeElement?.description,
				liveBroadcastContent: 'none',
				publishTime: '',
				publishedAt: '',
				thumbnails: {
					default: {
						height: 90,
						url: youtubeElement?.thumbnail,
						width: 120,
					},
					high: {
						height: 360,
						url: youtubeElement?.thumbnailHigh,
						width: 480,
					},
					medium: {
						height: 180,
						url: youtubeElement?.thumbnailMedium,
						width: 320,
					},
				},
				title: youtubeElement?.title,
			},
			status: {
				embeddable: true,
				license: 'youtube',
				madeForKids: false,
				privacyStatus: 'public',
				publicStatsViewable: true,
				uploadStatus: 'processed',
			},
		},
		source: 'youtube',
	};
};
