import React, { useState } from 'react';
import { ADD_DESCRIPTION } from '../../course.lang';
import { TinyEditor } from '../../../../components/tiny-editor/TinyEditor';
import { Editor } from '../../../../components/editor/Editor';
import { SaveType } from 'modules/course/course.types';

const editorConfig = {
	placeholder: ADD_DESCRIPTION,
};

export function DescriptionEdit({
	name,
	text = '',
	leaveEditMode,
	changeDescription,
	save,
}: {
	name: string;
	text: string | undefined;
	leaveEditMode: (name: string) => void,
	changeDescription: (description: string) => void;
	save: SaveType,
}) {
	const [state, setState] = useState(text);

	const handleChangeDescription = (change: string) => {
		setState(change);
	};

	const handleSaveDescription = () => {
		changeDescription(state);
		save(name);
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	return (
		<Editor
			onSave={handleSaveDescription}
			onCancel={handleLeaveEditMode}
		>
			<TinyEditor
				config={editorConfig}
				model={state}
				onModelChange={handleChangeDescription}
			/>
		</Editor>
	);
}
