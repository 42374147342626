import React, { useState } from 'react';
import styles from './youtube-edit.module.scss';

import { LoadingThumbnailList } from './components/thumbnail-list/LoadingThumbnailList';
import { Search } from './components/search/Search';
import { Editor } from '../../../../../../../components/editor/Editor';
import { EditorTitle } from '../../../../../../../components/editor/components/editor-title/EditorTitle';
import { YouTubeIcon } from '../../../../../../../components/svg/icons/YouTubeIcon';
import { ThumbnailList } from './components/thumbnail-list/ThumbnailList';
import { SelectedVideo } from './components/selected-video/SelectedVideo';

import { formatYoutubeToMuData } from './utils';

import {
	YoutubeClient,
	IConfig,
	YoutubeResponseDto,
	YoutubeResponseItemDto,
	ElementDto,
} from 'api';

import { youtubeConfig } from './youtube-config';
import { SaveType } from 'modules/course/course.types';
import { Button } from 'components/buttons/button/Button';

import * as lang from './youtube.lang';

const Wrapper = ({
	leaveEditMode,
	save,
	children,
	isSaveDisabled,
}: {
	name: string;
	leaveEditMode: () => void;
	save: () => void;
	children: React.ReactNode;
	isSaveDisabled?: boolean;
}) => {
	return (
		<Editor
			onCancel={leaveEditMode}
			onSave={save}
			isSaveDisabled={isSaveDisabled}
			title={<EditorTitle title='YouTube' icon={<YouTubeIcon />} />}
		>
			<div className={styles.youtubeEdit}>{children}</div>
		</Editor>
	);
};

type YouTubeEditProps = {
	name: string;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement?: () => void;
	element?: ElementDto;
	save: SaveType;
	videoControls: any;
};

const youtubeClient = new YoutubeClient(new IConfig());

export function YouTubeEdit({
	name,
	leaveEditMode,
	changeElement,
	deleteElement,
	element,
	save,
	videoControls,
}: YouTubeEditProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedVideo, setSelectedVideo] =
		useState<YoutubeResponseItemDto>();
	const [searchResults, setSearchResults] = useState<
		YoutubeResponseDto | undefined
	>();

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	const handleSearchError = () => {
		setSearchResults(new YoutubeResponseDto({ items: [] }));
		setIsLoading(false);
	};

	const handleCancelVideoSelection = () => {
		setSelectedVideo(undefined);
	};

	const handleSearchYouTube = (search: string, pageToken?: string) => {
		handleCancelVideoSelection();
		setIsLoading(true);

		youtubeClient
			.get(search, pageToken, youtubeConfig.maxResults)
			.then((response) => setSearchResults(response))
			.then(() => setIsLoading(false))
			.catch(() => handleSearchError());
	};

	const handleSelectVideo = (video: YoutubeResponseItemDto) => {
		setSelectedVideo(video);
	};

	const handleSave = () => {
		changeElement(JSON.stringify(formatYoutubeToMuData(selectedVideo)));
		save(name);
		leaveEditMode(name);
	};

	const handleDelete = () => {
		deleteElement?.();
		leaveEditMode(name);
	};

	if (isLoading) {
		return (
			<Wrapper
				leaveEditMode={handleLeaveEditMode}
				save={() => {}}
				name={name}
			>
				<Search disabled performSearch={handleSearchYouTube}>
					<LoadingThumbnailList />
				</Search>
			</Wrapper>
		);
	}

	if (selectedVideo) {
		return (
			<Wrapper
				leaveEditMode={handleLeaveEditMode}
				save={handleSave}
				name={name}
			>
				<Search performSearch={handleSearchYouTube}>
					<SelectedVideo
						thumbnail={selectedVideo}
						videoControls={videoControls}
						onCancel={handleCancelVideoSelection}
					/>
				</Search>
			</Wrapper>
		);
	}

	const searchTokens = {
		previous: searchResults?.prevPageToken,
		next: searchResults?.nextPageToken,
	};

	return (
		<Wrapper
			leaveEditMode={handleLeaveEditMode}
			save={handleSave}
			name={name}
			isSaveDisabled={!Boolean(selectedVideo)}
		>
			<Search
				searchTokens={searchTokens}
				performSearch={handleSearchYouTube}
			>
				<ThumbnailList
					selectVideo={handleSelectVideo}
					thumbnails={searchResults?.items}
				/>
			</Search>
			{element && (
				<Button
					onClick={handleDelete}
					variant='danger'
					className={styles.deleteButton}
				>
					{lang.DELETE_VIDEO}
				</Button>
			)}
		</Wrapper>
	);
}
