import React from 'react';
import styles from './image-view.module.scss';
import imageIcon from './images/image-icon.svg';
import { ADD_IMAGE } from './image.lang';
import { useImage } from 'hooks/useImage';
import { useClassName } from 'hooks/use-class-name';

type ImageViewProps = {
	name: string;
	image?: string;
	setEditMode: (name: string, imgSrc?: string) => void;
	userCanEdit?: boolean;
	isCourseImage?: boolean;
};

export function ImageView(props: ImageViewProps) {
	const imgSrc = useImage(props.image);

	const imageClassNames = useClassName(
		styles.image,
		props.userCanEdit && styles.editable,
	);

	const editImage = () => {
		props.setEditMode(props.name, imgSrc);
	};

	if (!props.image) {
		return (
			<div onClick={editImage} className={styles.noImage}>
				<img
					className={styles.noImageIcon}
					src={imageIcon}
					alt={ADD_IMAGE}
				/>
				<h4 className={styles.noImageText}>{ADD_IMAGE}</h4>
			</div>
		);
	}

	if (props.isCourseImage) {
		return (
			<wc-course-image
				onClick={editImage}
				imgsrc={imgSrc}
			></wc-course-image>
		);
	}

	return (
		<div onClick={editImage} className={!imgSrc ? styles.imageView : ''}>
			<img className={imageClassNames} src={imgSrc} alt='' />
		</div>
	);
}
