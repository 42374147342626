import { FileUploadStateType, emptyState } from 'components/file/file-upload/FileUpload';
import { JSONTryParseWithDefaultValue } from 'utils/json';

export const convertJsonToImageElement = (
	image?: string,
): FileUploadStateType => {
	if (!image) return emptyState;

	return JSONTryParseWithDefaultValue<
		FileUploadStateType,
		FileUploadStateType
	>(image, emptyState);
};
