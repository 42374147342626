import React, { useMemo, useRef } from 'react';
import styles from './category-edit.module.scss';
import { Editor } from '../../../../components/editor/Editor';
import { Checkbox } from '../../../../components/checkbox/Checkbox';
import { categoryList } from './category-list';
import { SaveType } from '../../course.types';
import { useClickOutside } from 'hooks/useClickOutside';

export function CategoryEdit({
	name,
	categories,
	leaveEditMode,
	save,
	onChange,
}: {
	name: string;
	categories: string[];
	leaveEditMode: any;
	save: SaveType;
	onChange: (categories: string[]) => void;
}) {
	const initialState = useMemo(() => categories, []);

	const handleSave = () => {
		save(name);
		leaveEditMode(name);
	};

	const handleCancel = () => {
		leaveEditMode(name);
		onChange(initialState);
	};

	const editorRef = useRef(null);
	useClickOutside(editorRef, handleSave);

	const isCategorySelected = (category: string) =>
		categories.includes(category);

	const handleChange = (category: string) => () => {
		let newCategories;

		if (isCategorySelected(category)) {
			newCategories = categories.filter(
				(savedCategory) => savedCategory !== category,
			);
		} else {
			newCategories = [...categories, category];
		}

		onChange(newCategories);
	};

	return (
		<div ref={editorRef} className={styles.categoryEdit}>
			<Editor onCancel={handleCancel} onSave={handleSave}>
				<div className={styles.categoryEditContent}>
					{categoryList.map((category, key) => (
						<Checkbox
							key={key}
							isDisplayBlock
							checked={isCategorySelected(category)}
							value={category}
							onChange={handleChange(category)}
						>
							{category.toUpperCase()}
						</Checkbox>
					))}
				</div>
			</Editor>
		</div>
	);
}
