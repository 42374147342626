import React, { useState, useEffect } from 'react';
import styles from './blog-posts.module.scss';

import { BlogClient, IConfig, PostDto } from '../../api';
import { Thumbnail } from './components/thumbnail/Thumbnail';
import { LoadingBlogPosts } from './LoadingBlogPosts';

const blogClient = new BlogClient(new IConfig());

const HEADER = 'Nyt fra Spilbloggen';

export function BlogPosts() {
	const [isLoading, setIsLoading] = useState(false);
	const [blogs, setBlogs] = useState<PostDto[]>();

	useEffect(() => {
		setIsLoading(true);
		blogClient
			.get(2)
			.then((blogs) => setBlogs(blogs))
			.then(() => setIsLoading(false))
			.catch(() => setIsLoading(true));
	}, []);

	if (isLoading) {
		return <LoadingBlogPosts header={HEADER} />;
	}

	return (
		<div className={styles.blogPosts}>
			<h2>{HEADER}</h2>
			<div className={styles.thumbnailGrid}>
				{blogs?.map((blog, key) => (
					<Thumbnail
						key={key}
						title={blog.title}
						exert={blog.excerpt}
						url={blog.link}
						media={blog.media}
					/>
				))}
			</div>
			<div className={styles.linkContainer}>
				<a
					className={styles.link}
					href='https://spilblog.aau.dk/'
					target='_blank'
					rel='noopener noreferrer'
				>
					Gå til Spilbloggen
				</a>
				<span> · </span>
				<a
					className={styles.link}
					href='https://spilblog.aau.dk/?page_id=35'
					target='_blank'
					rel='noopener noreferrer'
				>
					Gå til Forum
				</a>
			</div>
		</div>
	);
}
