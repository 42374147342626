import React from 'react';

export function DragIcon() {
	return (
		<svg width='128px' height='128px' viewBox='0 0 128 128' version='1.1'>
			<title>flyt</title>
			<g
				id='drag'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g
					id='Group-2'
					transform='translate(41.000000, 29.000000)'
					fill='#4A4A4A'
				>
					<g
						id='Group'
						transform='translate(6.500000, 34.500000) rotate(90.000000) translate(-6.500000, -34.500000) translate(-28.000000, 28.000000)'
					>
						<circle id='Oval' cx='34.5' cy='6.5' r='6.5'></circle>
						<circle
							id='Oval-Copy-2'
							cx='62.5'
							cy='6.5'
							r='6.5'
						></circle>
						<circle
							id='Oval-Copy'
							cx='6.5'
							cy='6.5'
							r='6.5'
						></circle>
					</g>
					<g
						id='Group-Copy'
						transform='translate(39.500000, 34.500000) rotate(90.000000) translate(-39.500000, -34.500000) translate(5.000000, 28.000000)'
					>
						<circle id='Oval' cx='34.5' cy='6.5' r='6.5'></circle>
						<circle
							id='Oval-Copy-2'
							cx='62.5'
							cy='6.5'
							r='6.5'
						></circle>
						<circle
							id='Oval-Copy'
							cx='6.5'
							cy='6.5'
							r='6.5'
						></circle>
					</g>
				</g>
			</g>
		</svg>
	);
}
