import authService from '../components/api-authorization/AuthorizeService';
import { useRecoilState, atom, selector } from 'recoil';

export type UserStateType = {
	isAuthenticated: boolean;
	user?: {
		name: string;
		fullname: string;
		sid: string;
	}
};

export const userData = atom({
	key: 'user',
	default: { isAuthenticated: false, user: undefined },
});

export const userState = selector({
	key: 'userState',
	get: ({ get }) => {
		return get(userData);
	},
});

export function useRecoilUser() {
	const [, setState] = useRecoilState(userData);

	Promise.all([
		authService.isAuthenticated(),
		authService.getUser(),
	]).then(([isAuthenticated, user]) => {
		setState({ isAuthenticated, user });
	});
}
