import { useEffect, useCallback } from 'react';
import authService from '../components/api-authorization/AuthorizeService';
import { useRecoilState } from 'recoil';
import { userData } from '../coils/useRecoilUser';
import { HeartbeatClient, IConfig } from 'api';

type UserStateType = {
	isAuthenticated: boolean;
	user?: {
		name: string;
		fullname: string;
	};
};

const heartbeatClient = new HeartbeatClient(new IConfig());
const fiveMinutes = 5 * 60 * 1000;

export function useUser(): UserStateType {
	const [state, setState] = useRecoilState(userData);

	const handleHeartbeat = useCallback(async () => {
		setTimeout(async () => {
			await heartbeatClient.get();
			handleHeartbeat();
		}, fiveMinutes);
	}, []);

	const setLoginState = useCallback(() => {
		Promise.all([
			authService.isAuthenticated(),
			authService.getUser(),
		]).then(([isAuthenticated, user]) => {
			setState({ isAuthenticated, user });

			if (isAuthenticated) {
				handleHeartbeat();
			}
		});
	}, [setState, handleHeartbeat]);

	useEffect(() => {
		setLoginState();
	}, [setLoginState]);

	return state;
}
