import React, { useState } from 'react';

import { SiteLogo } from '../../site-logo/SiteLogo';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';

import {
	Collapse,
	Container,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from 'reactstrap';

import styles from './nav-menu.module.scss';
import './NavMenu.css';
import { useRecoilValue } from 'recoil';
import { userState, UserStateType } from '../../../coils/useRecoilUser';
import { UserMenuItem } from './items/UserMenuItem';
import { useClassName } from 'hooks/use-class-name';

export function NavMenu() {
	const [isCollapsed, setIsCollapsed] = useState(true);
	const toggleNavbar = () => setIsCollapsed(!isCollapsed);

	const userData: UserStateType = useRecoilValue(userState);

	const authLinkClasses = useClassName(
		styles.navLink,
		!userData.isAuthenticated && styles.disabled,
	);

	const loginItem = userData.isAuthenticated ? (
		<UserMenuItem
			email={userData.user?.name}
			userName={userData?.user?.fullname}
		/>
	) : (
		<NavLink
			cssModule={{ 'nav-link': styles.navLink }}
			tag={Link}
			to={ApplicationPaths.Login}
		>
			{routes.login.name}
		</NavLink>
	);

	return (
		<header>
			<Navbar
				className='navbar-expand-sm navbar-toggleable-sm ng-white'
				light
			>
				<Container>
					<NavbarBrand tag={Link} to={routes.home.path}>
						<SiteLogo />
					</NavbarBrand>
					<NavbarToggler onClick={toggleNavbar} className='mr-2' />
					<Collapse
						className='d-sm-inline-flex flex-sm-row-reverse'
						isOpen={!isCollapsed}
						navbar
					>
						<ul className='navbar-nav flex-grow'>
							<NavItem>
								<NavLink
									tag={Link}
									cssModule={{ 'nav-link': styles.navLink }}
									to={routes.aboutSiu.path}
								>
									{routes.aboutSiu.name}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									tag={Link}
									cssModule={{ 'nav-link': styles.navLink }}
									to={routes.knowledgeOfGames.path}
								>
									{routes.knowledgeOfGames.name}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									tag={Link}
									cssModule={{ 'nav-link': authLinkClasses }}
									disabled={!userData.isAuthenticated}
									to={routes.myCourses.setPath(
										userData.user?.sid,
									)}
								>
									{routes.myCourses.name}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									tag={Link}
									cssModule={{ 'nav-link': authLinkClasses }}
									disabled={!userData.isAuthenticated}
									to={routes.favoriteCourses.setPath(
										userData.user?.sid,
									)}
								>
									{routes.favoriteCourses.name}
								</NavLink>
							</NavItem>
							<NavItem>{loginItem}</NavItem>
						</ul>
					</Collapse>
				</Container>
			</Navbar>
		</header>
	);
}
