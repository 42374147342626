import React, { useEffect, useMemo, useRef } from 'react';
import styles from './grades-edit.module.scss';
import { Editor } from '../../../../components/editor/Editor';
import { Range } from '../range';
import { gradesMapAsArray } from './grades-map';
import { SaveType } from 'modules/course/course.types';
import { useClickOutside } from 'hooks/useClickOutside';
import { useViewportSize } from 'hooks/useViewportSize';

export function GradesEdit({
	name,
	grades,
	leaveEditMode,
	save,
	onChange,
}: {
	name: string;
	grades: number[];
	leaveEditMode: (name: string) => void;
	save: SaveType;
	onChange: (from?: number, to?: number) => void;
}) {
	const initialState = useMemo(() => grades, []);
	const editorRef = useRef<HTMLDivElement>(null);

	const handleSave = () => {
		save(name);
		leaveEditMode(name);
	};

	const handleCancel = () => {
		const [from, to] = initialState;
		onChange(from, to);
		leaveEditMode(name);
	};

	const handleChange = (selection: number[]) => {
		const [from, to] = selection;
		onChange(from, to);
	};

	useClickOutside(editorRef, handleSave);

	return (
		<div ref={editorRef} className={styles.gradesEdit}>
			<Editor onCancel={handleCancel} onSave={handleSave}>
				<Range
					selection={grades}
					range={gradesMapAsArray}
					onChange={handleChange}
				/>
			</Editor>
		</div>
	);
}
