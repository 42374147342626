import React from 'react';
import styles from './blog-posts.module.scss';

import { LoadingThumbnail } from './components/thumbnail/LoadingThumbnail';

export function LoadingBlogPosts({ header }: {header: string}) {
	const thumbnailCount = 2;

	const loadingThumbnails = Array.from(Array(thumbnailCount)).map(
		(item, key) => {
			return <LoadingThumbnail key={key} />;
		},
	);

	return (
		<div className={styles.blogPosts}>
			<h2>{header}</h2>
			<div className={styles.thumbnailGrid}>
				{loadingThumbnails}
			</div>
		</div>
	);
}
