import React from 'react';
import styles from './teacher-instructions-view.module.scss';
import { ADD_TEACHER_INSTRUCTIONS } from '../../course.lang';
import { useClassName } from 'hooks/use-class-name';

export function TeacherInstructionsView({
	name,
	text,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	text?: string;
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const editInstructions = () => {
		setEditMode(name);
	};

	const contentClassNames = useClassName(
		styles.text,
		userCanEdit && styles.editable,
	);

	const makeText = (text?: string) => {
		if (!text) {
			return (
				<div className={styles.noText}>{ADD_TEACHER_INSTRUCTIONS}</div>
			);
		}

		return (
			<div
				className={styles.text}
				dangerouslySetInnerHTML={{ __html: text }}
			/>
		);
	};

	return (
		<div className={styles.teacherInstructionsView}>
			<div onClick={editInstructions} className={contentClassNames}>
				{makeText(text)}
			</div>
		</div>
	);
}
