import React from 'react';
import styles from './editor-title.module.scss';

export function EditorTitle({
	icon,
	title,
}: {
	icon?: React.ReactElement;
	title: string;
}) {
	const iconElement = icon
		? <span className={styles.icon}>{icon}</span>
		: null;

	return (
		<div className={styles.editorTitle}>
			{iconElement}
			<h4 className={styles.title}>{title}</h4>
		</div>
	);
}
