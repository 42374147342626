import React from 'react';
import styles from './category-view.module.scss';
import { Button } from '../../../../components/buttons/button/Button';
import { CATEGORY } from './category.lang';

export function CategoryView({
	name,
	categories,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	categories: string[];
	setEditMode: any;
	userCanEdit?: boolean;
}) {
	const handleClick = () => {
		setEditMode(name);
	};

	const makeButtonText = () => {
		if (!categories?.length) return CATEGORY.toUpperCase();
		if (categories.length === 1) return categories[0].toUpperCase();

		return `${categories[0].toUpperCase()} + ${categories.length - 1}`;
	};

	const makeHoverText = () => {
		if (!categories.length) {
			return;
		}

		if (categories.length === 1) {
			return `${categories[0]}`;
		}

		if (categories.length === 2) {
			return `${categories[0]} og ${categories[1]}`;
		}

		return categories.map((category, key) => {
			if (key === categories.length - 1) {
				return `og ${category}`;
			}

			return `${category}, `;
		}).join('');
	};

	const buttonVariant = categories?.length ? 'roundedPrimary' : 'ghostRounded';
	const buttonStyle = !userCanEdit ? styles.notEditable : undefined;

	return (
		<div title={makeHoverText()} className={styles.categoryView}>
			<Button
				className={buttonStyle}
				onClick={handleClick}
				variant={buttonVariant}
			>
				{makeButtonText()}
			</Button>
		</div>
	);
}
