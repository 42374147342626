import React, { useEffect, useState } from 'react';
import styles from './image-view.module.scss';
import { convertJsonToImageElement } from 'modules/course/components/image/utils/convert-json-to-image-element';
import { ElementDto } from 'api';
import { getImageUrl } from 'utils/get-file-url';
import { useClassName } from 'hooks/use-class-name';
import { useRecoilValue } from 'recoil';
import { appConfigState } from 'coils/useRecoilAppConfig';

interface IImageEdit {
	name: string;
	element: ElementDto;
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}

export function ImageView({
	name,
	element,
	setEditMode,
	userCanEdit,
}: IImageEdit) {
	const [url, setUrl] = useState<string>();
	const [description, setDescription] = useState<string>();
	const appConfig = useRecoilValue(appConfigState);

	useEffect(() => {
		const getUrl = async () => {
			const { _base, url, beskrivelse } = convertJsonToImageElement(
				element?.indhold,
			);
			setDescription(beskrivelse);

			if (_base?.blobproviderMetadataGuid)
				setUrl(
					getImageUrl(
						appConfig.muBaseUrl,
						_base?.blobproviderMetadataGuid,
						775,
						500,
					),
				);
			else setUrl(url);
		};

		getUrl();
	}, [element, appConfig]);

	const handleClick = () => setEditMode(name);

	const imgClassNames = useClassName(
		userCanEdit && styles.editable,
		styles.imageView,
	);

	return (
		<>
			<img
				className={imgClassNames}
				alt=''
				src={url}
				onClick={handleClick}
			/>
			{description && (
				<p className={styles.descriptionText}>{description}</p>
			)}
		</>
	);
}
