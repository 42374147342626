import React from 'react';
import styles from './authors.module.scss';

import { ForfatterDto } from '../../../../api';
import { EditControlsType, SaveType } from '../../course.types';

import { AuthorsEdit } from './AuthorsEdit';
import { AuthorsView } from './AuthorsView';
import { useClassName } from 'hooks/use-class-name';

export function Authors({
	name,
	authors,
	editControls,
	save,
	changeAuthors,
}: {
	name: string;
	authors: ForfatterDto[] | undefined;
	editControls: EditControlsType;
	save: SaveType;
	changeAuthors: (authors: ForfatterDto[]) => void;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } =
		editControls(name);

	const viewClassNames = useClassName(
		styles.authors,
		userCanEdit && styles.editable,
	);

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	const handleChangeAuthors = (authors: ForfatterDto[]) => {
		changeAuthors(authors);
		save(name);
		handleLeaveEditMode();
	};

	if (editMode) {
		return (
			<div className={styles.editor}>
				<AuthorsEdit
					changeAuthors={handleChangeAuthors}
					leaveEditMode={handleLeaveEditMode}
					authors={authors}
				/>
			</div>
		);
	}

	return (
		<div className={viewClassNames}>
			<AuthorsView
				name='authors'
				authors={authors}
				setEditMode={setEditMode}
				leaveEditMode={leaveEditMode}
				userCanEdit={userCanEdit}
			/>
		</div>
	);
}
