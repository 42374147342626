import React, { useRef, useState } from 'react';
import styles from '../nav-menu.module.scss';
import authService from 'components/api-authorization/AuthorizeService';

import { NavLink } from 'reactstrap';

import { Button } from 'components/buttons/button/Button';
import { AccountClient, IConfig } from 'api';
import { useClickOutside } from 'hooks/useClickOutside';
import { routes } from 'routes';
import { Avatar } from 'components/avatar/Avatar';

export function UserMenuItem({
	userName,
	email,
}: {
	userName?: string;
	email?: string;
}) {
	const usernameRef = useRef(null);
	const [showLogoutButton, setShowLogoutButton] = useState(false);

	const handleToggleLogout = () => setShowLogoutButton(shown => !shown);

	const handleLogout = () => {
		const accountClient = new AccountClient(new IConfig());

		accountClient.logout().then(() => {
			authService
				.signOut('/authentication/logout')
				.then(() => window.location.replace(routes.home.path));
		});
	};

	useClickOutside(usernameRef, handleToggleLogout, showLogoutButton);

	return (
		<NavLink cssModule={{ 'nav-link': styles.navLink }}>
			<div
				ref={usernameRef}
				className={styles.userMenuItem}
				onClick={handleToggleLogout}
			>
				<span className={styles.username}>{userName}</span>
				<Avatar email={email} />
				{showLogoutButton && (
					<Button
						className={styles.logoutButton}
						variant='ghostRounded'
						onClick={handleLogout}
					>
						Log ud
					</Button>
				)}
			</div>
		</NavLink>
	);
}
