import React, { useMemo } from 'react';
import styles from './thumbnail.module.scss';
import { MediaDto } from 'api';
import { cutTextAtLength } from 'utils/text';
import { MIN_SIZE_TABLET, useViewportSize } from 'hooks/useViewportSize';
import { useClassName } from 'hooks/use-class-name';

export function Thumbnail({
	title,
	exert = '',
	url,
	media,
}: {
	title?: string;
	exert?: string;
	url?: string;
	media?: MediaDto[];
	}) {
	
	const windowSize = useViewportSize();
	const imageUrl = useMemo(() => {
		if (!media?.length) return;

		const allowedMimeType = ['image/jpg', 'image/jpeg', 'image/png'];
		const images = media.filter((n) =>
			allowedMimeType.includes(n.mimeType),
		);

		if (windowSize.width > MIN_SIZE_TABLET) return images[0]?.url; // 150px x 150px

		let selectedImageUrl;
		let bestDiff = 0;

		images.forEach((image) => {
			const diff = Math.abs(windowSize.width - image.width);
			const imageNotTooLarge =
				image.width > windowSize.width &&
				windowSize.width * 2 > image.width;
			if (
				image.url &&
				(diff < bestDiff || imageNotTooLarge || !selectedImageUrl)
			) {
				bestDiff = diff;
				selectedImageUrl = image.url;
			}
		});

		return selectedImageUrl;
	}, [windowSize, media]);

	const thumbnailClassNames = useClassName(
		styles.thumbnail,
		!!imageUrl && styles.thumbnailWithImage,
	);

	const imageElement = imageUrl ? (
		<div
			style={{
				backgroundImage: `url(${imageUrl})`,
			}}
			className={styles.media}
		/>
	) : null;

	const cutExert = cutTextAtLength(exert, 80);
	const cutTitle = cutTextAtLength(title || '', 81);

	return (
		<a
			href={url}
			className={thumbnailClassNames}
			target='_blank'
			rel='noopener noreferrer'
		>
			{imageElement}
			<div className={styles.thumbnailContent}>
				<h4>{cutTitle}</h4>
				<div dangerouslySetInnerHTML={{ __html: cutExert }} />
			</div>
		</a>
	);
}
