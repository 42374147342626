import React from 'react';
import styles from './button.module.scss';
import { ButtonProps } from './button.types';
import { useClassName } from 'hooks/use-class-name';

export function Button(props: ButtonProps) {
	const {
		children,
		type = 'button',
		variant,
		className,
		block,
		...restProps
	} = props;
	const classNames = useClassName(
		styles.button,
		block && styles.block,
		styles[variant],
		className,
	);

	return (
		<button className={classNames} type={type} {...restProps}>
			{children}
		</button>
	);
}
