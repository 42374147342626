import React from 'react';
import styles from './button-group.module.scss';
import { ButtonGroupProps } from './button-group.types';

export function ButtonGroup(props: ButtonGroupProps) {
	const classNames = [
		styles.buttonGroup,
		styles[props.alignment],
		props.className,
	].join(' ');

	return <div className={classNames}>{props.children}</div>;
}
