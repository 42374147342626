import React, { MouseEvent, useState } from 'react';
import styles from './course-card.module.scss';
import {
	FagDto,
	IForloebListItemDto,
	ForloebClient,
	IConfig,
	FavoriteForloebDto,
} from 'api';

import { routes } from '../../routes';
import { Link, useHistory } from 'react-router-dom';
import { useImage } from 'hooks/useImage';
import { DRAFT } from './course-card.lang';
import { HeartEmptyIcon } from 'components/svg/icons/HeartEmptyIcon';
import { HeartFilledIcon } from 'components/svg/icons/HeartFilledIcon';
import { getGradeText } from 'modules/course/components/grades/utils/get-grade-text';

import { useRecoilValue } from 'recoil';
import { userState, UserStateType } from '../../coils/useRecoilUser';
import { Avatar } from 'components/avatar/Avatar';

export function CourseCard({ course, canFavorite }: { course: IForloebListItemDto, canFavorite?: boolean }) {
	const courseBillede = useImage(course.billede, 400, 200);
	const userData: UserStateType = useRecoilValue(userState);
	const history = useHistory();

	const handleFavorite = async (favorite?: boolean) => {
		const forloebClient = new ForloebClient(new IConfig());
		const model = new FavoriteForloebDto({ forloebId: course.id });

		if (!favorite) {
			forloebClient.addToFavorites(model);
		} else {
			forloebClient.removeFromFavorites(model);
		}
	};

	const makeHoverText = (array: FagDto[] | string[], key?: string) => {
		if (!array.length) {
			return;
		}

		const mappedArray: FagDto[] | string[] = key ? array.map((obj) => obj[key]) : array;

		if (array.length === 1) {
			return `${mappedArray[0]}`;
		}

		if (mappedArray.length === 2) {
			return `${mappedArray[0]} og ${mappedArray[1]}`;
		}

		return mappedArray.map((category, idx) => {
			if (idx === mappedArray.length - 1) {
				return `og ${category}`;
			}

			return `${category}, `;
		}).join('');
	};

	const SubjectsText = () => {
		if (!course.fag?.length) {
			return null;
		}

		const firstSubject: FagDto = course.fag[0];
		const subjectsLength = course.fag.length;

		const result: string =
			subjectsLength > 1
				? `${firstSubject.navn} + ${subjectsLength - 1}`
				: `${firstSubject.navn}`;

		return (
			<span
				title={makeHoverText(course.fag, 'navn')}
				className={styles.subjectsText}
			>
				{result.toUpperCase()}
			</span>
		);
	};

	const CategoriesText = () => {
		if (!course.kategorier?.length) {
			return null;
		}

		const firstKategory = course.kategorier[0];
		const categoriesLength = course.kategorier.length;

		const result: string =
			categoriesLength > 1
				? `${firstKategory} + ${categoriesLength - 1}`
				: firstKategory;

		return (
			<span
				title={makeHoverText(course.kategorier)}
				className={styles.categoriesText}
			>
				{result.toUpperCase()}
			</span>
		);
	};

	const GradesText = () => {
		if (!course.klassetrinTil && !course.klassetrinFra) {
			return null;
		}

		const grades = [course.klassetrinFra, course.klassetrinTil].filter(
			(grade) => grade !== undefined,
		) as number[];

		const gradeText = getGradeText(grades);

		return (
			<span title={gradeText.toLowerCase()} className={styles.gradesText}>{gradeText}</span>
		);
	};

	const AuthorsText = () => {
		if (!course.forfattere?.length) {
			return null;
		}

		const firstAuthor = course.forfattere[0];
		const authorsLength = course.forfattere.length;

		const result: string =
			authorsLength > 1
				? `${course.forfattere[0].navn} + ${authorsLength - 1}`
				: `${firstAuthor.navn}`;

		return <span className={styles.authorsText}>{result}</span>;
	};

	const Authors = () => {
		const firstAuthor = course.forfattere?.length
			? course.forfattere[0]
			: null;
	
		return (
			<div className={styles.authors}>
				<Avatar className={styles.avatar} email={firstAuthor?.email} />
				<AuthorsText />
			</div>
		);
	};

	const MidDot = () => <span className={styles.middot}>&middot;</span>;
	const DraftTag = ({ isDraft }: { isDraft?: boolean }) => {
		return isDraft ? (
			<small className={styles.draftTag}>{DRAFT.toUpperCase()}</small>
		) : null;
	};
	const FavoriteTag = ({
		isFavorite,
		favoriteCount = 0,
		withCounter = false,
	}: {
		isFavorite?: boolean;
		favoriteCount?: number;
		withCounter?: boolean;
	}) => {
		const [count, setFavoriteCount] = useState(favoriteCount);
		const [favorite, setFavorite] = useState(isFavorite);
	
		const changeFavorite = (event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();

			if (!userData.isAuthenticated) {
				history.push({ pathname: '/authentication/login' });

				return;
			}

			favorite ? setFavorite(false) : setFavorite(true);
			const addition = favorite ? -1 : 1;
			const newCount = count + addition;

			setFavoriteCount(newCount);
			handleFavorite(favorite);
		};

		if (!canFavorite) {
			return (
				<small className={styles.favoriteTag}>
					<HeartFilledIcon
						className={`${styles.heartIcon} ${styles.favorite}`}
					/>{' '}
						{count}
				</small>
			);
		}

		return (
			<small className={styles.favoriteTag} onClick={changeFavorite}>
				{favorite ? (
					<HeartFilledIcon
						className={`${styles.heartIcon} ${styles.favorite}`}
					/>
				) : (
					<HeartEmptyIcon className={styles.heartIcon} />
				)}{' '}
				{withCounter && count}
			</small>
		);
	};

	const metaDetails = [
		<SubjectsText key='subjects-text' />,
		SubjectsText() && CategoriesText() ? <MidDot key='subjects-dot' /> : null,
		<CategoriesText key='category-text' />,
		GradesText() && (CategoriesText() || SubjectsText()) ? <MidDot key='category-dot' /> : null,
		<GradesText key='grades-text' />,
	].filter(Boolean);

	return (
		<Link
			to={routes.course.setPath(course.id)}
			className={styles.courseCard}
		>
			<div
				className={styles.cardImage}
				style={{ backgroundImage: `url('${courseBillede}')` }}
			>
				<DraftTag isDraft={course.erKladde} />
				<FavoriteTag
					isFavorite={course.favorit}
					favoriteCount={course.favoritter}
				/>
			</div>
			<div className={styles.cardDetails}>
				<small className={styles.metaDetails}>{metaDetails}</small>
				<h5 className={styles.cardTitle}>{course.titel}</h5>
				<span className={styles.cardAuthors}>
					<Authors />
				</span>
			</div>
		</Link>
	);
}
