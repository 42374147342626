import React from 'react';
import styles from './page.module.scss';

import { Element } from './components/element/Element';
import { CreateElement } from './components/create-element/CreateElement';
import { Droppable } from 'react-beautiful-dnd';
import { DraggableElementType } from '../chapter/chapter.types';
import { PageTitle } from './components/page-title/PageTitle';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { ActionTypes } from 'modules/course/state/actions/course/types';
import { useClassName } from 'hooks/use-class-name';

const ElementList = ({
	children,
	innerRef,
	isDraggingOver,
	...restProps
}: {
	isDraggingOver: boolean;
	children: React.ReactElement;
	innerRef: any;
}) => {
	const classNames = useClassName(
		styles.elements,
		isDraggingOver && styles.isDraggingOver,
	);

	return (
		<div ref={innerRef} className={classNames} {...restProps}>
			{children}
		</div>
	);
};

export function Page({
	id,
	chapterId,
	title,
	elements,
	createElement,
	changeElement,
	changePageTitle,
	deleteElement,
	deletePage,
	save,
	editControls,
	videoControls,
	leaveEditMode,
}: {
	id: string;
	chapterId: string;
	title: string;
	elements: DraggableElementType[];
	createElement: () => void;
	changeElement: () => void;
	changePageTitle: (chapterId: string, pageId: string, title: string) => void;
	deleteElement: () => void;
	deletePage: ActionTypes['deletePage'];
	save: SaveType;
	editControls: EditControlsType;
	videoControls: any;
	leaveEditMode: (name: string) => void;
}) {
	const handleChangePageTitle = (title: string) => {
		changePageTitle(chapterId, id, title);
	};

	if (!editControls().userCanEdit && !elements.length) {
		return null;
	}

	const pageId = `page-title-${id}`;

	const handleDeletePage = () => {
		deletePage(chapterId, id);
		save(`delete-page-${id}`);
		leaveEditMode(pageId);
	};

	const deleteOptions = {
		text: 'Slet afsnit',
		confirmText: 'Bekræft slet',
		onDelete: handleDeletePage,
	};

	return (
		<div className={styles.page}>
			<PageTitle
				text={title}
				name={pageId}
				editControls={editControls}
				save={save}
				changeTitle={handleChangePageTitle}
				deleteOptions={deleteOptions}
			/>
			<div className={styles.pageContent}>
				<Droppable droppableId={id}>
					{(provided, snapshot) => (
						<ElementList
							innerRef={provided.innerRef}
							isDraggingOver={snapshot.isDraggingOver}
							{...provided.droppableProps}
						>
							<>
								{elements.map(
									(
										element: DraggableElementType,
										index: number,
									) => (
										<Element
											id={element.id}
											chapterId={chapterId}
											pageId={id}
											index={index}
											videoControls={videoControls}
											key={element.id}
											// @ts-ignore
											element={{
												id: element.id,
												...element.props,
											}}
											changeElement={changeElement}
											deleteElement={deleteElement}
											save={save}
											editControls={editControls}
										/>
									),
								)}
								{provided.placeholder}
							</>
						</ElementList>
					)}
				</Droppable>
			</div>
			<CreateElement
				save={save}
				pageId={id}
				chapterId={chapterId}
				createElement={createElement}
				editControls={editControls}
				videoControls={videoControls}
			/>
		</div>
	);
}
