import { useEffect, RefObject } from 'react';

export function useClickOutside(
	inputRef: RefObject<HTMLElement>,
	callback: () => void,
	isActive?: boolean, // Use if you initialize more than one useClickOutside
) {
	useEffect(() => {
		const onClick = (event: MouseEvent) => {
			const { target } = event;
	
			if (
				inputRef.current &&
				!inputRef.current.contains(target as HTMLElement)
			) {
				callback();
			}
		};

		if (isActive !== false) {
			document.addEventListener('mousedown', onClick);
		}

		return () => document.removeEventListener('mousedown', onClick);
	}, [inputRef, isActive, callback]);

	return inputRef;
}
