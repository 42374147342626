import React from 'react';
import styles from './selected-video.module.scss';

import ReactPlayer from 'react-player';

import { YoutubeResponseItemDto } from '../../../../../../../../../api';

import * as lang from './selected-video.lang';
import { Button } from 'components/buttons/button/Button';

export function SelectedVideo({
	thumbnail,
	onCancel,
	videoControls,
}: {
	thumbnail?: YoutubeResponseItemDto;
	onCancel: () => void;
	videoControls: any;
}) {
	const { playing, setPlayingVideo } = videoControls();

	const handleStartPlayingVideo = () => setPlayingVideo(`edit-${thumbnail?.id}`);

	return (
		<div className={styles.selectedVideo}>
			<div className={styles.videoMenu}>
				<Button
					onClick={onCancel}
					className={styles.readMore}
					variant='inline'
				>
					{lang.BACK_TO_SEARCH}
				</Button>
			</div>
			<ReactPlayer
				width={'100%'}
				controls
				onPlay={handleStartPlayingVideo}
				playing={playing === `edit-${thumbnail?.id}`}
				url={`https://www.youtube.com/watch?v=${thumbnail?.id}`}
			/>
		</div>
	);
}
