import React from 'react';

import styles from './register.module.scss';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';

export function TermsOfUse() {
	return (
		<p className={styles.termsOfUseText}>
			Når du opretter dig som bruger, giver du samtykke til, at Aalborg
			Universitet må behandle dine personlige oplysninger i forbindelse
			med brugeroprettelse og brug af sitet. Du kan til enhver tid ændre
			eller trække dit samtykke tilbage ved at skrive til{' '}
			<a
				target='_blank'
				rel='noopener noreferrer'
				href='mailto:thorkild@hum.aau.dk'
			>
				thorkild@hum.aau.dk
			</a>
			. I så fald vil din brugerprofil og oprettet indhold blive slettet.
			Læs mere under{' '}
			<NavLink to={routes.termsOfUse.path}>
				Vilkår for brug og behandling af persondata.
			</NavLink>
		</p>
	);
}
