import React from 'react';
import ipadArt from './images/ipad.svg';
import styles from './knowledge-of-games.module.scss';

export function KnowledgeOfGames() {
	return (
		<div className={styles.container}>
			<article>
				<h2>Viden om spil</h2>
				<p>
					Her kommer en kort indføring i centrale spilbegreber, som kan hjælpe dig til at anvende spil i undervisningen.
				</p>

				<h4>Spilelementer</h4>
				<p>
					Spil kan defineres på mange måder. Nogle af de mest centrale elementer i spil er:
				</p>
				<ul>
					<li>
						<i>Udfordringer:</i> Alle spil har mål og udfordringer, som skal overkommes.
					</li>
					<li>
						<i>Spilmekanikker:</i> De funktioner og regler, som bestemmer spillerens handlemuligheder og feedback fra spillet. Fx ved at bevæge sig rundt, kæmpe, samle ressourcer eller konstruere ting i spillet.
					</li>
					<li>
						<i>Spildynamikker:</i> Den sociale interaktion mellem spillerne - fx i form af taktikker, konkurrence, samarbejde eller vidensdeling.
					</li>
					<li>
						<i>Spilverden:</i> Spillets æstetiske udtryk, materialer, rum/sted og varighed.
					</li>
				</ul>

				<h4>
					Spiltyper
				</h4>
				<p>
					Her på sitet skelnes mellem fire spiltyper, der alle kan være analoge eller digitale:
				</p>
				<ul>
					<li>
						<i>Læringsspil:</i> brug af spil som er designet til læring og undervisning - fx Drabssag/Melved eller DragonBox
					</li>
					<li>
						<i>Underholdningsspil:</i> når man anvender spil designet til underholdning i undervisningen - fx i form af skak eller The
					</li>
					<li>
						<i>Design af spil:</i> elevers brug af redskaber/materialer til selv at designe spil - fx i form af brætspil eller programmeringssproget Scratch
					</li>
					<li>
						<i>Gamification:</i> brug af spilelementer - fx i form af løsrevne avatarer, points, badges eller quests eller systemer som Khan Academy
					</li>
				</ul>

				<h4>
					Hvis du vil vide mere
				</h4>
				<p>
					Til sidst en række forslag til videre læsning, hvis du vil vide mere:
				</p>
				<p>
					Hanghøj, T., Kjellow, T. N., Lassen, S. M., Møller, L.D., Henningsen, B. og Jensen, E. O. (2021).<br />
					<i>Sæt skolen i spil. Brug af computerspil og gamification i undervisningen.</i> Aalborg Universitetsforlag.<br />
					<a 
						target='_blank'
						rel='noopener noreferrer'
						href="https://sætskolenispil.dk"
					>
						www.sætskolenispil.dk
					</a>
				</p>
				<p>
					Hanghøj, T. (2019). <i>Digitale spil i undervisningen.</i> Tidsskriftet Læring Og Medier (LOM), 12(21). <br />
					<a
						href="https://tidsskrift.dk/lom/article/view/112888"
						target='_blank'
						rel='noopener noreferrer'
					>
						www.tidsskrift.dk
					</a>
				</p>
				<p>
					Temanummer af KvaN om Spildidaktik: <br />
					<a
						href="https://kvan.dk/product_info.php?cPath=3&products_id=127"
						target='_blank'
						rel='noopener noreferrer'
					>
						www.kvan.dk
					</a>
				</p>
			</article>
			<div className={styles.imgWrapper}>
				<img
					className={styles.ipadArt}
					src={ipadArt}
					alt='ipad art'
				/>
			</div>
		</div>
	);
}
