import React from 'react';
import styles from './thumbnail.module.scss';

import { YoutubeResponseItemDto } from '../../../../../../../../../api';

export function Thumbnail({
	thumbnail,
	resolution,
	onClick,
}: {
	thumbnail: YoutubeResponseItemDto;
	resolution?: 'thumbnailMedium'  | 'thumbnailHigh';
	onClick?: (thumbnail: YoutubeResponseItemDto) => void; 
}) {
	const handleClick = () => {
		if (!onClick) {
			return;
		}

		onClick(thumbnail);
	};

	const thumbnailType = resolution || 'thumbnailMedium';

	return (
		<div className={styles.thumbnail} onClick={handleClick}>
			<img alt='thumbnail' src={thumbnail[thumbnailType]} />
			<div className={styles.thumbnailTitle}>
				<h4>{thumbnail.title}</h4>
			</div>
		</div>
	);
}
