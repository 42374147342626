import React, { useState } from 'react';
import { TinyEditor } from '../../../../components/tiny-editor/TinyEditor';
import { ADD_TEACHER_INSTRUCTIONS } from '../../course.lang';
import { Editor } from '../../../../components/editor/Editor';
import { SaveType } from 'modules/course/course.types';

const editorConfig = {
	placeholder: ADD_TEACHER_INSTRUCTIONS,
};

export function TeacherInstructionsEdit({
	name,
	text = '',
	leaveEditMode,
	save,
	changeTeacherInstructions,
}: {
	name: string;
	text?: string;
	leaveEditMode: (name: string) => void;
	save: SaveType,
	changeTeacherInstructions: (teacherInstructions: string) => void,
}) {
	const [state, setState] = useState(text);

	const handleChangeTeacherInstructions = (change: string) => {
		setState(change);
	};

	const handleSaveTeacherInstructions = () => {
		changeTeacherInstructions(state);
		save(name);
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	return (
		<Editor
			onSave={handleSaveTeacherInstructions}
			onCancel={handleLeaveEditMode}
		>
			<TinyEditor
				config={editorConfig}
				model={state}
				onModelChange={handleChangeTeacherInstructions}
			/>
		</Editor>
	);
}
