// @ts-nocheck
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './modules/webcomponents/wc-dialog-overlay';
import './modules/webcomponents/wc-image-view';
import './modules/webcomponents/wc-course-image';
//import registerServiceWorker from './registerServiceWorker';

import './fonts/Muli/static/Muli-Light.ttf';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);
root.render(
	<BrowserRouter basename={baseUrl}>
		<RecoilRoot>
			<App />
		</RecoilRoot>
	</BrowserRouter>,
);
// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
