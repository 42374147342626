import React from 'react';
import styles from './teacher-instructions.module.scss';
import { TEACHER_INSTRUCTIONS } from '../../course.lang';

import { TeacherInstructionsView } from './TeacherInstructionsView';
import { TeacherInstructionsEdit } from './TeacherInstructionsEdit';
import { EditControlsType, SaveType } from '../../course.types';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.teacherInstructions}>
		<h4 className={styles.title}>{TEACHER_INSTRUCTIONS}</h4>
		{children}
</div>
);

export function TeacherInstructions({
	name,
	text,
	editControls,
	changeTeacherInstructions,
	save,
}: {
	name: string;
	text?: string;
	editControls: EditControlsType;
	changeTeacherInstructions: (teacherInstructions: string) => void;
	save: SaveType;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (!userCanEdit && !text?.length) {
		return null;
	}

	if (editMode) {
		return (
			<Wrapper>
				<TeacherInstructionsEdit
					save={save}
					changeTeacherInstructions={changeTeacherInstructions}
					leaveEditMode={leaveEditMode}
					name={name}
					text={text}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<TeacherInstructionsView
				setEditMode={setEditMode}
				name={name}
				text={text}
				userCanEdit={userCanEdit}
			/>
		</Wrapper>
	);
}
