import React from 'react';
import styles from './terms-of-use.module.scss';

export function TermsOfUsePage() {
	return (
			<div className={styles.termsOfUseText}>
				<h2>Vilkår for anvendelse</h2>
				<p>
					Der gælder en række vilkår for oprettelse og anvendelse af
					forløb på SIU, som vi vil bede dig om at overholde, når du
					bruger sitet.
				</p>
				<p>
					<h4>Kvalitet</h4>
					Opret kun gode forløb! Tænk nøje over hvordan andre
					undervisere kan få gavn af at anvende dit
					undervisningsforløb med spil. Læs gerne vores “Guide til at
					oprette forløb” (LINK). Del hellere få gennemarbejdede
					forløb end mange ufærdige forløb.
				</p>
				<p>
					<h4>Rettigheder</h4>
					Rettighederne til de forløb som oprettes tilhører brugeren.
					Forløbene må frit anvendes til undervisning af alle
					interesserede brugere af sitet. Forløbene må ikke anvendes i
					kommercielt øjemed medmindre andet aftales med den bruger,
					som har oprettet forløbene.
				</p>
				<p>
					Vær opmærksom på, at du skal overholde gældende regler for
					rettigheder i forhold at anvende det indhold, som du
					opretter i dine forløb. Det gælder fx brug af tekster,
					billeder eller video, som kan være underlagt copyright
					regler. AAU er ansvarlig for, at de gældende regler for
					rettigheder bliver overholdt.
				</p>
				<p>
					<h4>Tilgængelighed</h4>
					De oprettede undervisningsforløb så vidt muligt skal være
					tilgængelige for alle brugere af sitet. Der kan dog laves
					beskrivelser og linkes til gratis forløb uden for sitet.
				</p>
				<p>
					<h4>GDPR</h4>
					<p>
						Vær opmærksom på, at du skal overholde gældende regler
						for GDPR, hvis du fx vælger at bringe tekster, billeder
						eller videoer af elever i dine forløb. AAU er ansvarlig
						for, at de gældende GDPR regler bliver overholdt.
					</p>
				</p>
				<p>
					<h4>Deling af forløb</h4>
					Vi opfordrer til, at brugerne deler forløb i mange
					sammenhænge - fx på sociale medier eller med kollegaer.
					Derudover kan du som bruger vælge, at få vist dit oprettede
					forløb på læringsplatformen MinUddannelse. Det vælger du,
					når du opretter et forløb. Det gælder dog kun for forløb,
					som retter sig mod Folkeskolen.
				</p>
				<p>
					<h4>Opbevaring og behandling af data</h4>
					AAU er dataansvarlig for alle brugerdata i form af
					forløbsbeskrivelser, brugernavne og mailadresser, der
					genereres gennem sitet. Alle data opbevares af KMD, der
					fungerer som databehandler i henhold til den indgåede
					databehandleraftale mellem AAU og KMD.
				</p>
				<p>
					<h4>Redaktion</h4>
					Eftersom AAU er ansvarlig for indholdet på SIU, forbeholder
					medlemmer af SIU redaktionsgruppen sig ret til at skjule og
					eventuelt slette forløb, som vi vurderer ikke lever op til
					ovennævnte vilkår. Hvis redaktionsgruppen vælger at skjule
					eller slette forløb, tager vi kontakt til dig via din
					mailadresse med en begrundelse for vores valg - så vidt
					muligt med forslag til, hvordan du kan forbedre dit forløb.
				</p>
			</div>
	);
}
