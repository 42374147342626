import React, { useReducer, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import styles from './course-page.module.scss';

import { Course } from '../../modules/course/Course';
import { NavigationMenu } from '../../modules/course/components/navigation/NavigationMenu';
import { Chapter } from '../../modules/course/components/chapter/Chapter';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

import { reducer as courseReducer } from '../../modules/course/state/reducer';
import { initialState as courseInitialState } from '../../modules/course/state/initial-state';

import { globalActions } from 'modules/course/state/actions/global/actions';
import { courseActions } from '../../modules/course/state/actions/course/actions';
import { KapitelDto } from '../../api';
import { updateCourse } from '../../modules/course/api/update-course';
import { EditControlsType } from 'modules/course/course.types';
import { routes } from 'routes';
import { Button } from 'components/buttons/button/Button';
import 'components/prevent-unsaved';
import { imageViewService } from 'modules/webcomponents/services/image-view.service';
import { SubMenuIcon } from 'components/svg/icons/Submenu';

/**
 * COURSE PAGE
 */

const getSaveMessage = (editCount: number) => {
	if (!editCount) {
		return '';
	}

	const isOne = editCount === 1;
	const felt = isOne ? 'felt' : 'felter';
	const aaben = isOne ? 'åben' : 'åbne';

	return `Du har ${editCount} ${felt} ${aaben}`;
};

export function CoursePage() {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [isLoading, setIsloading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [courseState, dispatch] = useReducer<typeof courseReducer>(
		courseReducer,
		courseInitialState,
	);

	const [showNavigationItems, setShowNavigationItems] = useState(false);
	const toggleNavigationItems = () =>
		setShowNavigationItems(!showNavigationItems);

	useEffect(() => {
		if (id) {
			courseActions(dispatch)
				.loadCourse(id)
				.then(() => setIsloading(false))
				.then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
				.catch(() => history.push({ pathname: routes.home.path }));
		}
	}, [id]);

	const query = useQuery();
	const chapterId = query.get('chapter');
	const editCount = courseState._editing.length;
	const saveMessage = getSaveMessage(editCount);
	const isEditing = !!editCount;

	const handleSaveCourse = async () => {
		if (
			courseState.isUpdated &&
			!isEditing &&
			courseState.course.maaRedigere
		) {
			setIsSaving(true);
			await updateCourse(courseState.course);
			courseActions(dispatch).setSavedComplete();
			setIsSaving(false);
		}
	};

	const editControls: EditControlsType = (name?: string) => {
		if (!courseState.course.maaRedigere) {
			return {
				editMode: false,
				leaveEditMode: (name: string) => {},
				setEditMode: (name: string, imgSrc?: string) => {
					if (imgSrc) {
						imageViewService.openImage(imgSrc);
					}
				},
				userCanEdit: false,
			};
		}

		return {
			editMode: !!courseState._editing.find(
				(item: string) => item === name,
			),
			leaveEditMode: globalActions(dispatch).leaveEditMode,
			setEditMode: globalActions(dispatch).setEditMode,
			userCanEdit: courseState.course.maaRedigere,
		};
	};

	const videoControls = () => {
		return {
			playing: courseState._playingVideo,
			setPlayingVideo: globalActions(dispatch).setPlayingVideo,
		};
	};

	const handleCreateNewChapter = () => {
		const newChapterId = courseActions(dispatch).createNewChapter();
		globalActions(dispatch).setUpdated('');

		history.push({
			pathname: routes.course.setPath(id),
			search: `?chapter=${newChapterId}`,
		});
	};

	let renderPageContent = (
		<Course
			id={id}
			course={courseState.course}
			editControls={editControls}
			globalActions={globalActions(dispatch)}
			actions={courseActions(dispatch)}
		/>
	);

	if (chapterId) {
		const chapter = courseState.course.kapitler?.find(
			(chapter: KapitelDto) => chapter.id === chapterId,
		);

		renderPageContent = chapter ? (
			<Chapter
				id={chapterId}
				courseId={id}
				actions={courseActions(dispatch)}
				globalActions={globalActions(dispatch)}
				editControls={editControls}
				chapter={chapter}
				videoControls={videoControls}
			/>
		) : (
			renderPageContent
		);
	}

	if (isLoading) {
		return (
			<div className={styles.coursePage}>
				<spinner-text showspinner='true' size='14'>
					Indlæser
				</spinner-text>
			</div>
		);
	}

	const canEdit = editControls().userCanEdit;

	const courseSettings = [
		canEdit ? (
			<Button
				key='create-chapter-button'
				variant='text'
				className={styles.createNewChapterButton}
				onClick={handleCreateNewChapter}
			>
				Opret nyt kapitel...
			</Button>
		) : null,
	].filter(Boolean);

	const isDirty = canEdit && (isEditing || isSaving || courseState.isUpdated);

	return (
		<>
			<prevent-unsaved
				isdirty={isDirty ? 'true' : 'false'}
			></prevent-unsaved>
			<Prompt
				when={isDirty}
				message={(location) => {
					return location.pathname.startsWith('/courses')
						? true
						: `Er du sikker på du vil forlade siden? Du har muligvis ændringer som ikke er gemt.`;
				}}
			/>
			{canEdit ? (
				<div className={styles.saveMenu}>
					<div className={styles.navMenuIndent}>
						<Button
							variant='primary'
							onClick={handleSaveCourse}
							disabled={
								isEditing || isSaving || !courseState.isUpdated
							}
							block
						>
							<spinner-text
								showspinner={isSaving ? 'true' : 'false'}
								size='16'
							>
								{isSaving ? 'Gemmer' : 'Gem forløb'}
							</spinner-text>
						</Button>
					</div>
					<div>
						<strong>{saveMessage}</strong>
					</div>
				</div>
			) : null}
			<div
				className={`${styles.coursePage} ${
					showNavigationItems && styles.showNavigationItems
				}`}
			>
				<div
					className={styles.toggleMenu}
					onClick={toggleNavigationItems}
				>
					<SubMenuIcon
						className={
							showNavigationItems
								? styles.rotate
								: styles.noRotation
						}
					/>

					<span>{showNavigationItems ? 'Skjul' : 'Vis'} kapiter</span>
				</div>

				<NavigationMenu
					courseId={id}
					editControls={editControls}
					chapters={courseState.course.kapitler}
					className={styles.navigationMenu}
				>
					{courseSettings}
				</NavigationMenu>
				<div
					className={styles.pageContent}
					ref={(node) =>
						node &&
						(showNavigationItems
							? node.setAttribute('inert', '')
							: node.removeAttribute('inert'))
					}
				>
					{renderPageContent}
				</div>
			</div>
		</>
	);
}
