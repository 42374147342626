import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'routes';
import styles from './about-siu.module.scss';
import boardGameArt from './images/about_boardgame.svg';
import innovationsfondenArt from './images/innovationsfonden_logo.svg';

export function AboutSiuPage() {
	return (
		<div>
			<h2>Om SIU</h2>
			<div className={styles.firstSection}>
				<div>
					<p>
						Spil i Undervisningen (SIU) er en portal for
						spilrelaterede undervisningsforløb til fri afbenyttelse.
						Formålet med portalen at bidrage til anvendelse og
						udbredelse af spil i undervisningen.
					</p>
					<p>
						På portalen kan du finde finde og oprette gratis forløb
						om brug af spil i undervisningen. Det gælder fx brug af
						underholdningsspil, læringsspil, gamification eller
						læring gennem design af spil. Fokus er både analoge og
						digitale spilformater. De eksisterende forløb henvender
						sig især til grundskolen, men det er også muligt at
						oprette forløb til f.eks. daginstitutioner,
						ungdomsuddannelser eller videregående uddannelser.
						Endvidere kan man vælge at dele sine forløb på
						læringsplatformen MinUddannelse.
					</p>
					<p>
						Derudover har portalen en spilrelateret{' '}
						<NavLink to={routes.home.path}>nyhedsblog</NavLink>,
						hvor både forskere, udviklere og praktikere deler viden
						om og erfaringer med brug af spil i undervisningen.
					</p>
					<p>
						SIU er forankret i Center for Anvendt Spilforskning på
						Aalborg Universitet og er udviklet af forskere fra
						Aalborg Universitet i samarbejde med UVdata som en del
						af{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://gbl21.aau.dk/'
						>
							GBL21
						</a>{' '}
						projektet, der er finansieret af{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://innovationsfonden.dk/da'
						>
							Innovationsfonden
						</a>
						. Derudover har SIU en redaktionsgruppe, hvori der
						indgår forskere fra AAU samt læreruddannere og
						konsulenter fra KP og VIA, som er med til at gennemgå
						indhold og sikre udviklingen af sitet.
					</p>
					<p>
						Hvis du har spørgsmål til portalen, er du velkommen til
						at kontakte Thorkild Hanghøj, Professor MSO i Spil og
						læring, Aalborg Universitet på{' '}
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='mailto:thorkild@hum.aau.dk'
						>
							thorkild@hum.aau.dk
						</a>
						.
					</p>
				</div>
				<div className={styles.imageWrapper}>
					<img
						className={styles.boardGameArt}
						src={boardGameArt}
						alt='board game art'
					/>
				</div>
			</div>
			<div className={styles.secondSection}>
				<div>
					<div className={styles.innovationsfondenArtWrapper}>
						<img
							className={styles.innovationsfondenArt}
							src={innovationsfondenArt}
							alt='innovationsfonden'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
