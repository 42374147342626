import React, { useState } from 'react';
import styles from './course-library.module.scss';
import { Subjects } from 'modules/course/components/subjects/Subjects';
import { Grades } from 'modules/course/components/grades/Grades';
import { Category } from 'modules/course/components/category/Category';
import { EditControlsType } from 'modules/course/course.types';
import { GlobalActionTypes } from 'modules/course/state/actions/global/types';
import { CourseLibraryActionTypes } from 'modules/course/state/actions/course-library/types';
import { TextField } from 'components/text-field/TextField';
import { StateType } from 'modules/course/state/initial-state';
import { Button } from 'components/buttons/button/Button';
import { DRAFT } from 'components/course-card/course-card.lang';
import { SearchIcon } from 'components/svg/icons/SearchIcon';
import { useDebounced } from 'hooks/useDebounced';

interface ICourseLibraryFiltering {
	courseLibrary: StateType['courseLibrary'];
	actions: GlobalActionTypes & CourseLibraryActionTypes;
	editControls: EditControlsType;
	showDraftFilter?: boolean;
}

const DEBOUNCED_TIME_IN_MS = 500;

export function CourseLibraryFiltering({
	courseLibrary,
	actions,
	editControls,
	showDraftFilter,
}: ICourseLibraryFiltering) {
	const [showOnlyDraft, setShowOnlyDraft] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedHandler = useDebounced(DEBOUNCED_TIME_IN_MS);

	const toggleShowOnlyDraft = () => {
		actions.setCourseLibraryDraft(!showOnlyDraft);
		actions.setUpdated('draft');
		setShowOnlyDraft(!showOnlyDraft);
	};

	const performSearch = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		actions.setUpdated('term');
	};

	const grades = {
		from: courseLibrary.trin?.[0],
		to: courseLibrary.trin?.[courseLibrary.trin?.length - 1],
	};

	function handleOnChange(change: { term: string }) {
		setSearchTerm(change.term);

		debouncedHandler(() => {
			actions.setCourseLibrarySearchTerms(change);
			actions.setUpdated('term');
		});
	}

	return (
		<div className={styles.filtering}>
			<div className={styles.buttons}>
				{showDraftFilter && (
					<Button
						onClick={toggleShowOnlyDraft}
						variant={
							showOnlyDraft ? 'roundedPrimary' : 'ghostRounded'
						}
					>
						{DRAFT.toUpperCase()}
					</Button>
				)}
				<Subjects
					name='subjects'
					save={actions.setUpdated}
					changeSubjects={actions.setCourseLibrarySubject}
					selectedSubjects={courseLibrary._subjects}
					editControls={editControls}
				/>
				<Grades
					name='grades'
					grades={grades}
					save={actions.setUpdated}
					onChange={actions.setCourseLibraryGrade}
					editControls={editControls}
				/>
				<Category
					name='category'
					categories={courseLibrary.kategorier}
					save={actions.setUpdated}
					onChange={actions.setCourseLibraryCategories}
					editControls={editControls}
				/>
			</div>
			<form
				className={styles.searchForm}
				autoComplete='off'
				onSubmit={performSearch}
			>
				<SearchIcon />
				<TextField
					id='term'
					placeholder='Søg i undervisningsforløb...'
					onChange={handleOnChange}
					value={searchTerm}
					className={styles.searchField}
				/>
			</form>
		</div>
	);
}
