import React from 'react';
import styles from './teacher-note-view.module.scss';

export function TeacherNoteView({
	name,
	text,
	setEditMode,
}: {
	name: string;
	text: string;
	setEditMode: (name: string) => void;
}) {
	const editText = () => {
		setEditMode(name);
	};

	return (
		<div className={styles.textView}>
			<span className={styles.tagLine}>Lærernote - vises kun til undervisere</span>
			<div
				onClick={editText}				
				dangerouslySetInnerHTML={{ __html: text }}
			/>
		</div>		
	);
}
