import React from 'react';

export function CheckboxUncheckedIcon({ className }: { className?: string }) {
	return (
		<svg width='32px' height='32px' viewBox='0 0 128 128' version='1.1' className={className}>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/checkboxUnchecked' fill='#FF5A5D'>
					<path d='M63.9217923,7.1613756e-15 C28.6761711,7.1613756e-15 4.36843912e-13,28.6761711 4.36843912e-13,63.9217923 C4.36843912e-13,99.1674134 28.6761711,127.843585 63.9217923,127.843585 C99.1674134,127.843585 127.843585,99.1674134 127.843585,63.9217923 C127.843585,28.6761711 99.1674134,7.1613756e-15 63.9217923,7.1613756e-15 Z M63.9217923,121.456619 C32.1955193,121.456619 6.38696538,95.6480652 6.38696538,63.9217923 C6.38696538,32.1955193 32.1955193,6.38696538 63.9217923,6.38696538 C95.6480652,6.38696538 121.456619,32.1955193 121.456619,63.9217923 C121.456619,95.6480652 95.6480652,121.456619 63.9217923,121.456619 Z'></path>
				</g>
			</g>
		</svg>
	);
}
