import React from 'react';
import styles from './author.module.scss';

import { Avatar } from '../../../../../../components/avatar/Avatar';
import { useClassName } from 'hooks/use-class-name';

export function Author({
	name,
	email,
	className,
}: {
	name?: string;
	email?: string;
	className?: string;
}) {
	const classNames = useClassName(styles.author, className);

	return (
		<div className={classNames}>
			<Avatar email={email} className={styles.avatar} />
			<span className={styles.authorName}>{name}</span>
		</div>
	);
}
