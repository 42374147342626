import React from 'react';
import { IIconProps } from './types';

export function FileIcon({ size }: IIconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 128 128'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<title>Fil</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M70.1897 0.00884533C73.6833 0.00884533 77.0339 1.39643 79.5046 3.86645L110.472 34.8255C112.944 37.2964 114.332 40.6481 114.332 44.1429V113.672C114.332 120.948 108.434 126.847 101.157 126.847H26.8428C19.5664 126.847 13.6678 120.948 13.6678 113.672V13.1838C13.6678 5.90749 19.5664 0.00884533 26.8428 0.00884533H70.1897ZM71.1561 6.42757C70.8378 6.38206 70.515 6.35885 70.1897 6.35885H26.8428C23.0734 6.35885 20.0178 9.4145 20.0178 13.1838V113.672C20.0178 117.441 23.0734 120.497 26.8428 120.497H101.157C104.927 120.497 107.982 117.441 107.982 113.672V44.1429C107.982 43.8171 107.959 43.4938 107.913 43.175H84.3311C77.0547 43.175 71.1561 37.2764 71.1561 30V6.42757ZM77.5061 10.8475L103.491 36.825H84.3311C80.5617 36.825 77.5061 33.7693 77.5061 30V10.8475Z'
				fill='#4A4A4A'
			/>
		</svg>
	);
}
