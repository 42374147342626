import React from 'react';
import styles from './not-found.module.scss';

export function NotFound() {
	return (
		<div className={styles.notFound}>
			not found page
		</div>
	);
}
