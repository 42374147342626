

class PreventUnsaved extends HTMLElement {
	isdirty = false;
	innerHTML = '';

	static returnValue = 'Er du sikker på du vil forlade siden? Du har muligvis ændringer som ikke er gemt';
	static get observedAttributes() {
		return ['isdirty'];
	}

	constructor() {
		super();

		window.addEventListener('beforeunload', (e) => this.handleUnload(e));
	}

	isTrue(val: string) {
		return val === 'true';
	}

	handleUnload(event: BeforeUnloadEvent) {
		event.preventDefault();
		if (this.isdirty) {
			event.returnValue = PreventUnsaved.returnValue;
		}
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'isdirty' && oldValue !== newValue) {
			this.isdirty = newValue === 'true';
		}
	}

	connectedCallback() {
		this.isdirty = this.isTrue(this.getAttribute('isdirty'));
	}

	disconnectedCallback() {
		window.removeEventListener('beforeunload', (e) => this.handleUnload(e));
		this.isdirty = false;
	}
}

export const name = 'prevent-unsaved';

if (!window.customElements?.get(name)) {
	window.customElements.define(name, PreventUnsaved);
}