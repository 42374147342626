import React from 'react';

export function CloseIcon() {
	return (
		<svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1'>
			<title>slet</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/close' fill='#FF5A5D'>
					<path
						d='M15.9869579,22 C16.2094208,22 16.4057116,21.9084974 16.5562012,21.7647076 C16.7001478,21.6209178 16.7917502,21.4183049 16.7917502,21.1960843 L16.7917502,16.7974237 L21.1952076,16.7974237 C21.4176706,16.7974237 21.6139614,16.7059211 21.764451,16.5621313 C21.9083976,16.4183415 22,16.2157286 22,15.993508 C22,15.5490668 21.6401335,15.1895923 21.2017507,15.1961282 L16.7982933,15.1961282 L16.7982933,10.7974675 C16.7982933,10.3530263 16.4384267,9.99355184 16.0000439,10.0000877 C15.5551181,10.0000877 15.1952516,10.3595622 15.2017946,10.7974675 L15.2017946,15.1961282 L10.7983372,15.1961282 C10.3534113,15.1961282 9.9935448,15.5556027 10.0000878,15.993508 C10.0000878,16.4379492 10.3599543,16.7974237 10.7983372,16.7908878 L15.2017946,16.7908878 L15.2017946,21.1895484 C15.1821655,21.6405255 15.542032,22 15.9869579,22 Z'
						transform='translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) '
					></path>
				</g>
			</g>
		</svg>
	);
}
