import React, { useState } from 'react';
import styles from './my-courses.module.scss';
import { Button } from 'components/buttons/button/Button';
import { createCourse } from 'modules/course/api/create-course';
import { useHistory } from 'hooks/useHistory';
import { routes } from 'routes';
import { getMyCourses } from 'modules/course/api/get-my-courses';
import { CourseLibrary } from 'modules/course-library/CourseLibrary';
import { useCourseLibraryPage } from 'hooks/useCourseLibraryPage';
import { usePoliteLoadingText } from 'hooks/usePoliteLoadingText';

export function MyCoursesPage() {
	const [creatingStatusText, setCreatingStatusText] = useState<
		string | undefined
	>();
	const history = useHistory();
	const {
		state,
		actions,
		editControls,
		isLoading,
		courses,
		showMore,
		handleShowMore,
	} = useCourseLibraryPage({ getCourses: getMyCourses });

	const {
		politeLoadingText,
		startBeingPolite,
		stopBeingPolite,
	} = usePoliteLoadingText({
		loadingText: 'Opretter',
		politeLoadingText: 'Vent venligst',
	});

	const handleCreateNewCourse = () => {
		startBeingPolite();
		createCourse()
			.then(response => {
				const courseId = response.id;

				if (courseId) {
					history.push({
						pathname: routes.course.setPath(courseId),
					});
				}
			})
			.then(() => stopBeingPolite())
			.catch((error) => {
				setCreatingStatusText('Prøv igen senere...');
				throw error;
			});
	};

	return (
		<div>
			<div className={styles.header}>
				<Button
					onClick={handleCreateNewCourse}
					className={styles.createCourseButton}
					disabled={!!creatingStatusText || !!politeLoadingText}
					variant='primary'
				>
					{politeLoadingText || 'Opret nyt forløb'}
				</Button>
				<h2>Mine forløb</h2>
			</div>
			<CourseLibrary
				courseLibrary={state.courseLibrary}
				actions={actions}
				editControls={editControls}
				courses={courses}
				isLoading={isLoading}
				showMore={showMore}
				onShowMore={handleShowMore}
				showDraftFilter
			/>
		</div>
	);
}
