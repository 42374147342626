import React, { useState } from 'react';
import styles from './create-element.module.scss';

import { TextIcon } from '../../../../../../components/svg/icons/TextIcon';
import { LinkIcon } from '../../../../../../components/svg/icons/LinkIcon';
import { YouTubeIcon } from '../../../../../../components/svg/icons/YouTubeIcon';

import { TextEdit } from '../element/text/TextEdit';
import { LinkEdit } from '../element/link/LinkEdit';
import { YouTubeEdit } from '../element/youtube/YouTubeEdit';
import { ImageEdit } from '../element/image/ImageEdit';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { ExerciseIcon } from 'components/svg/icons/ExerciseIcon';
import { ExerciseEdit } from '../element/exercise/ExerciseEdit';
import { ActionTypes } from 'modules/course/state/actions/course/types';
import { FileEdit } from '../element/file/fileEdit';
import { ELEMENT_ID_MAP, elementOrder } from './element-utils';
import { Icon } from 'components/svg/icons';

const elementTypes: {
	[id: number]: {
		id: number;
		icon: React.ReactElement;
		editor(props: any): React.ReactNode;
	};
} = {
	[ELEMENT_ID_MAP.text]: {
		id: ELEMENT_ID_MAP.text,
		icon: <TextIcon />,
		editor: (props: any) => <TextEdit {...props} />,
	},

	[ELEMENT_ID_MAP.image]: {
		id: ELEMENT_ID_MAP.image,
		icon: <Icon icon='billede' size='32px' />,
		editor: (props: any) => <ImageEdit withDescription={true} {...props} />,
	},
	[ELEMENT_ID_MAP.file]: {
		id: ELEMENT_ID_MAP.file,
		icon: <Icon icon='fil' size='32px' />,
		editor: (props: any) => <FileEdit withDescription={true} {...props} />,
	},
	[ELEMENT_ID_MAP.exercise]: {
		id: ELEMENT_ID_MAP.exercise,
		icon: <ExerciseIcon />,
		editor: (props: any) => <ExerciseEdit {...props} />,
	},

	[ELEMENT_ID_MAP.youtube]: {
		id: ELEMENT_ID_MAP.youtube,
		icon: <YouTubeIcon />,
		editor: (props: any) => <YouTubeEdit {...props} />,
	},
	[ELEMENT_ID_MAP.link]: {
		id: ELEMENT_ID_MAP.link,
		icon: <Icon icon='link' size='32px' />,
		editor: (props: any) => <LinkEdit {...props} />,
	},
};

export function CreateElement({
	chapterId,
	pageId,
	save,
	createElement,
	editControls,
	videoControls,
}: {
	chapterId: string;
	pageId: string;
	save: SaveType;
	createElement: ActionTypes['createElement'];
	editControls: EditControlsType;
	videoControls: any;
}) {
	const [selectedElement, setSelectedElement] = useState<number>();

	if (!editControls(`create-element-${chapterId}-${pageId}`).userCanEdit) {
		return null;
	}

	const selectElementType = (id: number) => {
		setSelectedElement(id);
	};

	const clearSeletedElement = () => {
		setSelectedElement(undefined);
	};

	const handleSaveElement = (name: string) => {
		clearSeletedElement();
		save(name);
	};

	const handleCreateElement = (content: string) => {
		if (selectedElement) {
			// Exercise is always a text type element
			const isExercise = elementTypes[selectedElement].id === 7;
			const elementType = isExercise ? 1 : selectedElement;

			createElement(chapterId, pageId, elementType, isExercise, content);
		}
	};

	if (selectedElement) {
		const editor = elementTypes[selectedElement].editor;

		if (!editor) {
			return null;
		}

		return (
			<div className={styles.editElement}>
				{
					// @ts-ignore
					editor({
						name: `creating-element-type:${selectedElement}`,
						leaveEditMode: clearSeletedElement,
						changeElement: handleCreateElement,
						save: handleSaveElement,
						videoControls: videoControls,
					})
				}
			</div>
		);
	}

	return (
		<div className={styles.createElement}>
			{elementOrder.map((elementId) => (
				<button
					key={elementId}
					onClick={() => selectElementType(elementId)}
					className={styles.elementIcon}
				>
					{elementTypes[elementId].icon}
				</button>
			))}
		</div>
	);
}
