import React, {
	ChangeEvent,
	useRef,
	useEffect,
	useCallback,
	RefObject,
	useState,
	useMemo,
} from 'react';
import { IConfig, FileClient, AfslutFileUploadModel } from 'api';
import accept from 'attr-accept';

interface IUseBlobProvider {
	dropAreaRef: RefObject<HTMLElement>;
	onSuccess?: (
		blobproviderMetadataGuid: string,
		filNavn: string,
		mimeType: string,
	) => void;
	onStartProcessing?: () => void;
	invalidFileErrorMessage: string;
	accepted: 'images' | 'documents';
	setIsUploading: (isUploading: boolean) => void;
}

function getAcceptedString(str: string) {
	if (str === '.doc') {
		return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
	}

	return str;
}

export const useBlobProvider = ({
	dropAreaRef,
	onSuccess,
	onStartProcessing,
	invalidFileErrorMessage,
	accepted,
	setIsUploading,
}: IUseBlobProvider): {
	FileSelector: () => JSX.Element;
	processing: boolean;
	ready: boolean;
} => {
	const [acceptedFileTypes, setAcceptedFileTypes] = useState<string[]>();
	const [processing, setProcessing] = useState(false);
	const fileSelectorRef = useRef<HTMLInputElement>(null);

	const handleSuccess = useCallback(
		async (
			blobproviderMetadataGuid: string,
			filNavn: string,
			mimeType: string,
		) => {
			const getFileUploadUrl = new FileClient(new IConfig());
			getFileUploadUrl.getValidFiletypes();
			const { fileExistsInAzure } = await getFileUploadUrl.afslutUpload(
				new AfslutFileUploadModel({ blobproviderMetadataGuid }),
			);

			if (fileExistsInAzure) {
				onSuccess?.(blobproviderMetadataGuid, filNavn, mimeType);
			} else {
				console.log('Der skete en fejl');
			}
		},
		[onSuccess],
	);

	const uploadFile = useCallback(
		async (file: File) => {
			setIsUploading(true);
			setProcessing(true);
			onStartProcessing?.();
			const getFileUploadUrl = new FileClient(new IConfig());
			const { uploadUrl, blobproviderMetadataGuid } =
				await getFileUploadUrl.getUploadUrl(file.name, file.size);

			if (!uploadUrl || !blobproviderMetadataGuid)
				return setProcessing(false);

			fetch(uploadUrl, {
				method: 'PUT',
				headers: {
					'Content-Type': file.type,
					'x-ms-blob-type': 'BlockBlob',
				},
				body: file,
			})
				.then(() => {
					handleSuccess(
						blobproviderMetadataGuid,
						file.name,
						file.type,
					);
					setIsUploading(false);
				})
				.catch((error) => console.log(error))
				.finally(() => setProcessing(false));
		},
		[handleSuccess],
	);

	const stopEventPropagation = useCallback((event: DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const fileIsValid = ({ name, type }: File) => {
		return acceptedFileTypes.some((str) => {
			return accept(
				{
					name,
					type,
				},
				getAcceptedString(str),
			);
		});
	};

	const handleDrop = useCallback(
		async (event: DragEvent) => {
			stopEventPropagation(event);

			const items = event.dataTransfer?.items;
			const item = items?.[0];
			if (items?.length !== 1 || !item || item.kind !== 'file') return;

			const file = item.getAsFile();

			if (!file) return;

			if (!fileIsValid(file)) {
				alert(invalidFileErrorMessage);

				return;
			}

			uploadFile(file);
		},
		[stopEventPropagation, uploadFile],
	);

	const handleClick = useCallback(
		() => fileSelectorRef.current?.click(),
		[fileSelectorRef],
	);

	const handleFileChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const {
				target: { files },
			} = event;

			if (files?.length !== 1) return;

			if (!fileIsValid(files[0])) {
				alert(invalidFileErrorMessage);

				return;
			}

			uploadFile(files[0]);
		},
		[uploadFile],
	);

	useEffect(() => {
		const cleanup = () => {
			if (!acceptedFileTypes) {
				const fc = new FileClient(new IConfig());
				fc.getValidFiletypes().then((allAcceptedFileTypes) => {
					setAcceptedFileTypes(allAcceptedFileTypes[accepted]);
				});
			}
		};

		return cleanup();
	});

	useEffect(() => {
		const { current } = dropAreaRef;

		if (current) {
			current.addEventListener('click', handleClick);
			current.addEventListener('drop', handleDrop);
			current.addEventListener('dragover', stopEventPropagation);

			return () => {
				current?.removeEventListener('click', handleClick);
				current?.removeEventListener('drop', handleDrop);
				current?.removeEventListener('dragover', stopEventPropagation);
			};
		}
	}, [dropAreaRef, handleClick, handleDrop, stopEventPropagation]);

	const acceptString = useMemo(() => {
		if (!acceptedFileTypes) return '';

		return acceptedFileTypes.join(',');
	}, [acceptedFileTypes]);

	const FileSelector = () => (
		<input
			ref={fileSelectorRef}
			type='file'
			accept={acceptString}
			onChange={handleFileChange}
			style={{ display: 'none' }}
		/>
	);

	return {
		FileSelector,
		processing,
		ready: !!acceptedFileTypes,
	};
};
