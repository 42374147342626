import React, { memo } from 'react';
import { routes } from './routes';
import { Route, Switch, Redirect } from 'react-router';
import { Layout } from './components/Layout';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './components/spinner-text';

import './custom.scss';
import './scss-variables/app.scss';
import { useUser } from './hooks/useUser';
import { Impersonate } from 'pages/admin/impersonate';
import { useRecoilAppConfig } from 'coils/useRecoilAppConfig';

function App() {
	const currentUser = useUser();
	useRecoilAppConfig();

	window.onunhandledrejection = async (e: PromiseRejectionEvent) => {
		if (e.reason.status === 401) {
			window.location.replace('authentication/login');
		}
	};

	return (
		<Layout>
			<Switch>
				<Route
					path={ApplicationPaths.ApiAuthorizationPrefix}
					component={ApiAuthorizationRoutes}
				/>

				<Route
					exact
					path={routes.home.path}
					render={() => routes.home.component}
				/>

				<Route
					exact
					path={routes.aboutSiu.path}
					render={() => routes.aboutSiu.component}
				/>

				<Route
					path={routes.myCourses.path}
					render={() =>
						currentUser.isAuthenticated ? (
							routes.myCourses.component
						) : (
							<Redirect to={routes.login.path} push={false} />
						)
					}
				/>

				<Route
					path={routes.course.path}
					render={() => routes.course.component}
				/>

				<Route
					path={routes.favoriteCourses.path}
					render={() =>
						currentUser.isAuthenticated ? (
							routes.favoriteCourses.component
						) : (
							<Redirect to={routes.login.path} push={false} />
						)
					}
				/>

				<Route
					path={routes.login.path}
					render={() => routes.login.component}
				/>

				<Route
					path={routes.register.path}
					render={() => routes.register.component}
				/>

				<Route
					path={routes.termsOfUse.path}
					render={() => routes.termsOfUse.component}
				/>

				<Route
					path={routes.recoverAccount.path}
					render={() => routes.recoverAccount.component}
				/>

				<Route
					path={routes.knowledgeOfGames.path}
					render={() => routes.knowledgeOfGames.component}
				/>
				<Route path='/admin/impersonate'>
					<Impersonate />
				</Route>
				<Route render={() => routes.notFound.component} />
			</Switch>
		</Layout>
	);
}

export default memo(App);
