import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './course-settings.module.scss';
import * as lang from './course-settings.lang';
import { SwitchButton } from '../../../../components/buttons/switch-button/SwitchButton';
import { SaveType } from 'modules/course/course.types';
import { Button } from 'components/buttons/button/Button';
import { ForloebClient, IConfig } from 'api';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { userState, UserStateType } from 'coils/useRecoilUser';
import { QuestionMarkIcon } from '../../../../components/svg/icons/QuestionMarkIcon';

const forloebClient = new ForloebClient(new IConfig());

const DeleteCourse = ({ courseId }: { courseId: string }) => {
	const history = useHistory();
	const [confirmDelete, setConfirmDelete] = useState(false);
	const userData: UserStateType = useRecoilValue(userState);

	const handleDeleteCourse = () => {
		setConfirmDelete(true);
	};

	const handleConfirmDeleteCourse = () => {
		history.push({
			pathname: routes.myCourses.setPath(userData.user?.sid),
		});
		forloebClient.delete(courseId);
	};

	return (
		<div className={styles.deleteCourse}>
			<Button
				className={styles.deleteCourseButton}
				variant='ghostPrimary'
				onClick={
					confirmDelete
						? handleConfirmDeleteCourse
						: handleDeleteCourse
				}
			>
				{confirmDelete ? 'Bekræft slet' : 'Slet forløb'}
			</Button>
		</div>
	);
};

export function CourseSettings({
	name,
	courseId,
	publishedToLibrary,
	publishedToMinUdannelse,
	publishToLibrary,
	removeFromLibrary,
	userCanEdit,
	publishToMinUddannelse,
	removeFromMinUddannelse,
	save,
	isPublishToLibraryEnabled,
	isPublishToMinUddannelseEnabled,
}: {
	name: string;
	courseId: string;
	publishedToLibrary?: boolean;
	publishedToMinUdannelse?: boolean;
	userCanEdit?: boolean;
	publishToLibrary: () => void;
	removeFromLibrary: () => void;
	publishToMinUddannelse: () => void;
	removeFromMinUddannelse: () => void;
	save: SaveType;
	isPublishToLibraryEnabled: boolean;
	isPublishToMinUddannelseEnabled: boolean;
}) {
	if (!userCanEdit) {
		return null;
	}

	const handleDisplayInLibrary = (display: boolean) => {
		if (display) {
			publishToLibrary();
		} else {
			removeFromLibrary();
			removeFromMinUddannelse();
		}

		save(name);
	};

	const handleDisplayInMinUddannelse = (display: boolean) => {
		display ? publishToMinUddannelse() : removeFromMinUddannelse();

		save(name);
	};

	return (
		<div className={styles.courseSettings}>
			<div className={styles.settingContainer}>
				<h4>{lang.DISPLAY_IN_COURSE_LIBRARY}</h4>
				{isPublishToLibraryEnabled ? null : (
					<QuestionMarkIcon title='Du mangler at tilføje én beskrivelse.' />
				)}
				<div className={styles.switch}>
					<SwitchButton
						isDisabled={!isPublishToLibraryEnabled}
						isSelected={publishedToLibrary}
						onToggle={handleDisplayInLibrary}
					/>
				</div>
			</div>
			<div className={styles.settingContainer}>
				<h4>{lang.DISPLAY_IN_MU}</h4>
				{isPublishToMinUddannelseEnabled ? null : (
					<QuestionMarkIcon
						title={
							'Der skal mindst være:\n· ét kapitel\n· ét afsnit\n· ét element\n· én klasse mellem 0. og 10.\n· ét fag'
						}
					/>
				)}
				<div className={styles.switch}>
					<SwitchButton
						isDisabled={
							!publishedToLibrary ||
							!isPublishToMinUddannelseEnabled
						}
						isSelected={
							isPublishToMinUddannelseEnabled &&
							publishedToMinUdannelse
						}
						onToggle={handleDisplayInMinUddannelse}
					/>
				</div>
			</div>
			<DeleteCourse courseId={courseId} />
		</div>
	);
}
