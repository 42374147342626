

const spinner = /*html*/ `
	<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 512 512"
	>
		<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
		<path d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"/>
	</svg>`;



class SpinnerText extends HTMLElement {
	shadow: ShadowRoot;

	static get observedAttributes() {
		return ['showspinner', 'size'];
	}

	constructor() {
		super();
		this.shadow = this.attachShadow({ mode: 'open' });
	};

	connectedCallback() {
		const isActive = this.getAttribute('showspinner') === 'true';
		const size = this.getAttribute('size') || '18';
		let svg = '';

		if (isActive) {
			svg = spinner;
		}

		this.shadow.innerHTML = /*html*/ `
			<style>
				@keyframes spin {
					0% {
						-webkit-transform: rotate(0deg);
						transform: rotate(0deg);
					}
					100% {
						-webkit-transform: rotate(359deg);
						transform: rotate(359deg);
					}
				}

				span {
					display: inline-flex;
   					align-items: center;
				}

				svg {
					animation: spin 1.6s infinite cubic-bezier(0.5, 0.7, 0.7, 0.5);
					height: ${size}px;
					transform-origin: center;
					margin-left: 8px;
				}
			</style>

			<span><slot></slot>${svg}</span>
		`;


	}

	attributeChangedCallback() {
		this.connectedCallback();
	}
}

export const name = 'spinner-text';

if (!window.customElements?.get(name)) {
	window.customElements.define(name, SpinnerText);
}