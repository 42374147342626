import React from 'react';
import styles from './duration.module.scss';
import * as lang from './duration.lang';

import { DurationView } from './DurationView';
import { DurationEdit } from './DurationEdit';
import { EditControlsType, SaveType } from '../../course.types';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<div className={styles.duration}>
			<h4>{lang.DURATION}</h4>
			{children}
		</div>
	);
};

export function Duration({
	name,
	duration,
	editControls,
	changeDuration,
	save,
}: {
	name: string;
	className?: string;
	duration?: number;
	editControls: EditControlsType;
	changeDuration: (duration: number) => void;
	save: SaveType;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (!userCanEdit && !duration) {
		return null;
	}

	if (editMode) {
		return (
			<Wrapper>
				<DurationEdit
					changeDuration={changeDuration}
					leaveEditMode={leaveEditMode}
					name={name}
					duration={duration}
					save={save}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<DurationView
				name={name}
				duration={duration}
				userCanEdit={userCanEdit}
				setEditMode={setEditMode}
			/>
		</Wrapper>
	);
}
