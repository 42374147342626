import { TextField } from 'components/text-field/TextField';
import { Button } from 'components/buttons/button/Button';
import { Page } from 'pages/_page/Page';
import { useState } from 'react';
import { AccountClient, IConfig, LoginAsModel } from 'api';

export const Impersonate = () => {
	const [email, setEmail] = useState('');
	const handleChangeEmail = (change: { [name: string]: string }) =>
		setEmail(change['email']);
	const accountClient = new AccountClient(new IConfig());

	const handleImpersonateAccount = async (
		event: React.SyntheticEvent<HTMLFormElement>,
	) => {
		event.preventDefault();

		await accountClient.loginAs(
			new LoginAsModel({ email: email }),
		);
		window.location.href = '/authentication/login';
	};

	return (
		<Page
			pageClass='container'
			backgroundColor='var(--color-background-light)'
		>
			<div className='row'>
				<div className='col-md-12'>
					<form
						autoComplete='off'
						onSubmit={handleImpersonateAccount}
					>
						<TextField
							id='email'
							placeholder='E-mail'
							onChange={handleChangeEmail}
							value={email}
              className='mb-2'
						/>
						<div>
							<Button
								variant='ghostPrimary'
								onClick={handleImpersonateAccount}
							>
								Impersonate
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Page>
	);
};
