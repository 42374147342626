import React from 'react';
import { IIconProps } from './types';

export function ImageIcon({ size }: IIconProps) {
	return (
		<svg width={size} height={size} viewBox='0 0 32 32' version='1.1'>
			<title>billede</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/contentbar/image' fill='#4A4A4A'>
					<path d='M-5.32907052e-14,28.6260727 C-5.32907052e-14,30.4895791 1.51695954,32 3.37392726,32 L28.6260727,32 C30.4895791,32 32,30.4830405 32,28.6260727 L32,3.37392726 C32,1.51042092 30.4830405,3.55271368e-15 28.6260727,3.55271368e-15 L3.37392726,3.55271368e-15 C1.51042092,3.55271368e-15 -5.32907052e-14,1.51695954 -5.32907052e-14,3.37392726 L-5.32907052e-14,28.6260727 Z M28.6260727,30.3980384 L3.37392726,30.3980384 C2.39967307,30.3980384 1.60196159,29.6003269 1.60196159,28.6260727 L1.60196159,24.4086637 L7.66979975,18.3408255 L12.8549244,23.5259501 C13.1687781,23.8398038 13.6722517,23.8398038 13.9861054,23.5259501 L23.3494074,14.1626481 L30.3980384,21.2112791 L30.3980384,28.6260727 C30.3980384,29.6003269 29.6003269,30.3980384 28.6260727,30.3980384 Z M28.6260727,1.60196159 C29.6003269,1.60196159 30.3980384,2.39967307 30.3980384,3.37392726 L30.3980384,18.9423784 L23.9117286,12.4626073 C23.5978749,12.1487536 23.0944013,12.1487536 22.7805476,12.4626073 L13.4172456,21.8259093 L8.23212096,16.6407846 C7.91826727,16.3269309 7.41479362,16.3269309 7.10093993,16.6407846 L1.60196159,22.139763 L1.60196159,3.37392726 C1.60196159,2.39967307 2.39967307,1.60196159 3.37392726,1.60196159 L28.6260727,1.60196159 Z M9.91908459,4.67511238 C7.67633837,4.67511238 5.84552513,6.50592562 5.84552513,8.74867184 C5.84552513,10.9914181 7.66979975,12.8222313 9.91908459,12.8222313 C12.1683694,12.8222313 13.9926441,10.9914181 13.9926441,8.74867184 C13.9926441,6.50592562 12.1618308,4.67511238 9.91908459,4.67511238 Z M9.91908459,6.27707397 C11.2856559,6.27707397 12.3906825,7.38863915 12.3906825,8.74867184 C12.3906825,10.1087045 11.2791173,11.2202697 9.91908459,11.2202697 C8.5590519,11.2202697 7.44748672,10.1087045 7.44748672,8.74867184 C7.44748672,7.38863915 8.55251328,6.27707397 9.91908459,6.27707397 Z'></path>
				</g>
			</g>
		</svg>
	);
}
