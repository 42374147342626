import { AppConfigClient, IConfig } from 'api';
import { useEffect } from 'react';
import { atom, selector, useRecoilState } from 'recoil';

export type AppConfigData = {
	muBaseUrl: string;
};

export const appConfigData = atom<AppConfigData>({
	key: 'appConfig',
	default: { muBaseUrl: '' },
});

export const appConfigState = selector({
	key: 'appConfigState',
	get: ({ get }) => {
		return get(appConfigData);
	},
});

export function useRecoilAppConfig() {
	const [state, setState] = useRecoilState(appConfigData);

	useEffect(() => {
		async function fetchAppConfig() {
			const appConfigClient = new AppConfigClient(new IConfig());
			const { muBaseUrl } = await appConfigClient.get();

			setState({ muBaseUrl: muBaseUrl });
		}

		if (state.muBaseUrl === '') {
			fetchAppConfig();
		}
	}, []);
}
