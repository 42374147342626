import React, { useState } from 'react';
import styles from './register.module.scss';
import registerArt from './images/signup.svg';
import { TextField } from '../../components/text-field/TextField';
import { Button } from '../../components/buttons/button/Button';
import { TermsOfUse } from './terms-of-use';

import {
	AccountClient,
	IConfig,
	ICreateAccountModel,
	CreateAccountModel,
} from '../../api';

import { useHistory } from '../../hooks/useHistory';
import * as lang from './register.lang';

export function Register() {
	const history = useHistory();
	const accountClient = new AccountClient(new IConfig());

	const [state, setState] = useState<
		ICreateAccountModel & { _errors?: Record<string, string[]> }
	>({
		email: '',
		fullName: '',
		password: '',
		confirmPassword: '',
	});

	const handleChangeText = (change: Record<string, string>) =>
		setState({ ...state, ...change });

	const handleRegisterUser = (
		event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>,
	) => {
		event.preventDefault();
		setState((state) => ({ ...state, _errors: undefined }));

		const registerModel: ICreateAccountModel = {
			email: state.email,
			fullName: state.fullName,
			password: state.password,
			confirmPassword: state.password,
		};

		accountClient
			.create(new CreateAccountModel(registerModel))
			.then(() => history.push({ pathname: '/authentication/login' }))
			.catch((e) =>
				setState((state) => ({
					...state,
					_errors: e?.errors,
				})),
			);
	};

	const isRegisterButtonDisabled =
		!state.email && !state.password && !state.fullName;

	return (
		<>
			<div className='col-md-5'>
				<img src={registerArt} alt='register art' />
			</div>
			<div className='col-md-5 offset-md-2'>
				<h1 className={styles.title}>{lang.TITLE}</h1>
				<form autoComplete='on' onSubmit={handleRegisterUser}>
					<TextField
						id='fullName'
						className={styles.name}
						placeholder={lang.NAME}
						onChange={handleChangeText}
						labelText={lang.NAME}
						errorMessage={state._errors?.FullName?.[0]}
					/>
					<TextField
						id='email'
						className={styles.email}
						placeholder={lang.EMAIL}
						onChange={handleChangeText}
						labelText={lang.EMAIL}
						errorMessage={state._errors?.Email?.[0] ?? state._errors?.DuplicateUserName?.[0]}
					/>
					<TextField
						id='password'
						className={styles.password}
						type='password'
						placeholder={lang.PASSWORD}
						onChange={handleChangeText}
						labelText={lang.PASSWORD}
						errorMessage={state._errors?.Password?.[0]}
					/>

					<Button
						disabled={isRegisterButtonDisabled}
						type='submit'
						variant='ghostSecondary'
						onClick={handleRegisterUser}
					>
						{lang.REGISTER_USER}
					</Button>
				</form>
				<TermsOfUse />
			</div>
		</>
	);
}
