export type IUpdateMethod<T> = (newValues: T, oldState: T) => void | Promise<void>;

export interface IObserver<T> {
	updateMethod: IUpdateMethod<T>;
	unsubscribe: (value: IObserver<T>) => boolean;
}


class Observable<T> {
	observers: Set<IObserver<T>> = new Set();
	state!: T;
	oldState!: T;

	constructor() {
		this.observers = new Set();
	}

	subscribe(updateMethod: IUpdateMethod<T>) {
		const observer: IObserver<T> = {
			updateMethod,
			unsubscribe: (createdObserver: IObserver<T>) => this.observers.delete(createdObserver),
		};
		this.observers.add(observer);

		return observer;
	}

	setState(newState: T) {
		this.state = newState;
		this.broadcastUpdate();
	}

	// Denne broadcaster updates til alle observers(subscribers).
	broadcastUpdate() {
		this.observers.forEach((observer) => {
			observer.updateMethod(this.state, this.oldState);
		});
		this.oldState = this.state;
	}
}

export { Observable };
