import React from 'react';
import { SaveType } from 'modules/course/course.types';
import { ElementDto } from 'api';
import { ImageEdit as CourseImageEdit } from 'modules/course/components/image/ImageEdit';

interface IImageEdit {
	name: string;
	element?: ElementDto;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement: () => void;
	save: SaveType;
	withDescription?: boolean;
}

export function ImageEdit({ element, ...props }: IImageEdit) {
	return (
		<CourseImageEdit
			{...props}
			element={element}
			image={element?.indhold}
		/>
	);
}
