import React from 'react';

export function HeartEmptyIcon({ className }: { className?: string }) {
	return (
		<svg
			width='32px'
			height='32px'
			viewBox='0 0 32 32'
			version='1.1'
			className={className}
		>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/checkboxChecked' fill='#FF5A5D'>
					<path d='M23.168,2.25 C28.046,2.25 32,6.20571058 32,11.0858209 C32,13.7889898 30.786,16.2100368 28.874,17.8307376 L15.962,30 L2.976,17.7006814 C1.15,16.0819814 0,13.7189596 0,11.0858209 C0,6.20571058 3.954,2.25 8.832,2.25 C11.782,2.25 14.396,3.69662557 16,5.92158771 C17.604,3.69662557 20.216,2.25 23.168,2.25 Z M23.168,4 C20.9003693,4 18.8171884,5.06973997 17.4887873,6.85058906 L17.4195715,6.94497264 L16,8.91410786 L14.5804285,6.94497264 C13.2563294,5.10827037 11.1381877,4 8.832,4 C4.9206678,4 1.75,7.17203949 1.75,11.0858209 C1.75,13.1038428 2.5943292,14.9798512 4.05050699,16.313334 L4.15844913,16.4102761 L15.96475,27.59225 L27.707278,16.5255999 L27.74244,16.4957949 C29.3226952,15.1562966 30.25,13.1978536 30.25,11.0858209 C30.25,7.17203949 27.0793322,4 23.168,4 Z'></path>
				</g>
			</g>
		</svg>
	);
}
