import React from 'react';
import styles from './link.module.scss';
import { ElementDto } from 'api';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { LinkView } from './LinkView';
import { LinkEdit } from './LinkEdit';
import { ActionTypes } from 'modules/course/state/actions/course/types';
import { useClassName } from 'hooks/use-class-name';

interface ILink {
	chapterId: string;
	pageId: string;
	element: ElementDto;
	editControls: EditControlsType;
	save: SaveType;
	changeElement: ActionTypes['changeElement'];
	deleteElement: ActionTypes['deleteElement'];
}

const Wrapper = ({
	children,
	userCanEdit,
}: {
	children: React.ReactNode;
	userCanEdit?: boolean;
}) => {
	const classNames = useClassName(userCanEdit && styles.editable);

	return <div className={classNames}>{children}</div>;
};

export function Link({
	chapterId,
	pageId,
	element,
	editControls,
	save,
	changeElement,
	deleteElement,
}: ILink) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(
		element.id,
	);

	const handleChangeElement = (content: string) => {
		changeElement(chapterId, pageId, element.id, content);
	};

	const handleDeleteElement = () => {
		deleteElement(chapterId, pageId, element.id);
		save(element.id);
	};

	const handleSetEditMode = () => setEditMode(element.id);

	if (editMode) {
		return (
			<Wrapper>
				<LinkEdit
					name={element.id}
					element={element}
					changeElement={handleChangeElement}
					leaveEditMode={leaveEditMode}
					deleteElement={handleDeleteElement}
					save={save}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper userCanEdit={userCanEdit}>
			<LinkView
				userCanEdit={userCanEdit}
				setEditMode={handleSetEditMode}
				element={element}
			/>
		</Wrapper>
	);
}
