import { FagDto } from 'api';
import { GlobalActionType } from './types';
import { imageViewService } from 'modules/webcomponents/services/image-view.service';

export function globalActions(dispatch: (action: GlobalActionType) => void) {
	return {
		setUpdated(name: string) {
			dispatch({
				type: 'setUpdated',
				payload: { name },
			});
		},
		
		setEditMode(name: string) {
			dispatch({
				type: 'setEditMode',
				payload: { name },
			});
		},

		leaveEditMode(name: string, clearAllEditing?: boolean) {
			dispatch({
				type: 'leaveEditMode',
				payload: { name, clearAllEditing },
			});
		},

		loadSubjects(_subjects: FagDto[]) {
			dispatch({
				type: 'loadSubjects',
				payload: { _subjects },
			});
		},

		setPlayingVideo(id: string) {
			dispatch({
				type: 'setPlayingVideo',
				payload: { id },
			});
		},
	};
}
