import React from 'react';

export function TextIcon() {
	return (
		<svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1'>
			<title>tekst</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/contentbar/text' fill='#4A4A4A'>
					<path
						d='M28.6288281,0.0129591837 C30.4842793,0.0129591837 32,1.52867989 32,3.38413109 L32,3.38413109 L32,28.6417873 C32,30.4972385 30.4908126,32.0129592 28.6288281,32.0129592 L28.6288281,32.0129592 L3.37117191,32.0129592 C1.5157207,32.0129592 0,30.5037718 0,28.6417873 L0,28.6417873 L0,3.38413109 C0,1.52867989 1.50918742,0.0129591837 3.37117191,0.0129591837 L3.37117191,0.0129591837 Z M28.6288281,1.61361251 L3.37117191,1.61361251 C2.39771335,1.61361251 1.60065333,2.41067254 1.60065333,3.38413109 L1.60065333,3.38413109 L1.60065333,28.6417873 C1.60065333,29.6152458 2.39771335,30.4123059 3.37117191,30.4123059 L3.37117191,30.4123059 L28.6288281,30.4123059 C29.6022866,30.4123059 30.3993467,29.6152458 30.3993467,28.6417873 L30.3993467,28.6417873 L30.3993467,3.38413109 C30.3993467,2.41067254 29.6022866,1.61361251 28.6288281,1.61361251 L28.6288281,1.61361251 Z M16.0137831,7.56203433 C16.0639316,7.56203433 16.1130948,7.56625308 16.1609371,7.57435503 L21.8114251,7.57510089 C22.2946743,7.57510089 22.6864251,7.96685173 22.6864251,8.45010089 L22.6864251,8.46460062 C22.6864251,8.94784978 22.2946743,9.33960062 21.8114251,9.33960062 L16.888,9.33895918 L16.8887831,24.3320316 C16.8887831,24.8152808 16.4970323,25.2070316 16.0137831,25.2070316 L15.9992834,25.2070316 C15.5160343,25.2070316 15.1242834,24.8152808 15.1242834,24.3320316 L15.124,9.33895918 L10.2016414,9.33960062 C9.71839228,9.33960062 9.32664144,8.94784978 9.32664144,8.46460062 L9.32664144,8.45010089 C9.32664144,7.96685173 9.71839228,7.57510089 10.2016414,7.57510089 L15.8521295,7.57435503 C15.8999718,7.56625308 15.9491349,7.56203433 15.9992834,7.56203433 L16.0137831,7.56203433 Z'
					></path>
				</g>
			</g>
		</svg>
	);
}
