import React, { useState, ChangeEvent } from 'react';
import styles from './link-edit.module.scss';
import { Editor } from 'components/editor/Editor';
import { EditorTitle } from 'components/editor/components/editor-title/EditorTitle';
import { TextField } from 'components/text-field/TextField';
import { Button } from 'components/buttons/button/Button';
import { LinkType } from './types';
import { ElementDto, LinkPreviewClient, IConfig } from 'api';
import { convertJsonToLinkElement } from './utils/convert-json-to-link-element';
import { SaveType } from 'modules/course/course.types';
import { Icon } from 'components/svg/icons';

interface ILinkEdit {
	name: string;
	element?: ElementDto;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement?: () => void;
	save: SaveType;
}

export function LinkEdit({
	name,
	element,
	leaveEditMode,
	changeElement,
	deleteElement,
	save,
}: ILinkEdit) {
	const [state, setState] = useState<LinkType>(
		convertJsonToLinkElement(element),
	);

	const handleChange = (value: Record<string, LinkType>) => {
		setState((state) => ({
			...state,
			data: {
				...state.data,
				...value,
			},
		}));
	};

	const handleChangedUrl = async ({
		target: { value },
	}: ChangeEvent<HTMLInputElement>) => {
		if (!value) return;

		const linkPreviewClient = new LinkPreviewClient(new IConfig());

		linkPreviewClient
			.get(value)
			.then((res) => {
				const { title = '', description = '', image = '' } = res;

				setState((state) => ({
					...state,
					data: {
						...state.data,
						emne: title,
						beskrivelse: description,
						image,
					},
				}));
			})
			.catch(() => {});
	};

	const handleDelete = () => {
		deleteElement?.();
		leaveEditMode(name);
	};

	const handleSave = () => {
		if (state.data.url) {
			changeElement(JSON.stringify(state));
		}
		save(name);
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	return (
		<Editor
			onCancel={handleLeaveEditMode}
			onSave={handleSave}
			title={
				<EditorTitle
					title='Link'
					icon={<Icon icon='link' size='32px' />}
				/>
			}
		>
			<div className={styles.urlPanel}>
				<TextField
					id='url'
					onChange={handleChange}
					onBlur={handleChangedUrl}
					autoComplete='off'
					className={styles.url}
					placeholder='Indsæt URL...'
					value={state.data.url}
				/>
			</div>
			<div className={styles.contentContainer}>
				<TextField
					id='emne'
					className={styles.titleField}
					autoComplete='off'
					onChange={handleChange}
					placeholder='Tilføj titel...'
					value={state.data.emne}
				/>
				<TextField
					id='beskrivelse'
					className={styles.descriptionField}
					onChange={handleChange}
					autoComplete='off'
					placeholder='Tilføj beskrivelse...'
					value={state.data.beskrivelse}
					type='textarea'
				/>
				{element && (
					<Button
						onClick={handleDelete}
						variant='danger'
						className={styles.deleteButton}
					>
						Slet link
					</Button>
				)}
			</div>
		</Editor>
	);
}
