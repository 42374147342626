import React from 'react';
import styles from '../link/link-view.module.scss';
import { ElementDto } from 'api';
import { cutTextAtLength } from 'utils/text';
import {
	FileUploadStateType,
	emptyState,
} from 'components/file/file-upload/FileUpload';
import { JSONTryParseWithDefaultValue } from 'utils/json';
import { Icon } from 'components/svg/icons';
import { useClassName } from 'hooks/use-class-name';

interface IFileView {
	element: ElementDto;
	setEditMode: () => void;
	userCanEdit?: boolean;
}

type FileUploadDataStateType = { data: FileUploadStateType };

function convertJsonToFilElement(
	element?: ElementDto,
): FileUploadDataStateType {
	if (!element) return { data: emptyState };

	return JSONTryParseWithDefaultValue<
		FileUploadDataStateType,
		FileUploadDataStateType
	>(element.indhold, { data: emptyState });
}

export function FileView({ element, setEditMode, userCanEdit }: IFileView) {
	const converted = convertJsonToFilElement(element);
	const { emne, beskrivelse, url, _base } = converted?.data || {};
	const filNavn = _base?.filNavn;
	const wrapperClassName = useClassName(
		styles.wrapper,
		userCanEdit && styles.editable,
	);

	const descriptionCuttingLength = 100;
	const descriptionLength = beskrivelse?.length || 0;
	const isShortDescriptionCutted =
		descriptionLength > descriptionCuttingLength;

	const shortDescription = isShortDescriptionCutted
		? cutTextAtLength(beskrivelse, descriptionCuttingLength)
		: beskrivelse;

	return (
		<div className={wrapperClassName}>
			<div className={styles.preview}>
				<div className={styles.iconPreviewContainer}>
					<a
						href={url}
						target='_blank'
						rel='noopener noreferrer'
						title={`Hent ${filNavn}`}
						className={styles.url}
					>
						<Icon icon='fil' size='48px' />
					</a>
				</div>
				<div>
					<div
						className={styles.element}
						onClick={userCanEdit ? setEditMode : undefined}
					>
						<div className={styles.title}>{emne}</div>
						<div
							title={beskrivelse}
							className={styles.shortDescription}
						>
							{shortDescription}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
