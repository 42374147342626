export class ShadowElement extends HTMLElement {
	shadow: ShadowRoot;

	constructor() {
		super();
		this.shadow = this.attachShadow({ mode: 'open' });
	}
}

export function wcDefine(
	name,
	webcomponentClass
) {
	if (window.customElements && !window.customElements.get(name)) {
		window.customElements.define(name, webcomponentClass);
	}

	return name;
}
