import React from 'react';
import styles from './learning-objective-view.module.scss';

import { Bullet } from '../../../../components/bullet/Bullet';
import { LaeringsmaalDto } from '../../../../api';
import { LearningObjectiveIcon } from '../../../../components/svg/icons/LearningObjectiveIcon';
import { useClassName } from 'hooks/use-class-name';

const SubObjectivesList = ({
	learningObjective,
}: {
	learningObjective: LaeringsmaalDto;
}) => {
	if (!learningObjective.tegnPaaLaering?.length) {
		return null;
	}

	return (
		<div className={styles.subObjectivesList}>
			{learningObjective?.tegnPaaLaering?.map((subObjective, key) => (
				<React.Fragment key={key}>
					<Bullet className={styles.bullet} />
					<div>{subObjective}</div>
				</React.Fragment>
			))}
		</div>
	);
};

export function LearningObjectiveView({
	name,
	userCanEdit,
	setEditMode,
	learningObjective,
}: {
	name: string;
	userCanEdit?: boolean;
	setEditMode: (name: string) => void;
	learningObjective: LaeringsmaalDto;
}) {
	const editLearningObjective = () => {
		setEditMode(name);
	};

	const viewClassNames = useClassName(
		styles.learningObjectiveView,
		userCanEdit ? styles.editable : null,
	);

	return (
		<div onClick={editLearningObjective} className={viewClassNames}>
			<div className={styles.learningObjectiveText}>
				<div>
					<LearningObjectiveIcon />
				</div>
				<div>{learningObjective.tekst}</div>
			</div>
			<SubObjectivesList learningObjective={learningObjective} />
		</div>
	);
}
