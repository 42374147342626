import React from 'react';
import styles from './description-view.module.scss';
import { ADD_DESCRIPTION } from '../../course.lang';
import { useClassName } from 'hooks/use-class-name';

export function DescriptionView({
	name,
	text,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	text?: string;
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const contentClassNames = useClassName(
		styles.text,
		userCanEdit && styles.editable,
	);

	const editDescription = () => {
		setEditMode(name);
	};

	const makeText = (text?: string) => {
		if (!text) {
			return <div className={styles.noText}>{ADD_DESCRIPTION}</div>;
		}

		return (
			<div
				className={styles.text}
				dangerouslySetInnerHTML={{ __html: text }}
			/>
		);
	};

	return (
		<div className={styles.descriptionView}>
			<div onClick={editDescription} className={contentClassNames}>
				{makeText(text)}
			</div>
		</div>
	);
}
