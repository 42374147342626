import React from 'react';
import styles from './description.module.scss';

import { DESCRIPTION } from '../../course.lang';
import { DescriptionView } from './DescriptionView';
import { DescriptionEdit } from './DescriptionEdit';
import { EditControlsType, SaveType } from '../../course.types';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.description}>
		<h4 className={styles.title}>{DESCRIPTION}</h4>
		{children}
	</div>
);

export function Description({
	name,
	text,
	editControls,
	changeDescription,
	save,
}: {
	name: string;
	text: string | undefined;
	editControls: EditControlsType;
	changeDescription: (description: string) => void;
	save: SaveType;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (!userCanEdit && !text?.length) {
		return null;
	}

	if (editMode) {
		return (
			<Wrapper>
				<DescriptionEdit
					text={text}
					leaveEditMode={leaveEditMode}
					changeDescription={changeDescription}
					save={save}
					name={name}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<DescriptionView
				setEditMode={setEditMode}
				name={name}
				text={text}
				userCanEdit={userCanEdit}
			/>
		</Wrapper>
	);
}
