import { NEW_COURSE } from '../course.lang';
import { IForloebModel, FagDto, IForloebSearchDto } from 'api';

export type StateType = {
	course: IForloebModel;
	courseLibrary: IForloebSearchDto & { _subjects?: FagDto[]; };
	_editModeEnabled: boolean;
	_editing: string[];
	_loading: boolean;
	_subjects: FagDto[];
	isUpdated: boolean;
	_playingVideo?: string;
	courseSearchUpdateTimestamp: number;
};

export const initialState: StateType = {
	courseSearchUpdateTimestamp: 0,
	course: {
		titel: NEW_COURSE,
		beskrivelseTilLaerer: '',
		beskrivelseTilElev: '',
		kapitler: [],
		forfattere: [],
		fag: [],
		kategorier: [],
		visPaaMinUddannelse: false,
		visIBiblioteket: false,
		maaRedigere: false,
	},
	courseLibrary: {
		term: '',
		fag: undefined,
		trin: undefined,
		kategorier: undefined,
		lastSeenId: undefined,
		limit: 12,
		erKladde: undefined,
		_subjects: undefined,
	},
	_editModeEnabled: false,
	_editing: [],
	_loading: true,
	_subjects: [],
	isUpdated: false,
};
