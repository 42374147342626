import React from 'react';

export function ExerciseIcon() {
	return (
		<svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1'>
			<title>opgave</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/contentbar/exercise' fill='#4A4A4A'>
					<path
						d='M28.6588918,0.800256148 C28.6588918,0.357838115 28.3009665,-2.48689958e-14 27.8584407,-2.48689958e-14 L8.43936856,-2.48689958e-14 C5.71262887,-2.48689958e-14 3.5,2.21859631 3.5,4.93816598 L3.5,26.7793033 C3.5,29.5053791 5.7191366,31.7174693 8.43936856,31.7174693 L27.8649485,31.7174693 C28.3074742,31.7174693 28.6653995,31.3596311 28.6653995,30.9172131 L28.6653995,9.08258197 C28.6653995,8.64016393 28.3074742,8.28232582 27.8649485,8.28232582 L8.43936856,8.28232582 C6.59768041,8.28232582 5.09439433,6.77940574 5.09439433,4.93816598 C5.09439433,3.09692623 6.59768041,1.59400615 8.43936856,1.59400615 L27.8649485,1.59400615 C28.3009665,1.59400615 28.6588918,1.23616803 28.6588918,0.800256148 Z M5.09439433,8.56859631 C5.97293814,9.38186475 7.15083763,9.87633197 8.43936856,9.87633197 L27.0644974,9.87633197 L27.0644974,30.1234631 L8.43936856,30.1234631 C6.59768041,30.1234631 5.09439433,28.620543 5.09439433,26.7793033 L5.09439433,8.56859631 Z M26.9408505,4.21598361 C27.3833763,4.21598361 27.7413015,4.57382172 27.7413015,5.01623975 C27.7413015,5.45865779 27.3833763,5.8164959 26.9408505,5.8164959 L26.9408505,5.8164959 L8.7126933,5.8164959 C8.27016753,5.8164959 7.91224227,5.45865779 7.91224227,5.01623975 C7.91224227,4.57382172 8.27016753,4.21598361 8.7126933,4.21598361 L8.7126933,4.21598361 Z'
					></path>
				</g>
			</g>
		</svg>
	);
}
