import React from 'react';
import styles from './course.module.scss';

import { Image } from './components/image/Image';
import { Title } from './components/title/Title';
import { Subjects } from './components/subjects/Subjects';
import { Authors } from './components/authors/Authors';
import { Grades } from './components/grades/Grades';
import { Category } from './components/category/Category';
import { Description } from './components/description/Description';
import { TeacherInstructions } from './components/teacher-instructions/TeacherInstructions';
import { Duration } from './components/duration/Duration';
import { CourseSettings } from './components/course-settings/CourseSettings';
import { gradesMap } from './components/grades/grades-map';

import { Ruler } from './components/ruler/Ruler';
import { ButtonGroup } from '../../components/buttons/button-group/ButtonGroup';

import { GlobalActionTypes } from './state/actions/global/types';
import { ActionTypes } from './state/actions/course/types';
import { EditControlsType } from './course.types';
import { IForloebModel } from 'api';
import { ADD_TITLE } from './course.lang';

type CourseType = {
	id: string;
	globalActions: GlobalActionTypes;
	actions: ActionTypes;
	editControls: EditControlsType;
	course: IForloebModel;
};

const FIRSTGRADE = gradesMap[0].id;
const TENTHGRADE = gradesMap[10].id;

export function Course(props: CourseType) {
	const M16Block = () => (
		<div style={{ marginTop: 2, marginBottom: 2 }}>&nbsp;</div>
	);

	const isPublishToMinUddannelseEnabled = props.course.kapitler?.some(
		(k) =>
			k.sider.some((s) => s.elementer.length) &&
			!!props.course.fag?.length &&
			props.course.klassetrinFra >= FIRSTGRADE &&
			props.course.klassetrinTil <= TENTHGRADE,
	);

	return (
		<div className={styles.course}>
			<div className={styles.courseContent}>
				<Image
					name='image'
					save={props.globalActions.setUpdated}
					image={props.course.billede}
					changeImage={props.actions.changeImage}
					editControls={props.editControls}
					isCourseImage
				/>

				<Title
					name='course-title'
					text={props.course.titel}
					save={props.globalActions.setUpdated}
					changeTitle={props.actions.changeTitle}
					editControls={props.editControls}
					placeholder={ADD_TITLE}
				/>

				<Ruler />

				<div className={styles.sbjectsGradesCategories}>
					<ButtonGroup alignment='horiziontal'>
						<Subjects
							name='subjects'
							selectedSubjects={props.course.fag}
							save={props.globalActions.setUpdated}
							changeSubjects={props.actions.setSubjects}
							editControls={props.editControls}
						/>

						<Grades
							name='grades'
							grades={{
								from: props.course.klassetrinFra,
								to: props.course.klassetrinTil,
							}}
							save={props.globalActions.setUpdated}
							onChange={props.actions.setCourseGrade}
							editControls={props.editControls}
						/>

						<Category
							name='category'
							categories={props.course.kategorier}
							save={props.globalActions.setUpdated}
							editControls={props.editControls}
							onChange={props.actions.changeCourseCategories}
						/>
					</ButtonGroup>
				</div>

				<Authors
					name='authors'
					authors={props.course.forfattere}
					editControls={props.editControls}
					save={props.globalActions.setUpdated}
					changeAuthors={props.actions.changeAuthors}
				/>

				<Ruler
					isHidden={
						props.editControls('description').editMode ||
						props.editControls('authors').editMode
					}
				/>

				<Description
					name='description'
					text={props.course.beskrivelseTilElev}
					save={props.globalActions.setUpdated}
					changeDescription={props.actions.changeDescription}
					editControls={props.editControls}
				/>

				<Ruler
					isHidden={
						props.editControls('description').editMode ||
						props.editControls('teacherInstructions').editMode
					}
				/>

				<TeacherInstructions
					name='teacherInstructions'
					text={props.course.beskrivelseTilLaerer}
					save={props.globalActions.setUpdated}
					changeTeacherInstructions={
						props.actions.changeTeacherInstructions
					}
					editControls={props.editControls}
				/>

				<Ruler
					isHidden={
						props.editControls('teacherInstructions').editMode
					}
				/>

				<M16Block />

				<Duration
					name='duration'
					duration={props.course.varighed}
					save={props.globalActions.setUpdated}
					changeDuration={props.actions.changeDuration}
					editControls={props.editControls}
				/>

				<CourseSettings
					name='courseSettings'
					courseId={props.id}
					publishedToLibrary={props.course.visIBiblioteket}
					publishedToMinUdannelse={props.course.visPaaMinUddannelse}
					publishToLibrary={props.actions.publishToLibrary}
					removeFromLibrary={props.actions.removeFromLibrary}
					publishToMinUddannelse={
						props.actions.publishToMinUddannelse
					}
					removeFromMinUddannelse={
						props.actions.removeFromMinUddannelse
					}
					save={props.globalActions.setUpdated}
					userCanEdit={props.course.maaRedigere}
					isPublishToLibraryEnabled={
						!!props.course.beskrivelseTilElev
					}
					isPublishToMinUddannelseEnabled={
						isPublishToMinUddannelseEnabled
					}
				/>
			</div>
		</div>
	);
}
