import React, { useState, useEffect } from 'react';
import styles from './learning-objective-edit.module.scss';

import { Editor } from '../../../../components/editor/Editor';
import { TextField } from '../../../../components/text-field/TextField';
import { Button } from '../../../../components/buttons/button/Button';
import { LaeringsmaalDto } from '../../../../api';
import { SubObjectives } from './components/sub-objective/SubObjectives';

import { EditorTitle } from '../../../../components/editor/components/editor-title/EditorTitle';
import { LearningObjectiveIcon } from '../../../../components/svg/icons/LearningObjectiveIcon';
import { SaveType } from 'modules/course/course.types';

import * as lang from './learning-objective.lang';

export function LearningObjectiveEdit({
	name,
	learningObjective,
	leaveEditMode,
	save,
	changeLearningObjective,
	chapterId,
	createLearningObjective,
	deleteLearningObjective,
	allowDelete,
}: {
	name: string;
	leaveEditMode: (name: string) => void;
	save: SaveType;
	learningObjective: LaeringsmaalDto;
	changeLearningObjective: (
		chapterId: string,
		learningObjective: LaeringsmaalDto,
	) => void;
	chapterId: string;
	createLearningObjective?: (
		chapterId: string,
		learningObjective: LaeringsmaalDto,
	) => void;
	allowDelete?: boolean;
	deleteLearningObjective?: (
		chapterId: string,
		learningObjectiveId: string,
	) => void;
}) {
	const [state, setState] = useState<LaeringsmaalDto>();

	useEffect(() => {
		setState(learningObjective);
	}, [learningObjective]);

	const isSaveDisabled = !state?.tekst;

	const handleChangeLearningObjectiveText = (change: {
		[name: string]: string;
	}) => {
		if (state) {
			setState(
				new LaeringsmaalDto({
					...state,
					tekst: change[learningObjective.id],
				}),
			);
		}
	};

	const handleChangeSubObjectives = (subObjectives?: string[]) => {
		if (subObjectives && state) {
			setState(
				new LaeringsmaalDto({
					...state,
					tegnPaaLaering: subObjectives,
				}),
			);
		}
	};

	const handleSave = () => {
		if (isSaveDisabled) {
			return;
		}

		const saveModel = new LaeringsmaalDto(state);

		if (createLearningObjective) {
			createLearningObjective(chapterId, saveModel);
			save(name);
			leaveEditMode(name);

			return;
		}

		changeLearningObjective(chapterId, saveModel);
		save(name);
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	const handleDelete = () => {
		if (state?.id && deleteLearningObjective) {
			deleteLearningObjective(chapterId, state.id);
			save(name);
			leaveEditMode(name);
		}
	};

	return (
		<Editor
			isSaveDisabled={isSaveDisabled}
			onCancel={handleLeaveEditMode}
			onSave={handleSave}
			title={
				<EditorTitle
					title={lang.GOAL}
					icon={<LearningObjectiveIcon />}
				/>
			}
		>
			<div>
				<div className={styles.header}>
					<TextField
						placeholder={lang.ADD_GOAL_PLACEHOLDER}
						className={styles.learningObjectiveTextField}
						id={learningObjective.id}
						onChange={handleChangeLearningObjectiveText}
						value={state?.tekst || ''}
						type='textarea'
						autoComplete='off'
					/>
				</div>
				<SubObjectives
					subObjectives={learningObjective.tegnPaaLaering}
					changeSubObjectives={handleChangeSubObjectives}
				/>
				{allowDelete && (
					<Button
						className={styles.deleteButton}
						variant='ghostPrimary'
						onClick={handleDelete}
					>
						{lang.DELETE_GOAL}
					</Button>
				)}
			</div>
		</Editor>
	);
}
