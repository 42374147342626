import { ALL_GRADES } from '../grades.lang';
import { gradesMap } from '../grades-map';

const GRADE = 'klasse';
const testIfStartsWithGradeNumber = /^(\d+)\./;

export const getGradeText = (grades: number[]) => {
	const [firstGradeId, secondGradeId] = grades;

	if (!grades?.length) return GRADE.toUpperCase();
	if (grades.length === 1) return gradesMap[firstGradeId]?.name;
	if (firstGradeId === secondGradeId) return  gradesMap[firstGradeId]?.name;

	const startGrade = gradesMap[firstGradeId]?.name || '';
	const endGrade = gradesMap[secondGradeId]?.name || '';

	if (
		testIfStartsWithGradeNumber.test(startGrade) ||
		testIfStartsWithGradeNumber.test(endGrade)
	) {
		const fromGrade = startGrade.match(testIfStartsWithGradeNumber)?.[1];
		const toGrade = endGrade.match(testIfStartsWithGradeNumber)?.[1];

		if (fromGrade && toGrade) {
			return `${fromGrade}. - ${toGrade}. ${GRADE.toUpperCase()}`;
		}

		if (fromGrade) {
			return `${fromGrade}. ${GRADE.toUpperCase()} - ${endGrade}`;
		} else {
			return `${startGrade} - ${toGrade}. ${GRADE.toUpperCase()}`;
		}
	}

	return ALL_GRADES.toUpperCase();
};
