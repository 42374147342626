import React from 'react';
import styles from './authors-view.module.scss';

import { Author } from './components/author/Author';

import { ForfatterDto } from '../../../../api';
import { useClassName } from 'hooks/use-class-name';

export function AuthorsView({
	name,
	authors,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	authors: ForfatterDto[] | undefined;
	setEditMode: (name: string) => void;
	leaveEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const classNames = useClassName(
		styles.authorsView,
		userCanEdit && styles.editable,
	);

	const handleSetEditMode = () => {
		if (userCanEdit) {
			setEditMode(name);
		}
	};

	return (
		<div className={classNames} onClick={handleSetEditMode}>
			{authors?.map((author, key) => (
				<Author email={author.email} name={author.navn} key={key} />
			))}
		</div>
	);
}
