import React from 'react';
import styles from './course-library.module.scss';
import { IForloebListItemDto, IForloebSearchDto } from 'api';
import { CourseCard } from 'components/course-card/CourseCard';
import { CourseCardGrid } from './loading-slides/course-card-grid';
import { CourseLibraryFiltering } from './CourseLibraryFiltering';
import { EditControlsType } from 'modules/course/course.types';
import { GlobalActionTypes } from 'modules/course/state/actions/global/types';
import { CourseLibraryActionTypes } from 'modules/course/state/actions/course-library/types';
import { Button } from 'components/buttons/button/Button';

interface ICourseLibrary {
	courseLibrary: IForloebSearchDto;
	actions: GlobalActionTypes & CourseLibraryActionTypes;
	editControls: EditControlsType;
	courses?: IForloebListItemDto[];
	isLoading?: boolean;
	isFavoritesPage?: boolean;
	showDraftFilter?: boolean;
	showMore: boolean;
	canFavorite?: boolean;
	onShowMore: () => void;
}

export function CourseLibrary({
	courseLibrary,
	actions,
	editControls,
	courses,
	isLoading,
	showDraftFilter,
	showMore,
	onShowMore,
	canFavorite,
	isFavoritesPage,
}: ICourseLibrary) {
	if (!courses?.length && isLoading) {
		return (
			<>
				<CourseLibraryFiltering
					courseLibrary={courseLibrary}
					actions={actions}
					editControls={editControls}
					showDraftFilter={showDraftFilter}
				/>
				<div className={styles.cards}>
					<CourseCardGrid />
				</div>
			</>
		);
	}

	const text = isFavoritesPage
		? 'Listen af favoritter er tom.'
		: 'Ingen forløb her. Måske mangler du at oprette et?';

	return (
		<>
			<CourseLibraryFiltering
				courseLibrary={courseLibrary}
				actions={actions}
				editControls={editControls}
				showDraftFilter={showDraftFilter}
			/>
			<div className={styles.cards}>
				{courses?.length ? (
					courses?.map((course) => (
						<CourseCard
							canFavorite={canFavorite}
							key={course.id}
							course={course}
						/>
					))
				) : (
					<div className={styles.cards}>{text}</div>
				)}
				{isLoading && <CourseCardGrid />}
			</div>
			{showMore && (
				<Button
					variant='ghostPrimary'
					onClick={onShowMore}
					disabled={isLoading}
					className={styles.showMoreButton}
				>
					Indlæs flere forløb
				</Button>
			)}
		</>
	);
}
