import React, { useState } from 'react';
import styles from './create-page.module.scss';
import { TextField } from '../../../../../../components/text-field/TextField';
import { SaveType } from 'modules/course/course.types';
import * as lang from './create-page.lang';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.createPage}>{children}</div>;
};

export function CreatePage({
	save,
	chapterId,
	createNewPage,
	userCanEdit,
}: {
	save: SaveType;
	chapterId: string;
	createNewPage: (title: string, chapterId: string) => void;
	userCanEdit?: boolean;
}) {
	const initialPageTitle = '';
	const pageTitleId = 'create-new-page-input';
	const [createMode, setCreateMode] = useState(false);
	const [pageTitle, setPageTitle] = useState(initialPageTitle);

	if (!userCanEdit) {
		return null;
	}

	const handleChangePageTitle = (change: { [name: string]: string }) => {
		setPageTitle(change[pageTitleId]);
	};

	const handleChangeCreateMode = () => {
		setCreateMode(!createMode);
	};

	const handleCreatePage = (
		event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>,
	) => {
		event.preventDefault();

		if (pageTitle.length) {
			createNewPage(pageTitle, chapterId);
			save(pageTitleId);
		}

		handleChangeCreateMode();
		setPageTitle(initialPageTitle);
	};

	if (!createMode) {
		return (
			<Wrapper>
				<h4
					className={styles.beginAddPage}
					onClick={handleChangeCreateMode}
				>
					{lang.ADD_PAGE}
				</h4>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<form autoComplete='off' onSubmit={handleCreatePage}>
				<TextField
					id='create-new-page-input'
					className={styles.createNewPageText}
					placeholder={lang.ADD_PAGE_PLACEHOLDER}
					autoFocus
					onChange={handleChangePageTitle}
					onBlur={handleCreatePage}
					value={pageTitle}
				/>
			</form>
		</Wrapper>
	);
}
