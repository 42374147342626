import React, { useState } from 'react';
import styles from './login.module.scss';
import loginArt from './images/login.svg';
import { Button } from '../../components/buttons/button/Button';
import { TextField } from '../../components/text-field/TextField';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/';
import { AccountClient, IConfig, ILoginModel, LoginModel } from 'api';
import { useQuery } from '../../hooks/useQuery';
import * as lang from './login.lang';
import { Checkbox } from 'components/checkbox/Checkbox';

export function Login() {
	const query = useQuery();
	const accountClient = new AccountClient(new IConfig());

	const [state, setState] = useState<
		ILoginModel & { _errors?: Record<string, string[]> }
	>({
		email: '',
		password: '',
		rememberMe: false,
	});

	const handleChangeText = (change: Record<string, string>) =>
		setState({ ...state, ...change });

	const handleLogin = (
		event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>,
	) => {
		event.preventDefault();
		setState((state) => ({ ...state, _errors: undefined }));

		const loginModel: ILoginModel = {
			email: state.email,
			password: state.password,
			rememberMe: state.rememberMe,
		};

		const returnUrl = query.get('ReturnUrl') || '/about';
		const pathname = routes.home.setPath(returnUrl);

		accountClient
			.login(new LoginModel(loginModel))
			.then(() => (window.location.href = pathname))
			.catch((e) =>
				setState((state) => ({
					...state,
					_errors: e?.errors || { Password: [lang.WRONG_PASSWORD] },
				})),
			);
	};

	const handleToggleRememberMe = () =>
		setState((state) => ({ ...state, rememberMe: !state.rememberMe }));

	return (
		<>
			<div className='col-md-5'>
				<img className='img-fluid' alt='login art' src={loginArt} />
			</div>
			<div className='col-md-5 offset-md-2'>
				<div className={styles.login}>
					<h1 className={styles.title}>{lang.TITLE}</h1>
					<form autoComplete='on' onSubmit={handleLogin}>
						<TextField
							id='email'
							className={styles.email}
							onChange={handleChangeText}
							placeholder={lang.EMAIL}
							type='text'
							labelText={lang.EMAIL}
							errorMessage={state._errors?.Email?.[0]}
						/>
						<TextField
							id='password'
							className={styles.password}
							onChange={handleChangeText}
							placeholder={lang.PASSWORD}
							type='password'
							labelText={lang.PASSWORD}
							errorMessage={state._errors?.Password?.[0]}
							autoComplete='on'
						/>
						<div>
							<Checkbox
								onChange={handleToggleRememberMe}
								checked={state.rememberMe}
							>
								{lang.REMEMBER_ME}
							</Checkbox>
						</div>
						<Button
							variant='primary'
							type='submit'
							onClick={handleLogin}
						>
							{lang.LOG_IN}
						</Button>
					</form>
					<div className={styles.links}>
						<Link
							className='text-muted'
							to={routes.recoverAccount.path}
							replace
						>
							{lang.FORGOT_PASSWORD}
						</Link>
						<Link
							className='text-muted'
							to={routes.register.path}
							replace
						>
							{lang.NEW_USER}
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
