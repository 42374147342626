import React from 'react';

import { HomePage } from '../pages/home';
import { CoursePage } from '../pages/course';
import { AboutSiuPage } from '../pages/about-siu';
import { FavoritesPage } from '../pages/favorites';
import { MyCoursesPage } from '../pages/my-courses';
import { RegisterPage } from '../pages/register';
import { LoginPage } from '../pages/login';
import { TermsOfUsePage } from '../pages/terms-of-use';
import { AccountRecoveryPage } from '../pages/account-recovery';

import { RoutesType } from './types';
import { Page } from '../pages/_page/Page';
import { NotFound } from '../pages/not-found/NotFound';
import { KnowledgeOfGames } from '../modules/knowledge-of-games/knowledge-of-games';

let routes: RoutesType = {
	home: {
		name: 'Forside',
		exact: false,
		path: '/',
		setPath: (args) => `${args}`,
		component: HomePage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	aboutSiu: {
		name: 'Om SIU',
		exact: false,
		path: '/about',
		setPath: () => '',
		component: AboutSiuPage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	myCourses: {
		name: 'Mine forløb',
		exact: false,
		path: '/user/:id/courses',
		setPath: (userId: string) => `/user/${userId}/courses`,
		component: MyCoursesPage,
		auth: true,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	favoriteCourses: {
		name: 'Favoritter',
		exact: false,
		path: '/user/:id/favorites',
		setPath: (userId: string) => `/user/${userId}/favorites`,
		component: FavoritesPage,
		auth: true,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	course: {
		name: 'Forløb',
		exact: false,
		path: '/courses/:id',
		setPath: (courseId: string) => `/courses/${courseId}`,
		component: CoursePage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	register: {
		name: 'Opret ny bruger',
		exact: false,
		path: '/register',
		setPath: () => '',
		component: RegisterPage,
		auth: false,
		pageClass: 'container-fluid',
		backgroundColor: 'var(--color-primary)',
	},
	login: {
		name: 'Log ind',
		exact: false,
		path: '/login',
		setPath: () => '',
		component: LoginPage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	notFound: {
		name: 'Page not found',
		exact: false,
		path: '',
		setPath: () => '',
		component: NotFound,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	termsOfUse: {
		name: 'Terms of use',
		exact: false,
		path: '/terms-of-use',
		setPath: () => '',
		component: TermsOfUsePage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	recoverAccount: {
		name: 'Recover account',
		exact: false,
		path: '/recover-account',
		setPath: () => '',
		component: AccountRecoveryPage,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
	knowledgeOfGames: {
		name: 'Viden om spil',
		exact: false,
		path: '/knowledgeOfGames',
		setPath: () => '',
		component: KnowledgeOfGames,
		auth: false,
		pageClass: 'container',
		backgroundColor: 'var(--color-background-light)',
	},
};

for (const [key, value] of Object.entries(routes)) {
	routes[key].component = React.createElement(Page, {
		pageClass: value.pageClass,
		backgroundColor: value.backgroundColor,
		// @ts-ignore
		children: React.createElement(value.component),
	});
}

export { routes };
