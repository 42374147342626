import React from 'react';
import styles from './page-title.module.scss';
import { Title } from '../../../title/Title';
import { EditControlsType, SaveType } from '../../../../course.types';
import { Ruler } from 'modules/course/components/ruler/Ruler';
import * as lang from '../create-page/create-page.lang';

type PageTitleProps = {
	name: string;
	text: string;
	editControls: EditControlsType;
	changeTitle: (title: string) => void;
	save: SaveType;
	deleteOptions: { onDelete: () => void; text: string; confirmText: string; }
};

export function PageTitle(props: PageTitleProps) {
	return (
		<div className={styles.pageTitle}>
			<Ruler />
			<Title
				{...props}
				headingType='h3'
				placeholder={lang.ADD_PAGE_PLACEHOLDER}
			/>
			<Ruler />
		</div>
	);
}
