import React from 'react';
import styles from './text-field.module.scss';
import { TextFieldProps } from './text-field.types';
import { useClassName } from 'hooks/use-class-name';

export function TextField(props: TextFieldProps) {
	const { onChange, labelText, errorMessage, ...restProps } = props;

	const handleChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		return onChange({
			[props.id]:
				props.type === 'number'
					? parseInt(event.target.value)
					: event.target.value,
		});
	};

	const className = useClassName(
		styles.textField,
		!!labelText && styles.hasLabelText,
	);

	const wrapperClassName = useClassName(
		styles.wrapper,
		props.className,
		!!errorMessage && styles.hasError,
	);

	const errorMessageClassName = [styles.errorMessage, 'errorMessage'].join(
		' ',
	);

	if (props.type === 'textarea') {
		return (
			<div className={wrapperClassName}>
				<textarea
					{...restProps}
					className={className}
					onChange={handleChange}
				/>
			</div>
		);
	}

	return (
		<div className={wrapperClassName}>
			<input
				{...restProps}
				className={className}
				onChange={handleChange}
				min='0'
			/>
			{!!labelText && <div className={styles.labelText}>{labelText}</div>}
			{errorMessage && (
				<div className={errorMessageClassName}>{errorMessage}</div>
			)}
		</div>
	);
}

TextField.defaultProps = {
	type: 'text',
};
