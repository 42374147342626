import { FileIcon } from './FileIcon';
import { ImageIcon } from './ImageIcon';
import { LinkIcon } from './LinkIcon';
import { IIcon } from './types';

// Jeg er doven, i kan selv tilføje resten.
export function Icon({ icon, size }: IIcon) {
	if (icon === 'billede') return <ImageIcon size={size} />;
	if (icon === 'fil') return <FileIcon size={size} />;
	if (icon === 'link') return <LinkIcon size={size} />;

	return null;
}
