import React from 'react';
import styles from './text-view.module.scss';

export function TextView({
	name,
	text,
	setEditMode,
}: {
	name: string;
	text: string;
	setEditMode: (name: string) => void;
}) {
	const editText = () => {
		setEditMode(name);
	};

	return (
		<div
			onClick={editText}
			className={styles.textView}
			dangerouslySetInnerHTML={{ __html: text }}
		/>
	);
}
