import React from 'react';
import styles from './duration-view.module.scss';
import { ADD_LESSONS_OF_45_MINUTES } from './duration.lang';
import { useClassName } from 'hooks/use-class-name';

export function DurationView({
	name,
	duration = 0, // duration = number of lessons
	setEditMode,
	userCanEdit,
}: {
	name: string;
	duration?: number;
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const editDuration = () => {
		setEditMode(name);
	};

	const lessonsToTime = () => {
		const lessonDurationInMinutes = 45;
		const totalMinutes = duration * lessonDurationInMinutes;
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;

		return { hours, minutes };
	};

	const contentClassNames = useClassName(
		styles.text,
		userCanEdit && styles.editable,
	);

	const makeText = (duration?: number) => {
		if (!duration) {
			return (
				<div className={styles.noText}>{ADD_LESSONS_OF_45_MINUTES}</div>
			);
		}

		const { hours, minutes } = lessonsToTime();

		return (
			<div className={styles.text}>
				Ca. {duration} lektioner ({hours} t.{' '}
				{minutes ? `og ${minutes} min.)` : ')'}
			</div>
		);
	};


	return (
		<div onClick={editDuration} className={styles.durationView}>
			<div className={contentClassNames}>{makeText(duration)}</div>
		</div>
	);
}
