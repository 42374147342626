import React from 'react';

export function LearningObjectiveIcon() {
	return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='32px'
				height='32px'
				viewBox='0 0 32 32'
				version='1.1'
			>
				<title>mål</title>
				<g
					id='Symbols'
					stroke='none'
					strokeWidth='1'
					fill='red'
					fillRule='evenodd'
				>
					<g id='icon/goal' fill='var(--color-primary)'>
						<path
							d='M15.9804481,-3.01980663e-14 C24.7918534,-3.01980663e-14 31.9608961,7.16904277 31.9608961,15.9804481 C31.9608961,24.7918534 24.7918534,31.9608961 15.9804481,31.9608961 C7.16904277,31.9608961 5.32907052e-15,24.7918534 5.32907052e-15,15.9804481 C5.32907052e-15,7.16904277 7.16904277,-3.01980663e-14 15.9804481,-3.01980663e-14 Z M15.9804481,1.59674134 C8.04887984,1.59674134 1.59674134,8.04887984 1.59674134,15.9804481 C1.59674134,23.9120163 8.04887984,30.3641548 15.9804481,30.3641548 C23.9120163,30.3641548 30.3641548,23.9120163 30.3641548,15.9804481 C30.3641548,8.04887984 23.9120163,1.59674134 15.9804481,1.59674134 Z M16.1259843,6.17322835 C21.6227396,6.17322835 26.0787402,10.6292289 26.0787402,16.1259843 C26.0787402,21.6227396 21.6227396,26.0787402 16.1259843,26.0787402 C10.6292289,26.0787402 6.17322835,21.6227396 6.17322835,16.1259843 C6.17322835,10.6292289 10.6292289,6.17322835 16.1259843,6.17322835 Z M16.1259843,7.93700787 C11.6033375,7.93700787 7.93700787,11.6033375 7.93700787,16.1259843 C7.93700787,20.648631 11.6033375,24.3149606 16.1259843,24.3149606 C20.648631,24.3149606 24.3149606,20.648631 24.3149606,16.1259843 C24.3149606,11.6033375 20.648631,7.93700787 16.1259843,7.93700787 Z M16.1259843,11.9685039 C18.4220972,11.9685039 20.2834646,13.8298713 20.2834646,16.1259843 C20.2834646,18.4220972 18.4220972,20.2834646 16.1259843,20.2834646 C13.8298713,20.2834646 11.9685039,18.4220972 11.9685039,16.1259843 C11.9685039,13.8298713 13.8298713,11.9685039 16.1259843,11.9685039 Z M16.1259843,13.7322835 C14.8039798,13.7322835 13.7322835,14.8039798 13.7322835,16.1259843 C13.7322835,17.4479887 14.8039798,18.519685 16.1259843,18.519685 C17.4479887,18.519685 18.519685,17.4479887 18.519685,16.1259843 C18.519685,14.8039798 17.4479887,13.7322835 16.1259843,13.7322835 Z'
						></path>
					</g>
				</g>
			</svg>
	);
}
