import React, { ChangeEvent, useState, useEffect, useMemo, useRef } from 'react';
import styles from './subjects-edit.module.scss';
import { FagDto } from '../../../../api';
import { Editor } from '../../../../components/editor/Editor';
import { Checkbox } from '../../../../components/checkbox/Checkbox';
import { fetchSubjects } from '../../api/fetch-subjects';
import { SkeletonSubjectsList } from './loading-slides/subjects-list';
import { SaveType } from 'modules/course/course.types';
import { useClickOutside } from 'hooks/useClickOutside';

export function SubjectsEdit({
	name,
	selectedSubjects = [],
	leaveEditMode,
	save,
	changeSubjects,
}: {
	name: string;
	selectedSubjects: FagDto[];
	leaveEditMode: (name: string) => void;
	changeSubjects: (subjects: FagDto[]) => void;
	save: SaveType;
}) {
	const initialState = useMemo(() => selectedSubjects, []);
	const [isLoading, setIsLoading] = useState(true);
	const [listOfSubjects, setListOfSubjects] = useState<FagDto[]>([]);

	useEffect(() => {
		fetchSubjects()
			.then(setListOfSubjects)
			.then(() => setIsLoading(false));
	}, []);

	const isSubjectSelected = (subjectId?: string) => {
		return !!selectedSubjects.find((item) => item.id === subjectId);
	};

	const handleChangeSubjects = (event: ChangeEvent<HTMLInputElement>) => {
		const id = event.target.value;
		const subject = listOfSubjects.find((item) => item.id === id);

		let subjects = selectedSubjects;

		if (isSubjectSelected(id)) {
			subjects = subjects.filter((item) => item.id !== id);

			return changeSubjects(subjects);
		}

		if (subject) {
			subjects = [...subjects, subject];
		}

		changeSubjects(subjects);
	};

	const handleCancel = () => {
		leaveEditMode(name);
		changeSubjects(initialState);
	};

	const handleSave = () => {
		save(name);
		leaveEditMode(name);
	};

	const editorRef = useRef(null);
	useClickOutside(editorRef, handleSave);

	if (isLoading) {
		return (
			<div ref={editorRef} className={styles.subjectsEdit}>
				<Editor onCancel={handleCancel} onSave={() => {}}>
					<SkeletonSubjectsList />
				</Editor>
			</div>
		);
	}

	return (
		<div ref={editorRef} className={styles.subjectsEdit}>
			<Editor onCancel={handleCancel} onSave={handleSave}>
				<div className={styles.subjectsEditContent}>
					{listOfSubjects.map((subject, key) => (
						<Checkbox
							key={key}
							isDisplayBlock
							checked={isSubjectSelected(subject.id)}
							value={subject.id}
							onChange={handleChangeSubjects}
						>
							{subject?.navn?.toUpperCase()}
						</Checkbox>
					))}
				</div>
			</Editor>
		</div>
	);
}
