import React from 'react';
import styles from './navigation.module.scss';
import { NavigationMenuItemProps } from './navigation.types';
import { useClassName } from 'hooks/use-class-name';

export function NavigationMenuItem({
	id,
	name,
	isSelected,
	onClick,
}: NavigationMenuItemProps) {
	const handleClick = () => onClick(id);

	const classNames = useClassName(
		styles.navigationMenuItem,
		isSelected && styles.selected,
	);

	return (
		<button onClick={handleClick} className={classNames}>
			{name}
		</button>
	);
}
