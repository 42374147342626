import React from 'react';

export function CheckboxCheckedIcon({ className }: { className?: string }) {
	return (
		<svg width='32px' height='32px' viewBox='0 0 128 128' version='1.1' className={className}>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/checkboxChecked' fill='#FF5A5D'>
					<path d='M63.9217923,-2.14841268e-14 C99.1674134,-2.14841268e-14 127.843585,28.6761711 127.843585,63.9217923 C127.843585,99.1674134 99.1674134,127.843585 63.9217923,127.843585 C28.6761711,127.843585 -2.14841268e-14,99.1674134 -2.14841268e-14,63.9217923 C-2.14841268e-14,28.6761711 28.6761711,-2.14841268e-14 63.9217923,-2.14841268e-14 Z M80.6582485,48.3584521 L56.1531568,72.7331976 L47.2114053,63.7393075 C45.9600815,62.4879837 43.9527495,62.4879837 42.7014257,63.7132383 C41.4501018,64.9384929 41.4501018,66.9718941 42.6753564,68.2232179 L53.8590631,79.4851324 C54.4847251,80.1107943 55.2928717,80.4236253 56.1270876,80.4236253 C56.9352342,80.4236253 57.7433809,80.1107943 58.3690428,79.4851324 L85.1421589,52.8684318 C86.3934827,51.6171079 86.3934827,49.609776 85.1682281,48.3584521 C83.9169043,47.1071283 81.9095723,47.1071283 80.6582485,48.3584521 Z'></path>
				</g>
			</g>
		</svg>
	);
}
