import React, { useState } from 'react';
import styles from './search-authors.module.scss';
import { IConfig } from 'api/AuthorizedApiBase';
import { UserClient, ForfatterDto } from 'api';
import { TextField } from 'components/text-field/TextField';
import { SearchIcon } from 'components/svg/icons/SearchIcon';
import { Author } from '../author/Author';
import { Button } from 'components/buttons/button/Button';

import { LoadingAuthors } from './LoadingAuthors';

const userClient = new UserClient(new IConfig());

export function SearchAuthors({
	addAuthor,
}: {
	addAuthor: (author: ForfatterDto) => void;
}) {
	const [searchResult, setSearchResult] = useState<
		ForfatterDto | undefined
	>();
	const [authorSearch, setAuthorSearch] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSearchAuthors = (event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => {
		event.preventDefault();
		
		setIsLoading(true);
		userClient
			.byEmail(authorSearch)
			.then(response => setSearchResult(response))
			.then(() => setIsLoading(false))
			.catch(() => setIsLoading(true));
	};

	const handleChangeAuthorSearch = (change: { [name: string]: string }) => {
		const query = change['author-search'];
		setAuthorSearch(query);
	};

	const handleAddAuthor = () => {
		if (searchResult) {
			addAuthor(searchResult);
		}
	};

	return (
		<>
			<form
				className={styles.searchAuthors}
				onSubmit={handleSearchAuthors}
				autoComplete='off'
			>
				<TextField
					id='author-search'
					className={styles.searchAuthorsField}
					placeholder='Forfatterens fulde email...'
					onChange={handleChangeAuthorSearch}
					value={authorSearch}
				/>
				<button
					type='button'
					className={styles.searchButton}
					onClick={handleSearchAuthors}
				>
					<SearchIcon />
				</button>
			</form>
			{isLoading && <LoadingAuthors />}
			{!isLoading && searchResult && (
				<div className={styles.searchResult}>
					{
						<Author
							className={styles.author}
							name={searchResult.navn}
							email={searchResult.email}
						/>
					}{' '}
					<Button
						className={styles.addAuthorButton}
						variant='inline'
						onClick={handleAddAuthor}
					>
						Tilføj
					</Button>
				</div>
			)}
		</>
	);
}
