export const ELEMENT_ID_MAP = {
	text: 1,
	image: 3,
	file: 4,
	exercise: 7,
	youtube: 5,
	link: 2,
	teachernote: 0,
};

// Don't know why teacher note isn't included.
export const elementOrder = [
	ELEMENT_ID_MAP.text,
	ELEMENT_ID_MAP.image,
	ELEMENT_ID_MAP.file,
	ELEMENT_ID_MAP.exercise,
	ELEMENT_ID_MAP.youtube,
	ELEMENT_ID_MAP.link,
];