import React from 'react';
import desktopLogo from './images/desktop-logo.svg';
import mobileLogo from './images/mobile-logo.svg';
import styles from './site-logo.module.scss';

import { useViewportSize } from '../../hooks/useViewportSize';

export function SiteLogo() {
	const { isDesktop } = useViewportSize();

	const logo = isDesktop
		? desktopLogo
		: mobileLogo;

	return <img className={styles.siteLogo} src={logo} alt='logo' />;
};