import React from 'react';
import styles from './teacher-note.module.scss';
import { ElementDto } from '../../../../../../../api';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { TeacherNoteEdit } from './TeacherNoteEdit';
import { TeacherNoteView } from './TeacherNoteView';
import { ActionTypes } from 'modules/course/state/actions/course/types';
import { useClassName } from 'hooks/use-class-name';

const Wrapper = ({
	children,
	userCanEdit,
}: {
	children: React.ReactNode;
	userCanEdit?: boolean;
}) => {
	const classNames = useClassName(
		styles.text,
		userCanEdit && styles.editable,
	);

	return <div className={classNames}>{children}</div>;
};

type TextProps = {
	chapterId: string;
	pageId: string;
	element: ElementDto;
	editControls: EditControlsType;
	save: SaveType;
	changeElement: ActionTypes['changeElement'];
	deleteElement: ActionTypes['deleteElement'];
};

export function TeacherNote({
	chapterId,
	pageId,
	element,
	editControls,
	save,
	changeElement,
	deleteElement,
}: TextProps) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(
		element.id,
	);

	const handleChangeElement = (content: string) => {
		changeElement(chapterId, pageId, element.id, content);
	};

	const handleDeleteElement = () => {
		deleteElement(chapterId, pageId, element.id);
		save(element.id);
	};

	if (editMode) {
		return (
			<Wrapper>
				<TeacherNoteEdit
					name={element.id}
					text={element.indhold}
					changeElement={handleChangeElement}
					deleteElement={handleDeleteElement}
					element={element}
					leaveEditMode={leaveEditMode}
					save={save}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper userCanEdit={userCanEdit}>
			<TeacherNoteView
				setEditMode={setEditMode}
				text={element.indhold}
				name={element.id}
			/>
		</Wrapper>
	);
}
