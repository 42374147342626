import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { youtubeConfig } from '../../youtube-config';
import styles from './loading-thumbnail-list.module.scss';

export function LoadingThumbnailList() {
	const thumbnailCount = youtubeConfig.maxResults;
	const thumbnailWidth = 320;
	const thumbnailHeight = 180;
	const textHeight = 24;

	const thumbnails: React.ReactNode[] = Array.from(Array(thumbnailCount)).map(
		(item, key) => {
			return (
				<div key={key} className={styles.thumbnail}>
					<Skeleton width={thumbnailWidth} height={thumbnailHeight} />
					<Skeleton width={thumbnailWidth} height={textHeight} />
				</div>
			);
		},
	);

	return <div className={styles.thumbnailList}>{thumbnails}</div>;
}
