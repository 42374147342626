import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';

import styles from './tiny-editor.module.scss';
import { Editor as TinyMCEEditor } from 'tinymce';

// Issued by free registered account at tiny.cloud
const apiKey = '61qv1i9nb249xdx6ipf1kn121wgwqdma28jarr1rkr6ji0j3';

export interface ConfigType {
	auto_focus?: true | string;
	branding?: boolean;
	default_link_target?: string;
	height?: number;
	language?: 'da';
	menubar?: boolean | string;
	placeholder?: string;
	plugins?: string;
	toolbar?: boolean | string;
	link_default_protocol?: string;
}

const defaultConfig: ConfigType = {
	auto_focus: true,
	branding: true,
	default_link_target: '_blank',
	height: 500,
	language: 'da',
	menubar: false,
	placeholder: 'Tilføj tekst...',
	plugins: 'link autolink lists',
	toolbar:
		'undo redo | bold italic underline | h1 h2 h3 | alignleft aligncenter alignright alignjustify |  bullist numlist | indent outdent | link | subscript superscript removeformat',
	link_default_protocol: 'https',
};

// Formatting: https://www.tiny.cloud/docs/configure/content-formatting/

interface TinyEditorProps {
	onModelChange: (a: string, editor: TinyMCEEditor) => void;
	model: string;
	config: ConfigType;
}

export function TinyEditor({
	onModelChange,
	model,
	config = defaultConfig,
}: TinyEditorProps) {
	useEffect(() => {
		const handleClick = (e: MouseEvent) => e.stopImmediatePropagation();

		const handleTinyMCEDialog = () => {
			const tinyMCEDialog =
				document.body.getElementsByClassName('tox-tinymce-aux');

			if (tinyMCEDialog.length) {
				tinyMCEDialog[0].addEventListener('mousedown', handleClick);
			}
		};

		document.addEventListener('focusin', handleTinyMCEDialog);

		return () => {
			document.removeEventListener('focusin', handleTinyMCEDialog);
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const editor = (
		<Editor
			init={{ ...defaultConfig, ...config }}
			apiKey={apiKey}
			onEditorChange={onModelChange}
			value={model}
		/>
	);

	return <div className={styles.tinyEditor}>{editor}</div>;
}
