import React from 'react';
import styles from './navigation.module.scss';

import { NavigationMenuProps } from './navigation.types';
import { NavigationMenuItem } from './NavigationMenuItem';

import { useHistory } from 'hooks/useHistory';
import { routes } from 'routes';
import { KapitelDto } from 'api';
import { useQuery } from 'hooks/useQuery';

export function NavigationMenu({
	courseId,
	chapters = [],
	className,
	children = [],
}: NavigationMenuProps) {
	const history = useHistory();
	const query = useQuery();
	const selectedChapterId = query.get('chapter');

	const navItemsList = [
		{
			id: 'Præsentation',
			name: 'Præsentation',
		},
		...chapters.map((kapitel: KapitelDto) => ({
			id: kapitel.id,
			name: kapitel.overskrift,
		})),
	];

	const isSelected = (id: string) => {
		if (id === selectedChapterId) {
			return true;
		}

		if (!selectedChapterId && id === navItemsList[0].id) {
			return true;	
		}

		return false;
	};

	const handleSelectItem = (id: string) => {
		if (id === navItemsList[0].id) {
			return history.push({ pathname: routes.course.setPath(courseId) });
		}

		history.push({
			pathname: routes.course.setPath(courseId),
			search: `?chapter=${id}`,
		});
	};

	const navItems = navItemsList.map((navItem) => (
		<NavigationMenuItem
			key={navItem.id}
			isSelected={isSelected(navItem.id)}
			onClick={handleSelectItem}
			{...navItem}
		/>
	));

	const renderNavItems = () => {
		if (!children.length) {
			return navItems;
		}

		return [
			...navItems,
			children.map((child, key) => <div key={key}>{child}</div>),
		];
	};

	return <div className={`${styles.navigationMenu} ${className}`}>{renderNavItems()}</div>;
}
