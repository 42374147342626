import React from 'react';
import styles from './register.module.scss';
import { Register } from '../../modules/register/Register';

export function RegisterPage({ className }: { className: string }) {
	const classNames = [styles.registerPage, className].join(' ');

	return (
		<div className={classNames}>
			<div className='container'>
				<div className='row'>
					<Register />
				</div>
			</div>
		</div>
	);
}
