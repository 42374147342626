/* eslint-disable no-nested-ternary */
import {
	useCallback, useEffect, useState,
} from 'react';


export const clamp = (value: number, min: number, max: number) => {
	return Math.max(Math.min(value, max), min);
};

export const swap = (list: any[], indexA: number, indexB: number) => {
	const newList = list.slice(0);
	newList[indexB] = list[indexA];
	newList[indexA] = list[indexB];

	return newList;
};

export const between = (number: number, min: number, max: number, inclusive = true) => {
	if (inclusive) {
		return number >= min && number <= max;
	}

	return number > min && number < max;
};


const MIN_SIZE_DESKTOP = 992;

export const MIN_SIZE_TABLET = 768;
const MAX_SIZE_TABLET = 991;

const MAX_SIZE_MOBILE = 576;

export function useViewportSize() {
	const isClient = typeof window === 'object';

	const getSize = useCallback(() => {
		return {
			width: isClient ? window.innerWidth : 99999,
			height: isClient ? window.innerHeight : 99999,
			isDesktop: isClient
				? window.innerWidth >= MIN_SIZE_DESKTOP
					? true
					: false
				: true,
			isTablet: isClient && between(window.innerWidth, MIN_SIZE_TABLET, MAX_SIZE_TABLET),
			isMobile: isClient && window.innerWidth < MAX_SIZE_MOBILE,
		};
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return;
		}

		const handleResize = () => {
			setWindowSize(getSize());
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [getSize, isClient]);

	return windowSize;
}

