import React from 'react';
import styles from './exercise-view.module.scss';
import { ExerciseIcon } from 'components/svg/icons/ExerciseIcon';
import { EXERCISE } from './exercise.lang';

export function ExerciseView({
	name,
	text,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	text: string;
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const editText = () => {
		setEditMode(name);
	};

	return (
		<div className={styles.exerciseView} onClick={editText}>
			<div className={styles.exerciseHeader}>
				<h4 className={styles.title}><ExerciseIcon /> {EXERCISE}</h4>
			</div>
			<div
				className={styles.exerciseText}
				dangerouslySetInnerHTML={{ __html: text }}
			/>
		</div>
	);
}
