import React from 'react';
import styles from './image.module.scss';

import { ImageView } from './ImageView';
import { ImageEdit } from './ImageEdit';
import { EditControlsType, SaveType } from '../../course.types';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.image}>{children}</div>
);

export function Image({
	name,
	image,
	save,
	editControls,
	changeImage,
	isCourseImage,
}: {
	name: string;
	editControls: EditControlsType;
	image?: string;
	save: SaveType;
	changeImage: (image: string) => void;
	isCourseImage?: boolean;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } =
		editControls(name);

	if (!userCanEdit && !image) {
		return null;
	}

	if (editMode) {
		return (
			<Wrapper>
				<ImageEdit
					save={save}
					leaveEditMode={leaveEditMode}
					changeElement={changeImage}
					name={name}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<ImageView
				name={name}
				image={image}
				userCanEdit={userCanEdit}
				setEditMode={setEditMode}
				isCourseImage
			/>
		</Wrapper>
	);
}
