import React from 'react';

export function HeartFilledIcon({ className }: { className?: string }) {
	return (
		<svg
			width='32px'
			height='32px'
			viewBox='0 0 32 32'
			version='1.1'
			className={className}
		>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/checkboxChecked' fill='#FF5A5D'>
					<path d='M23.168,2.25 C28.046,2.25 32,6.20571058 32,11.0858209 C32,13.7889898 30.786,16.2100368 28.874,17.8307376 L15.962,30 L2.976,17.7006814 C1.15,16.0819814 0,13.7189596 0,11.0858209 C0,6.20571058 3.954,2.25 8.832,2.25 C11.782,2.25 14.396,3.69662557 16,5.92158771 C17.604,3.69662557 20.216,2.25 23.168,2.25 Z'></path>
				</g>
			</g>
		</svg>
	);
}
