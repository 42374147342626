import React, { useState } from 'react';

import { Editor } from '../../../../../../../components/editor/Editor';
import { TinyEditor } from '../../../../../../../components/tiny-editor/TinyEditor';
import { EditorTitle } from '../../../../../../../components/editor/components/editor-title/EditorTitle';
import { ExerciseIcon } from '../../../../../../../components/svg/icons/ExerciseIcon';

import { SaveType } from 'modules/course/course.types';
import * as lang from './exercise.lang';
import { Button } from 'components/buttons/button/Button';
import { ElementDto } from 'api';

import styles from './exercise-edit.module.scss';

const editorConfig = {
	placeholder: lang.PLACEHOLDER,
};

type ExerciseEditProps = {
	name: string;
	text?: string;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement?: () => void;
	element: ElementDto;
	save: SaveType;
};

export function ExerciseEdit({
	name,
	text,
	leaveEditMode,
	changeElement,
	deleteElement,
	element,
	save,
}: ExerciseEditProps) {
	const [state, setState] = useState(text);

	const handleChangeText = (change: string) => {
		setState(change);
	};

	const handleSaveText = () => {
		if (state) {
			changeElement(state);
		}
		save(name);
		leaveEditMode(name);
	};

	const handleDeleteText = () => {
		deleteElement?.();
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	return (
		<Editor
			onCancel={handleLeaveEditMode}
			onSave={handleSaveText}
			title={
				<EditorTitle title={lang.EXERCISE} icon={<ExerciseIcon />} />
			}
		>
			<TinyEditor
				config={editorConfig}
				model={state}
				onModelChange={handleChangeText}
			/>
			{element && (
				<Button
					onClick={handleDeleteText}
					variant='danger'
					className={styles.deleteButton}
				>
					{lang.DELETE_EXERCISE}
				</Button>
			)}
		</Editor>
	);
}
