import {
	AccountClient,
	IConfig,
	IForgotPasswordModel,
	ForgotPasswordModel,
    IResetPasswordModel,
    ResetPasswordModel,
} from 'api';

import { Button } from 'components/buttons/button/Button';
import { TextField } from 'components/text-field/TextField';
import { useQuery } from 'hooks/useQuery';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styles from './account-recovery.module.scss';

export const ResetPassword = ({ code }: { code: string }) => {
    const history = useHistory();
    const accountClient = new AccountClient(new IConfig());

    const [email, setEmail] = useState('');
    const handleChangeEmail = (change: { [name: string]: string }) => setEmail(change['email']);
    
    const [password, setPassword] = useState('');
    const handleChangePassword = (change: { [name: string]: string }) => setPassword(change['password']);
    
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleChangeConfirmPassword = (change: { [name: string]: string }) => setConfirmPassword(change['confirm-password']);
	
    const isValidForm = !!email && !!password && !!confirmPassword && confirmPassword === password;
	const handleResetPassword = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (!isValidForm) {
            return;
        }
    
        const resetpasswordModel: IResetPasswordModel = {
            email,
            password,
            confirmPassword,
            code,
        };
    
        accountClient
            .resetPassword(new ResetPasswordModel(resetpasswordModel))
            .then(() => history.push({ pathname: '/authentication/login' }));
    };

	return (
        <div className="row">
            <div className="col-md-6">
                <h1>Opret et nyt kodeord</h1>
            </div>
            <div className='col-md-5 offset-md-1'>
                <form autoComplete='off' onSubmit={handleResetPassword}>   
                    <TextField
                        id='email'
                        className={styles.textField}
                        placeholder="E-mail"
                        onChange={handleChangeEmail}
                    />
                    <TextField
                        id='password'
                        className={styles.textField}
                        placeholder='Nyt kodeord'
                        type='password'
                        onChange={handleChangePassword}
                    />
                    <TextField
                        id='confirm-password'
                        className={styles.textField}
                        type='password'
                        placeholder='Gentag nyt kodeord'
                        onChange={handleChangeConfirmPassword}
                    />
                    <div className={styles.buttons}>
                        <Button
                            variant='ghostPrimary'
                            onClick={handleResetPassword}
                            disabled={!isValidForm}
                        >
                            Opret nyt kodeord
                        </Button>
                    </div>
                </form>
            </div>
        </div>
		
	);
};

export function AccountRecoveryPage({ className }: { className: string }) {
	const query = useQuery();
	const recoveryCode = query.get('code');
	const accountClient = new AccountClient(new IConfig());
	const [email, setEmail] = useState('');
    const isValidEmail = !!email;
	const [sentCode, setSentCode] = useState(false);

	if (recoveryCode) {
		return <ResetPassword code={recoveryCode} />;
	}

    if (sentCode) {
        return (
            <div>
                <p>Der er sendt intruktioner til din email. Tjek din inbox.</p>
            </div>
        );
    }

	const handleRecoverAccount = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if (!isValidEmail) {
            return;
        }

		const forgotPasswordModel: IForgotPasswordModel = {
			email,
			redirectUrl: 'recover-account',
		};

		accountClient
            .forgotPassword(new ForgotPasswordModel(forgotPasswordModel))
            .then(() => setSentCode(true));
	};

    const pageClasses = [styles.accountRecoveryPage, 'row'].join(' ');
	const handleChangeEmail = (change: { [name: string]: string }) => setEmail(change['recover-account']);

	return (
		<div className={pageClasses}>
            <div className='col-md-6'>
                <h1>Glemt kodeord?</h1>
                <p>Få instruktioner til at oprette et nyt kodeord på din e-mail.</p>
            </div>
			<div className='col-md-5 offset-md-1'>
                <form autoComplete='off' onSubmit={handleRecoverAccount}>
                    <TextField
                        id='recover-account'
                        className={styles.textField}
                        placeholder='E-mail'
                        onChange={handleChangeEmail}
                        value={email}
                    />
                    <div className={styles.buttons}>
                        <Button
                            variant='ghostPrimary'
                            onClick={handleRecoverAccount}
                            disabled={!isValidEmail}
                        >
                            Send
                        </Button>
                    </div>
                </form>
            </div>
		</div>
	);
}
