import { useState } from 'react';

interface IUsePoliteLoadingText {
	loadingText: string;
	politeLoadingText: string;
}

export function usePoliteLoadingText({
	loadingText,
	politeLoadingText,
}: IUsePoliteLoadingText) {
	const [_text, _setText] = useState('');

	const loadText = () => {
		const delay = 500;
		let count = 0;
		let text = loadingText;

		return setInterval(() => {
			count++;

			if (count % 12 === 0) {
				text = politeLoadingText;
			}

			if (count % 23 === 0) {
				text = loadingText;
			}

			_setText(text + new Array(count % 5).join('.'));
		}, delay);
	};

	let refreshId: any;
	
	const startBeingPolite = () => {
		refreshId = loadText();
	};

	const stopBeingPolite = () => {
		_setText('');
		clearInterval(refreshId);
	};

	return {
		politeLoadingText: _text,
		startBeingPolite,
		stopBeingPolite,
	};
}
