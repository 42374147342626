import { ElementDto } from 'api';
import { LinkType } from '../types';
import { JSONTryParseWithDefaultValue } from 'utils/json';

export const initialLinkElement: LinkType = {
	data: {
		emne: '',
		beskrivelse: '',
		url: '',
	},
};

export const convertJsonToLinkElement = (element?: ElementDto): LinkType => {
	if (!element) return initialLinkElement;

	return JSONTryParseWithDefaultValue<LinkType, LinkType>(
		element.indhold,
		initialLinkElement,
	);
};
