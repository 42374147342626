import { FagDto } from 'api';
import { CourseLibraryActionType } from './types';

export function courseLibraryActions(
	dispatch: (action: CourseLibraryActionType) => void,
) {
	return {
		setCourseLibrarySubject(subjects?: FagDto[]) {
			dispatch({
				type: 'setCourseLibrarySubject',
				payload: subjects,
			});
		},

		setCourseLibraryGrade(from?: number, to?: number) {
			dispatch({
				type: 'setCourseLibraryGrade',
				payload: { from, to },
			});
		},

		setCourseLibraryCategories(kategorier: string[] | undefined) {
			dispatch({
				type: 'setCourseLibraryCategories',
				payload: kategorier,
			});
		},

		setCourseLibrarySearchTerms({ term }: Record<'term', string>) {
			dispatch({
				type: 'setCourseLibrarySearchTerms',
				payload: term,
			});
		},

		setCourseLibraryDraft(showDraft: boolean) {
			dispatch({
				type: 'setCourseLibraryDraft',
				payload: showDraft,
			});
		},

		setCourseLibraryLastSeenId(lastSeenId?: string) {
			dispatch({
				type: 'setCourseLibraryLastSeenId',
				payload: lastSeenId,
			});
		}
	};
}
