export function JSONTryParse<ReturnType>(value: string): ReturnType | false {
	try {
		return JSON.parse(value);
	} catch (e) {
		return false;
	}
}

export function JSONTryParseWithDefaultValue<ReturnType, DefaultReturnType>(
	value: string,
	defaultReturnValue: DefaultReturnType,
): ReturnType | DefaultReturnType {
	if (!value) return defaultReturnValue;
	try {
		return JSON.parse(value);
	} catch (e) {
		return defaultReturnValue;
	}
}
