import React from 'react';
import styles from './switch-button.module.scss';
import { SwitchButtonProps } from './switch-button.types';
import { Checkmark } from '../../../components/svg/icons/Checkmark';
import { useClassName } from 'hooks/use-class-name';

function useTrackFocus() {
	const [hasFocus, setFocus] = React.useState(false);

	const onBlur = React.useCallback(() => {
		setFocus(false);
	}, []);

	const onFocus = React.useCallback(() => {
		setFocus(true);
	}, []);

	return {
		hasFocus,
		onBlur,
		onFocus,
	};
}

export const SwitchButton = React.memo(function SwitchButton(
	props: SwitchButtonProps,
) {
	const { id, title, isSelected, onToggle, isDisabled } = props;

	const trackFocus = useTrackFocus();

	const labelClassName = useClassName(
		styles.label,
		isSelected && styles.isSelected,
		isDisabled && styles.isDisabled,
		trackFocus.hasFocus && styles.hasFocus,
	);

	const handleChange = () => {
		onToggle(!isSelected);
	};

	const renderToggleCircle = () => {
		if (isSelected) {
			return <Checkmark />;
		}

		return null;
	};

	return (
		<span className={styles.switchButton} title={title}>
			<label htmlFor={id} className={labelClassName}>
				<span className={styles.toggleCircle}>
					{renderToggleCircle()}
				</span>
				<input
					id={id}
					type='checkbox'
					className={styles.checkbox}
					checked={isSelected}
					onChange={handleChange}
					disabled={isDisabled}
					onBlur={trackFocus.onBlur}
					onFocus={trackFocus.onFocus}
				/>
			</label>
		</span>
	);
});
