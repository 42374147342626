import React from 'react';
import styles from './title-edit.module.scss';
import { TextField } from '../../../../components/text-field/TextField';

interface TitleEditProps {
	name: string;
	text: string;
	changeTitle: (title: string) => void;
	placeholder?: string,
}

export function TitleEdit({
	name,
	text,
	changeTitle,
	placeholder,
}: TitleEditProps) {
	const handleChangeTitle = (change: { [name: string]: string }) => {
		changeTitle(change[name]);
	};

	return (
		<TextField
			id={name}
			className={styles.titleEdit}
			value={text}
			autoFocus
			placeholder={placeholder}
			onChange={handleChangeTitle}
		/>
	);
}
