export const getImageUrl = (
	muBaseUrl: string,
	guid: string,
	width: number,
	height: number,
) => {
	return `${muBaseUrl}api/forloebsafvikling/blobprovider/GetThumbnail/${guid}/${width}/${height}/Min`;
};

export const getFileUrl = (guid: string) => {
	return `https://${window.location.host}/api/file/getFile?blobProviderMetadataGuid=${guid}`;
};
