import React, { useState } from 'react';
import styles from './search-bar.module.scss';

import { SEARCH_YOUTUBE } from './search-bar.lang';

import { TextField } from '../../../../../../../../../components/text-field/TextField';
import { SearchIcon } from '../../../../../../../../../components/svg/icons/SearchIcon';
import { Paging } from '../paging/Paging';
import { SearchTokensType } from '../paging/paging.types';

export function Search({
	performSearch,
	disabled,
	children,
	searchTokens,
}: {
	performSearch: (search: string, token?: string) => void;
	disabled?: boolean;
	children: React.ReactNode;
	searchTokens?: SearchTokensType;
}) {
	const textFieldId = 'youtube-search-field';
	const [searchString, setSearchString] = useState('');

	const handleChangeSearchString = (change: { [id: string]: string }) => {
		setSearchString(change[textFieldId]);
	};

	const handlePerformSearch = (
		event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>
	) => {
		event.preventDefault();

		performSearch(searchString);
	};

	const handlePaging = (token?: string) => {
		performSearch(searchString, token);
	};

	return (
		<div>
			<form
				className={styles.search}
				autoComplete='off'
				onSubmit={handlePerformSearch}
			>
				<TextField
					id={textFieldId}
					className={styles.searchTextField}
					onChange={handleChangeSearchString}
					placeholder={SEARCH_YOUTUBE}
					autoComplete='off'
				/>
				<button
					type='button'
					className={styles.searchButton}
					disabled={disabled}
					onClick={handlePerformSearch}
				>
					<SearchIcon />
				</button>
			</form>

			{children}
			<Paging searchTokens={searchTokens} performSearch={handlePaging} />
		</div>
	);
}
