import React, { useEffect, useState, useRef } from 'react';
import styles from './file-upload.module.scss';
import { useBlobProvider } from 'hooks/useBlobProvider';
import { TextField } from 'components/text-field/TextField';
import { Icon } from 'components/svg/icons';
import { getImageUrl, getFileUrl } from 'utils/get-file-url';
import { usePoliteLoadingText } from 'hooks/usePoliteLoadingText';
import { IIconType } from 'components/svg/icons/types';
import { useRecoilValue } from 'recoil';
import { appConfigState } from 'coils/useRecoilAppConfig';

export type FileUploadStateType = {
	_base: {
		blobproviderMetadataGuid?: string;
		filNavn?: string;
		mimeType?: string;
	};
	url?: string;
	beskrivelse?: string;
	emne?: string;
};

/*
 FileUpload is shared between FileEdit and ImageEdit.
 FileEdit currently has another file structure.
*/
export type DocumentUploadFileStateType = {
	data: FileUploadStateType;
};

interface IFileUpload {
	onChange: (props: FileUploadStateType) => void;
	addUrlTextArea?: boolean;
	initialState?: FileUploadStateType;
	placeholder?: string;
	icon?: IIconType;
	accepted: 'images' | 'documents';
	invalidFileErrorMessage: string;
	setIsUploading: (isUploading: boolean) => void;
}

export const emptyState: FileUploadStateType = {
	_base: {
		blobproviderMetadataGuid: '',
		filNavn: '',
		mimeType: '',
	},
	url: '',
	beskrivelse: '',
	emne: '',
};

export const documentEmptyState: DocumentUploadFileStateType = {
	data: emptyState,
};

const APPLICATION = 'application';

export function FileUpload({
	onChange,
	initialState,
	addUrlTextArea,
	placeholder,
	icon,
	accepted,
	invalidFileErrorMessage,
	setIsUploading,
}: IFileUpload) {
	const dropAreaRef = useRef<HTMLDivElement>(null);
	const [state, setState] = useState<FileUploadStateType>(
		initialState || emptyState,
	);
	const appConfig = useRecoilValue(appConfigState);

	const { politeLoadingText, startBeingPolite, stopBeingPolite } =
		usePoliteLoadingText({
			loadingText: 'Uploader',
			politeLoadingText: 'Vent venligst',
		});

	const handleSuccess = async (
		guid: string,
		filNavn: string,
		mimeType: string,
	) => {
		const isImage = mimeType?.indexOf('image') === 0;
		const url = isImage
			? getImageUrl(appConfig.muBaseUrl, guid, 500, 500)
			: getFileUrl(guid);
		stopBeingPolite();
		setState({
			...emptyState,
			_base: {
				blobproviderMetadataGuid: guid,
				filNavn,
				mimeType,
			},
			url,
		});
	};

	const { FileSelector, processing } = useBlobProvider({
		dropAreaRef,
		onSuccess: handleSuccess,
		onStartProcessing: startBeingPolite,
		accepted,
		invalidFileErrorMessage,
		setIsUploading,
	});

	const handleChangeUrl = ({ url }: Record<string, string>) =>
		setState({ ...emptyState, url });

	useEffect(() => {
		onChange(state);
	}, [onChange, state]);

	const textFieldValue = state._base.blobproviderMetadataGuid
		? ''
		: state.url;

	const placeholderText = placeholder || 'Vælg billede';
	const isOfMimeTypeApplication =
		(state._base.mimeType || '').slice(0, APPLICATION.length) ===
		APPLICATION;

	return (
		<>
			<div className={styles.fileUpload}>
				<div
					ref={dropAreaRef}
					className={styles.dropArea}
					style={{
						backgroundImage: `url('${state.url}')`,
					}}
				>
					{isOfMimeTypeApplication && state._base.filNavn ? (
						<div>{state._base.filNavn} er tilføjet</div>
					) : null}
					<FileSelector />
					{!state.url && (
						<div className={styles.dropAreaIcon}>
							<Icon icon={icon} size='32px' />
							<div className={styles.dropAreaText}>
								{processing
									? politeLoadingText
									: placeholderText}
							</div>
						</div>
					)}
				</div>
				{addUrlTextArea && (
					<TextField
						id='url'
						className={styles.titleField}
						onChange={handleChangeUrl}
						placeholder='eller skriv linket til et billede...'
						value={textFieldValue}
						autoComplete='off'
					/>
				)}
			</div>
		</>
	);
}
