import React from 'react';
import styles from './thumbnail-list.module.scss';

import { Thumbnail } from '../thumbnail/Thumbnail';
import { YoutubeResponseItemDto } from '../../../../../../../../../api';
import { NO_RESULTS } from './thumbnail-list.lang';

const NoResults = () => <div className={styles.noResults}>{NO_RESULTS}</div>;

export function ThumbnailList({
	thumbnails,
	selectVideo,
}: {
	thumbnails?: YoutubeResponseItemDto[];
	selectVideo: (thumbnail: YoutubeResponseItemDto) => void;
}) {
	if (!thumbnails) {
		return null;
	}

	if (!thumbnails?.length) {
		return <NoResults />;
	}

	return (
		<div className={styles.thumbnailList}>
			{thumbnails.map(thumbnail => (
				<Thumbnail
					onClick={selectVideo}
					key={thumbnail.id}
					thumbnail={thumbnail}
				/>
			))}
		</div>
	);
}
