import React from 'react';
import styles from './checkbox.module.scss';

import { CheckboxCheckedIcon } from 'components/svg/icons/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from 'components/svg/icons/CheckboxUncheckedIcon';
import { useClassName } from 'hooks/use-class-name';

// modify the input type to make some props required
type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	isDisplayBlock?: boolean;
	description?: string;
};

/**
 * Render a semantically correct and visually appealing Checkbox
 *
 * To make the checkbox fully static, pass down `readOnly={true}`. Use this when only presentation is relevant,
 * or when some wrapping component steals all change events (such as react-select)
 *
 * @param props
 * @constructor
 */
export function Checkbox(props: InputProps) {
	const {
		readOnly = false,
		className = '',
		checked = false,
		isDisplayBlock = false,
		children,
		description,
		disabled,
		title,
		...restProps
	} = props;

	const checkboxClassName = useClassName(
		className,
		styles.wrapper,
		isDisplayBlock ? styles.block : null,
		readOnly ? styles.readonly : null,
		disabled ? styles.disabled : null,
	);

	const checkedMark = useClassName(
		styles.checkmark,
		styles.checkedMark,
		disabled && styles.disabled,
	);

	const uncheckedMark = useClassName(
		styles.checkmark,
		styles.uncheckedMark,
		disabled && styles.disabled,
	);

	const inner = (
		<>
			<input
				readOnly={readOnly}
				tabIndex={readOnly ? -1 : undefined}
				checked={checked}
				disabled={disabled}
				aria-checked={checked}
				{...restProps}
				type='checkbox'
			/>
			<div className={styles.checkmarkContainer}>
				<CheckboxUncheckedIcon className={uncheckedMark} />
				<CheckboxCheckedIcon className={checkedMark} />
			</div>
			<div className={styles.label}>
				{children}
				{description && (
					<div className={styles.description}>{description}</div>
				)}
			</div>
		</>
	);

	if (readOnly) {
		return <div className={checkboxClassName}>{inner}</div>;
	}

	return <label className={checkboxClassName}>{inner}</label>;
}
