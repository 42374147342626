import React from 'react';
import styles from './grades-view.module.scss';
import { Button } from '../../../../components/buttons/button/Button';
import { getGradeText } from './utils/get-grade-text';

export function GradesView({
	name,
	grades,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	grades: number[];
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const handleClick = () => {
		setEditMode(name);
	};

	const buttonVariant = grades?.length ? 'roundedPrimary' : 'ghostRounded';
	const buttonStyle = !userCanEdit ? styles.notEditable : undefined;

	return (
		<div className={styles.gradesView}>
			<Button
				className={buttonStyle}
				onClick={handleClick}
				variant={buttonVariant}
			>
				{getGradeText(grades)}
			</Button>
		</div>
	);
}
