import React from 'react';
import { IIconProps } from './types';

export function LinkIcon({ size }: IIconProps) {
	return (
		<svg width={size} height={size} viewBox='0 0 32 32' version='1.1'>
			<title>link</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/contentbar/link' fill='#4A4A4A'>
					<path d='M27.8164111,3.48768457 L28.326804,3.99807744 C30.252999,5.92427244 30.252999,9.04725159 28.326804,10.9734466 L24.2436611,15.0565895 C22.4108573,16.8893932 19.4945079,16.9782564 17.5566331,15.3231791 L15.3231791,17.5566331 C16.9782564,19.4945079 16.8893932,22.4108573 15.0565895,24.2436611 L10.9734466,28.326804 C9.04725159,30.252999 5.92427244,30.252999 3.99807744,28.326804 L3.48768457,27.8164111 C1.56148957,25.8902161 1.56148957,22.767237 3.48768457,20.841042 L7.57082749,16.7578991 C9.39551024,14.9332163 12.2941578,14.8370538 14.2320384,16.4694117 L16.4694117,14.2320384 C14.8370538,12.2941578 14.9332163,9.39551024 16.7578991,7.57082749 L20.841042,3.48768457 C22.767237,1.56148957 25.8902161,1.56148957 27.8164111,3.48768457 Z M8.59161322,17.7786848 L4.5084703,21.8618277 C3.14603969,23.2242583 3.14603969,25.4331948 4.5084703,26.7956254 L5.01886317,27.3060183 C6.38129378,28.6684489 8.59023025,28.6684489 9.95266086,27.3060183 L14.0358038,23.2228753 C15.3043711,21.954308 15.3917678,19.9518554 14.2979938,18.5821675 L11.1755596,21.7035099 C10.9199721,21.9590974 10.4697295,21.9223052 10.1686535,21.6212292 C9.86757751,21.3201532 9.83078534,20.8699107 10.0863729,20.6143231 L10.0863729,20.6143231 L13.205392,17.495206 C11.8364966,16.4239133 9.85189176,16.5184062 8.59161322,17.7786848 Z M21.8618277,4.5084703 L17.7786848,8.59161322 C16.5184062,9.85189176 16.4239133,11.8364966 17.495206,13.205392 L20.6143231,10.0863729 C20.8699107,9.83078534 21.3201532,9.86757751 21.6212292,10.1686535 C21.9223052,10.4697295 21.9590974,10.9199721 21.7035099,11.1755596 L21.7035099,11.1755596 L18.5821675,14.2979938 C19.9518554,15.3917678 21.954308,15.3043711 23.2228753,14.0358038 L27.3060183,9.95266086 C28.6684489,8.59023025 28.6684489,6.38129378 27.3060183,5.01886317 L26.7956254,4.5084703 C25.4331948,3.14603969 23.2242583,3.14603969 21.8618277,4.5084703 Z'></path>
				</g>
			</g>
		</svg>
	);
}
