import React from 'react';
import aauLogo from './images/aau_logo.png';
import styles from './site-footer.module.scss';

export function SiteFooter() {
	return (
		<div className={styles.siteFooter}>
			<div className="container">
				<div className="row">
					<div className={`col-md-6 ${styles.leftCol}`}>
						<h4>Spil i Undervisningen (SIU)</h4>
						<ul className={styles.list}>
							<li>Center for Anvendt Spilforskning</li>
							<li>Aalborg Universitet</li>
							<li>A C Meyers Vænge 15, 3. sal</li>
							<li>2450 København SV</li>
						</ul>
						<div className={`text-muted mt-5 ${styles.versionNumber}`}>Version: {process.env.REACT_APP_VERSION}</div>
					</div>
					<div className={`col-md-6 ${styles.rightCol}`}>
						<img className={styles.aauLogo} src={aauLogo} alt="AAU LOGO" />
					</div>
				</div>
			</div>
		</div>
	);
};
