import React from 'react';
import styles from './learning-objective.module.scss';

import { LearningObjectiveView } from './LearningObjectiveView';
import { LearningObjectiveEdit } from './LearningObjectiveEdit';

import { EditControlsType, SaveType } from '../../course.types';
import { LaeringsmaalDto } from '../../../../api';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.learningObjective}>{children}</div>
);

export function LearningObjective({
	name,
	learningObjective,
	editControls,
	allowDelete,
	save,
	changeLearningObjective,
	chapterId,
	deleteLearningObjective,
}: {
	name: string;
	editControls: EditControlsType;
	learningObjective: LaeringsmaalDto;
	save: SaveType;
	changeLearningObjective: (
		chapterId: string,
		learningObjective: LaeringsmaalDto,
	) => void;
	chapterId: string;
	deleteLearningObjective: (
		chapterId: string,
		learningObjectiveId: string,
	) => void;
	allowDelete?: boolean,
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (editMode) {
		return (
			<Wrapper>
				<LearningObjectiveEdit
					chapterId={chapterId}
					name={name}
					leaveEditMode={leaveEditMode}
					save={save}
					learningObjective={learningObjective}
					changeLearningObjective={changeLearningObjective}
					deleteLearningObjective={deleteLearningObjective}
					allowDelete={allowDelete}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<LearningObjectiveView
				name={name}
				userCanEdit={userCanEdit}
				setEditMode={setEditMode}
				learningObjective={learningObjective}
			/>
		</Wrapper>
	);
}
