import React, { useRef } from 'react';
import styles from './editor.module.scss';
import { Button } from '../../components/buttons/button/Button';
import { ButtonGroup } from '../../components/buttons/button-group/ButtonGroup';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useViewportSize } from 'hooks/useViewportSize';
import { useClassName } from 'hooks/use-class-name';

export function Editor({
	children,
	onCancel,
	onSave,
	isSaveDisabled,
	title,
}: {
	children: React.ReactElement | React.ReactElement[];
	onCancel: () => void;
	onSave: () => void;
	isSaveDisabled?: boolean;
	title?: React.ReactNode;
}) {
	const popoverRef = useRef<HTMLDivElement>(null);
	const handleSave = () => {
		if (isSaveDisabled) return;

		return onSave();
	};

	useClickOutside(popoverRef, handleSave, true);
	const { isMobile, width } = useViewportSize();
	const classNames = useClassName(
		styles.editor,
		isMobile && styles.mobileWidth,
	);

	return (
		<div className={classNames} ref={popoverRef}>
			<div className={styles.header}>
				<div className={styles.headerActionsLeft}>{title}</div>
				<div className={styles.headerActionsRight}>
					<ButtonGroup
						className={styles.headerActionsGroup}
						alignment='horiziontal'
					>
						<Button variant='text' onClick={onCancel}>
							Fortryd
						</Button>
						<Button
							disabled={isSaveDisabled}
							variant='primary'
							onClick={handleSave}
						>
							Anvend
						</Button>
					</ButtonGroup>
				</div>
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	);
}
