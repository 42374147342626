import { Observable, IObserver } from './core';

class ImageViewService extends Observable<string | null> {
	constructor() {
		super();
		this.state = null;
	}

	openImage(src: string) {
		this.setState(src);
	}

	closeImage() {
		this.setState(null);
	}
}

export type IImageViewObserver = IObserver<string | null>
export const imageViewService = new ImageViewService();
