import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function CourseCardGrid() {
	const rowsCount = 6;
	const rows: React.ReactNode[] = Array.from(Array(rowsCount)).map(
		(_element, key) => {
			return (
				<Skeleton key={`${key}-skeleton-course-card`} height={250} />
			);
		},
	);

	return <>{rows}</>;
}
