import React from 'react';
import styles from './grades.module.scss';
import { GradesView } from './GradesView';
import { GradesEdit } from './GradesEdit';
import { EditControlsType, SaveType } from '../../course.types';
import { uniqueAndDefinedGrades } from './utils/unique-and-defined-grades';

export function Grades({
	name,
	grades: { from, to },
	save,
	onChange,
	editControls,
}: {
	name: string;
	className?: string;
	grades: { from?: number; to?: number };
	save: SaveType;
	onChange: (from?: number, to?: number) => void;
	editControls: EditControlsType;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);
	
	if (!userCanEdit && typeof from !== 'number') {
		return null;
	}

	const grades = [from, to].filter(uniqueAndDefinedGrades) as number[];

	return (
		<div className={styles.grades}>
			<GradesView
				setEditMode={setEditMode}
				name={name}
				grades={grades}
				userCanEdit={userCanEdit}
			/>
			{editMode && (
				<GradesEdit
					leaveEditMode={leaveEditMode}
					name={name}
					grades={grades}
					onChange={onChange}
					save={save}
				/>
			)}
		</div>
	);
}
