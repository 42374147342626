import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';

import styles from './youtube-view.module.scss';
import { ElementDto } from 'api';
import { useClassName } from 'hooks/use-class-name';

type YouTubeViewProps = {
	name: string;
	setEditMode: (name: string) => void;
	element: ElementDto;
	videoControls: any;
	userCanEdit?: boolean;
};

export function YouTubeView({
	name,
	setEditMode,
	element,
	userCanEdit,
	videoControls,
}: YouTubeViewProps) {
	useEffect(() => {
		return () => {
			videoControls().setPlayingVideo(undefined);
		};
	}, []);

	const descriptionClassNames = useClassName(
		styles.videoDescription,
		userCanEdit && styles.editable,
	);

	const editYouTube = () => {
		if (!userCanEdit) {
			return;
		}

		setEditMode(name);
	};

	let videoId: string;
	let videoTitle;
	let videoDescription;

	try {
		const elementContent = JSON.parse(element.indhold);
		videoId = elementContent.data.id.videoId;
		videoTitle = elementContent.data.snippet.title;
		videoDescription = elementContent.data.snippet.description;
	} catch (err) {
		return null;
	}

	const { playing, setPlayingVideo } = videoControls();

	const handleStartPlayingVideo = () =>
		setPlayingVideo(`${element.id}-${videoId}`);

	return (
		<div className={styles.youTubeView}>
			<ReactPlayer
				playing={playing === `${element.id}-${videoId}`}
				onPlay={handleStartPlayingVideo}
				width={'100%'}
				controls
				url={`https://www.youtube.com/watch?v=${videoId}`}
			/>
			<div onClick={editYouTube} className={descriptionClassNames}>
				<h4>{videoTitle}</h4>
				<p>{videoDescription}</p>
			</div>
		</div>
	);
}
