import React from 'react';
import styles from './category.module.scss';
import { CategoryView } from './CategoryView';
import { CategoryEdit } from './CategoryEdit';
import { EditControlsType, SaveType } from '../../course.types';

export function Category({
	name,
	categories = [],
	editControls,
	save,
	onChange,
}: {
	name: string;
	categories?: string[];
	editControls: EditControlsType;
	save: SaveType;
	onChange: (categories: string[]) => void;
}) {
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(name);

	if (!userCanEdit && !categories.length) {
		return null;
	}

	return (
		<div className={styles.category}>
			<CategoryView
				setEditMode={setEditMode}
				name={name}
				categories={categories}
				userCanEdit={userCanEdit}
			/>
			{editMode && (
				<CategoryEdit
					leaveEditMode={leaveEditMode}
					name={name}
					categories={categories}
					save={save}
					onChange={onChange}
				/>
			)}
		</div>
	);
}
