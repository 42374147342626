import { IConfig, ForloebClient, ForloebModel, IForloebModel } from '../../../api';
import { initialState } from '../state/initial-state';

const forloebClient = new ForloebClient(new IConfig());


export const createCourse = (course?: IForloebModel) => {
	const model = course || initialState.course;
	const forloeb = new ForloebModel(model);
	
	return forloebClient.create(forloeb);
};
