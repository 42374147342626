import React from 'react';

export function YouTubeIcon() {
	return (
		<svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1'>
			<title>youtube</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g id='icon/contentbar/youtube' fill='#4A4A4A'>
					<path
						d='M16.4760818,4.43795789 C23.4353534,4.49263991 27.6604275,5.18309498 29.3399019,6.65312098 C31.0098538,8.11481205 31.8266477,11.179537 31.9133264,15.8817286 L31.9210701,15.8824728 L31.9189602,16.2876566 L31.9210701,16.7408114 C31.8267525,21.4433539 31.009961,24.5083784 29.3399019,25.9701632 C27.6604021,27.4402115 23.4352255,28.1306652 16.4771123,28.1821995 L16.4760818,28.1853263 L16.2528508,28.1851205 C16.1839326,28.1849928 16.1152708,28.1848058 16.0468652,28.1845592 L15.8412579,28.1851205 L15.6180269,28.1853263 C8.6588832,28.1306652 4.43370664,27.4402115 2.75420682,25.9701632 C1.08414773,24.5083784 0.267356189,21.4433539 0.180765676,16.7406504 L0.173038611,16.7408114 L0.174601482,16.3501818 L0.175038611,16.2899579 L0.173038611,15.8824728 C0.267410888,11.1797251 1.08420365,8.11485691 2.75420682,6.65312098 C4.43370664,5.18307273 8.6588832,4.49261901 15.6169965,4.4410847 L15.6180269,4.43795789 L16.4760818,4.43795789 Z M16.0470386,6.15495789 L16.0581337,6.15541342 C9.28566782,6.1808168 5.16153216,6.827081 3.88427304,7.94505226 C2.64232223,9.03211853 1.93350787,11.8330026 1.89116208,16.3103923 C1.93350787,20.7902816 2.64232223,23.5911657 3.88427304,24.678232 C5.13250355,25.7707948 9.09967761,26.4128481 15.6004808,26.4651784 L16.0520386,26.4669579 L16.4936279,26.4651784 C22.9944311,26.4128481 26.9616052,25.7707948 28.2098357,24.678232 C29.4517865,23.5911657 30.1606008,20.7902816 30.2029466,16.3128919 C30.1606008,11.8330026 29.4517865,9.03211853 28.2098357,7.94505226 C26.9616052,6.85248944 22.9944311,6.21043609 16.4936279,6.15810579 L16.0470386,6.15495789 Z M12.8987213,10.5352459 L22.9136253,16.3111654 L12.8987213,22.0870848 L12.8987213,10.5352459 Z M14.6153986,13.5064699 L14.6153986,19.1155748 L19.4784591,16.3111654 L14.6153986,13.5064699 Z'
					></path>
				</g>
			</g>
		</svg>
	);
}
