type GradeType = { id: number; name: string };

export const gradesMap: Record<number, GradeType> = {
	'-5': { id: -5, name: 'DAGINSTITUTION' },
	'0': { id: 0, name: '0. KLASSE' },
	'1': { id: 1, name: '1. KLASSE' },
	'2': { id: 2, name: '2. KLASSE' },
	'3': { id: 3, name: '3. KLASSE' },
	'4': { id: 4, name: '4. KLASSE' },
	'5': { id: 5, name: '5. KLASSE' },
	'6': { id: 6, name: '6. KLASSE' },
	'7': { id: 7, name: '7. KLASSE' },
	'8': { id: 8, name: '8. KLASSE' },
	'9': { id: 9, name: '9. KLASSE' },
	'10': { id: 10, name: '10. KLASSE' },
	'15': { id: 15, name: 'UNGDOMSUDDANNELSE' },
	'20': { id: 20, name: 'VIDEREGÅENDE' },
};

export const gradesMapAsArray: GradeType[] = Object.values(gradesMap).sort(
	(a, b) => a.id - b.id,
);
