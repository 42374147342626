import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './create-learning-objective.module.scss';

import { LearningObjectiveIcon } from '../../../../../../components/svg/icons/LearningObjectiveIcon';
import { LaeringsmaalDto } from '../../../../../../api';
import { LearningObjectiveEdit } from '../../LearningObjectiveEdit';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { ADD_GOAL } from '../../learning-objective.lang';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className={styles.createLearningObjective}>{children}</div>
);

export function CreateLearningObjective({
	chapterId,
	save,
	changeLearningObjective,
	createLearningObjective,
	editControls,
}: {
	chapterId: string;
	save: SaveType;
	changeLearningObjective: (
		chapterId: string,
		learningObjective: LaeringsmaalDto,
	) => void;
	createLearningObjective: (
		chapterId: string,
		learningObjective: LaeringsmaalDto,
	) => void;
	editControls: EditControlsType;
}) {
	const [isCreateMode, setCreateMode] = useState<boolean>(false);

	if (!editControls().userCanEdit) {
		return null;
	}

	const changeCreateMode = () => {
		setCreateMode(!isCreateMode);
	};

	const handleLeaveEditMode = () => {
		setCreateMode(false);
	};

	if (isCreateMode) {
		const newLearningObjective = new LaeringsmaalDto({
			id: uuidv4(),
			tekst: '',
			tegnPaaLaering: [],
			faellesmaal: [],
		});

		return (
			<Wrapper>
				<LearningObjectiveEdit
					chapterId={chapterId}
					name={`${chapterId}-${newLearningObjective.id}`}
					save={save}
					changeLearningObjective={changeLearningObjective}
					leaveEditMode={handleLeaveEditMode}
					learningObjective={newLearningObjective}
					createLearningObjective={createLearningObjective}
				/>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<h4
				className={styles.createLearningObjectiveTitle}
				onClick={changeCreateMode}
			>
				<LearningObjectiveIcon />
				{ADD_GOAL}
			</h4>
		</Wrapper>
	);
}
