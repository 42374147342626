import React, { useState } from 'react';
import styles from './sub-objectives.module.scss';

import { Bullet } from '../../../../../../components/bullet/Bullet';
import { TextField } from '../../../../../../components/text-field/TextField';
import { Button } from '../../../../../../components/buttons/button/Button';
import { CloseIcon } from '../../../../../../components/svg/icons/CloseIcon';
import { ADD_SUB_GOAL } from '../../learning-objective.lang';

export const SubObjectives = ({
	subObjectives = [],
	changeSubObjectives,
}: {
	subObjectives?: string[];
	changeSubObjectives: (subObjectives?: string[]) => void;
}) => {
	const [objectives, setObjectives] = useState<string[]>(subObjectives);
	const [subObjectiveText, setSubObjectiveText] = useState('');

	const changeObjective = (change: { [index: string]: string }) => {
		const changedObjectives = objectives?.map((objective, key) => {
			if (key.toString() !== Object.keys(change)[0]) {
				return objective;
			}

			return Object.values(change)[0];
		});

		setObjectives(changedObjectives);
		changeSubObjectives(changedObjectives);
	};

	const handleDeleteSubObjective = (subObjective: string) => {
		const changedObjectives = objectives?.filter(
			objective => objective !== subObjective,
		);

		setObjectives(changedObjectives);
		changeSubObjectives(changedObjectives);
	};

	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		if (!value.length) {
			return;
		}

		const changedObjectives = [...objectives, value];

		setObjectives(changedObjectives);
		changeSubObjectives(changedObjectives);
		setSubObjectiveText('');
	};

	let result: React.ReactElement[] = objectives?.map((subObjective, key) => {
		return (
			<div key={key} className={styles.subObjective}>
				<div><Bullet className={styles.bullet}/></div>
				<TextField
					id={key.toString()}
					placeholder={ADD_SUB_GOAL}
					value={subObjective}
					className={styles.learningObjectiveTextField}
					onChange={changeObjective}
					autoComplete='off'
				/>
				<div>
					<Button
						className={styles.deleteSubObjectiveBtn}
						variant='text'
						onClick={() => handleDeleteSubObjective(subObjective)}
					>
						<CloseIcon />
					</Button>
				</div>
			</div>
		);
	});

	result = [
		...result,
		<div key='some-key' className={styles.subObjective}>
			<div><Bullet className={styles.bullet}/></div>
			<TextField
				id={objectives?.length.toString()}
				placeholder={ADD_SUB_GOAL}
				className={styles.learningObjectiveTextField}
				onChange={(change: { [name: string]: string }) =>
					setSubObjectiveText(Object.values(change)[0])
				}
				onBlur={handleBlur}
				value={subObjectiveText}
				autoComplete='off'
			/>
			<div></div>
		</div>,
	];

	return <div className={styles.subObjectives}>{result}</div>;
};
