export const ADD_TITLE = 'Skriv en titel';
export const ADD_TITLE_CHAPTER = 'Skriv en titel';
export const DESCRIPTION = 'Beskrivelse';
export const ADD_DESCRIPTION = 'Tilføj beskrivelse...';
export const TEACHER_INSTRUCTIONS = 'Lærervejledning';
export const ADD_TEACHER_INSTRUCTIONS = 'Tilføj lærervejledning...';
export const DURATION = 'Varighed';
export const LESSON = 'Lektion';
export const LESSONS = 'Lektoner';
export const SUBJECT = 'Fag';
export const GRADE = 'Trin';
export const CATEGORY = 'Kategori';
export const CHAPTER = 'Kapitel';
export const NEW_CHAPTER = 'Nyt kapitel';
export const COURSE = 'Forløb';
export const PUBLISH_COURSE = 'Udgiv forløb';
export const NEW_COURSE = 'Nyt forløb';
