import React from 'react';
import styles from './title-view.module.scss';
import { HeadingType } from './title.types';

interface TitleViewProps {
	name: string;
	text: string;
	setEditMode: (name: string) => void,
	headingType?: HeadingType;
}

export function TitleView({
	name,
	text,
	setEditMode,
	headingType,
}: TitleViewProps) {
	const Wrapper = headingType || 'h2';

	const editTitle = () => {
		setEditMode(name);
	};

	return (
		<div className={styles.title} onClick={editTitle}>
			<Wrapper>{text}</Wrapper>
		</div>
	);
}
