import React from 'react';
import styles from './element.module.scss';

import { Draggable } from 'react-beautiful-dnd';

import { Text } from '../element/text/Text';
import { TeacherNote } from '../element/teacher-note/TeacherNote';
import { Link } from '../element/link/Link';
import { YouTube } from '../element/youtube/YouTube';
import { DragIcon } from 'components/svg/icons/DragIcon';
import { Image } from '../element/image/Image';
import { ElementDto } from 'api';
import { EditControlsType, SaveType } from 'modules/course/course.types';
import { Exercise } from './exercise/Exercise';
import { ActionTypes } from 'modules/course/state/actions/course/types';
import { ELEMENT_ID_MAP } from '../create-element/element-utils';
import { FileComponent } from './file/file';
import { useClassName } from 'hooks/use-class-name';

const DragHandle = ({ editControls, ...dragHandleProps }: any) => {
	const { userCanEdit, editMode } = editControls(dragHandleProps.id);

	if (!userCanEdit || editMode) {
		return null;
	}

	return (
		<div className={styles.dragHandleWrapper}>
			<span {...dragHandleProps} className={styles.dragHandle}>
				<DragIcon />
			</span>
		</div>
	);
};

const Content = ({
	id,
	chapterId,
	pageId,
	innerRef,
	isDragging,
	element,
	editControls,
	videoControls,
	save,
	changeElement,
	deleteElement,
	children,
	...draggebleProps
}: {
	id: string;
	chapterId: string;
	pageId: string;
	innerRef: (element: HTMLElement) => void;
	isDragging: boolean;
	children: React.ReactElement;
	element: ElementDto;
	save: SaveType;
	changeElement: ActionTypes['changeElement'];
	deleteElement: ActionTypes['deleteElement'];
	editControls: EditControlsType;
	videoControls: any;
}) => {
	const classNames = useClassName(styles.element, isDragging && styles.isDragging);

	const elementTypes: {
		[id: number]: {
			id: number;
			view: React.ReactElement;
		};
	} = {
		[ELEMENT_ID_MAP.teachernote]: {
			id: ELEMENT_ID_MAP.teachernote,
			view: (
				<TeacherNote
					chapterId={chapterId}
					pageId={pageId}
					element={element}
					save={save}
					editControls={editControls}
					changeElement={changeElement}
					deleteElement={deleteElement}
				/>
			),
		},
		[ELEMENT_ID_MAP.link]: {
			id: ELEMENT_ID_MAP.link,
			view: (
				<Link
					chapterId={chapterId}
					pageId={pageId}
					element={element}
					save={save}
					editControls={editControls}
					changeElement={changeElement}
					deleteElement={deleteElement}
				/>
			),
		},
		[ELEMENT_ID_MAP.text]: {
			id: ELEMENT_ID_MAP.text,
			view: (
				<Text
					chapterId={chapterId}
					pageId={pageId}
					element={element}
					save={save}
					editControls={editControls}
					changeElement={changeElement}
					deleteElement={deleteElement}
				/>
			),
		},
		[ELEMENT_ID_MAP.image]: {
			id: ELEMENT_ID_MAP.image,
			view: (
				<Image
					chapterId={chapterId}
					pageId={pageId}
					element={element}
					save={save}
					editControls={editControls}
					changeElement={changeElement}
					deleteElement={deleteElement}
				/>
			),
		},
		[ELEMENT_ID_MAP.file]: {
			id: ELEMENT_ID_MAP.file,
			view: (
				<FileComponent
					chapterId={chapterId}
					pageId={pageId}
					element={element}
					save={save}
					editControls={editControls}
					changeElement={changeElement}
					deleteElement={deleteElement}
				/>
			),
		},
		[ELEMENT_ID_MAP.exercise]: {
			id: ELEMENT_ID_MAP.exercise,
			view: (
				<Exercise
					chapterId={chapterId}
					pageId={pageId}
					save={save}
					changeElement={changeElement}
					deleteElement={deleteElement}
					editControls={editControls}
					element={element}
				/>
			),
		},
		[ELEMENT_ID_MAP.youtube]: {
			id: ELEMENT_ID_MAP.youtube,
			view: (
				<YouTube
					chapterId={chapterId}
					pageId={pageId}
					save={save}
					changeElement={changeElement}
					deleteElement={deleteElement}
					editControls={editControls}
					element={element}
					videoControls={videoControls}
				/>
			),
		},
	};

	if (!elementTypes[element.elementType]) {
		return (
			<div ref={innerRef} className={classNames} {...draggebleProps}>
				{children}
				<div>not supported type</div>
			</div>
		);
	}

	const view = element.erOpgave
		? elementTypes[7].view
		: elementTypes[element.elementType].view;

	return (
		<div ref={innerRef} className={classNames} {...draggebleProps}>
			{children}
			{view}
		</div>
	);
};

export function Element({
	id,
	chapterId,
	pageId,
	index,
	editControls,
	element,
	save,
	changeElement,
	deleteElement,
	videoControls,
}: {
	id: string;
	chapterId: string;
	pageId: string;
	index: number;
	editControls: EditControlsType;
	element: ElementDto;
	save: SaveType;
	changeElement: () => void;
	deleteElement: () => void;
	videoControls: any;
}) {
	return (
		<Draggable
			draggableId={id}
			index={index}
			isDragDisabled={!editControls().userCanEdit}
		>
			{(provided, snapshot) => (
				<Content
					id={id}
					chapterId={chapterId}
					pageId={pageId}
					innerRef={provided.innerRef}
					isDragging={snapshot.isDragging}
					element={element}
					changeElement={changeElement}
					deleteElement={deleteElement}
					save={save}
					editControls={editControls}
					videoControls={videoControls}
					{...provided.draggableProps}
				>
					<DragHandle
						id={id}
						{...provided.dragHandleProps}
						editControls={editControls}
					/>
				</Content>
			)}
		</Draggable>
	);
}
