import React from 'react';

export function SearchIcon() {
	return (
		<svg width='32px' height='32px' viewBox='0 0 32 32' version='1.1'>
			<title>søg</title>
			<g
				id='Symbols'
				stroke='none'
				strokeWidth='1'
				fill='none'
				fillRule='evenodd'
			>
				<g fill='#4A4A4A'>
					<path
						d='M0,13.3164039 C0,20.6627074 5.9766537,26.6328077 13.3164039,26.6328077 C16.6979316,26.6328077 19.791112,25.3614581 22.1437641,23.2774933 L30.6303502,31.7640795 C30.7876306,31.9213598 30.9907844,32 31.2004915,32 C31.4101986,32 31.6133524,31.9213598 31.7706328,31.7640795 C32.0851935,31.4495187 32.0851935,30.9449109 31.7706328,30.6303502 L23.2774933,22.1437641 C25.3614581,19.791112 26.6328077,16.7044849 26.6328077,13.3164039 C26.6328077,5.97010035 20.656154,0 13.3164039,0 C5.9766537,0 0,5.97010035 0,13.3164039 Z M25.0272374,13.3164039 C25.0272374,19.771452 19.771452,25.0272374 13.3164039,25.0272374 C6.86135572,25.0272374 1.60557035,19.771452 1.60557035,13.3164039 C1.60557035,6.86135572 6.86135572,1.60557035 13.3164039,1.60557035 C19.771452,1.60557035 25.0272374,6.85480238 25.0272374,13.3164039 Z'
					></path>
				</g>
			</g>
		</svg>
	);
}
