import { ShadowElement, wcDefine } from './core';
import { imageViewService, IImageViewObserver } from './services/image-view.service';

class WcImageView extends ShadowElement {
	observer: IImageViewObserver;

	constructor() {
		super();

		this.observer = imageViewService.subscribe((newState, oldState) => {
			if (newState === oldState) return;

			this.connectedCallback();
		});
	}

	connectedCallback() {
		const imageSrc = imageViewService.state;

		if (!imageSrc) {
			this.shadow.innerHTML = '';

			return;
		}

		this.shadow.innerHTML = /*html*/ `
			<style>
				img {
					max-width: calc(100vw - 40px);
					max-height: calc(100vh - 40px);
					cursor: pointer;
					box-shadow: 0px 6px 30px -10px black;
				}
			</style>

			<wc-dialog-overlay allowClick="true" title="Klik for at lukke fuldskærmsvisning"/>
				<img src="${imageSrc}">
			</wc-dialog-overlay>
		`;

		const overlay = this.shadow.querySelector('wc-dialog-overlay') as HTMLElement;
		const img = this.shadow.querySelector('img');

		img.onclick = () => imageViewService.closeImage();
		overlay.onclick = () => imageViewService.closeImage();
	}

	disconnectedCallback() {
		this.observer.unsubscribe(this.observer);
	}
}

export const name = wcDefine('wc-image-view', WcImageView);
