import React from 'react';
import styles from './paging.module.scss';

import { SearchTokensType } from './paging.types';
import { Button } from '../../../../../../../../../components/buttons/button/Button';

import * as lang from './paging.lang';

export function Paging({
	searchTokens,
	performSearch,
}: {
	searchTokens?: SearchTokensType;
	performSearch: (pageToken?: string) => void;
}) {
	if (!searchTokens?.next && !searchTokens?.previous) {
		return null;
	}

	const handlePrevious = () => {
		performSearch(searchTokens.previous);
	};

	const handleNext = () => {
		performSearch(searchTokens.next);
	};

	const Pipe = () =>
		searchTokens.previous && searchTokens.next ? <span>|</span> : null;

	return (
		<div className={styles.paging}>
			{searchTokens.previous && (
				<Button
					onClick={handlePrevious}
					className={styles.previous}
					variant='inline'
				>
					{lang.PREVIOUS}
				</Button>
			)}
			
			<Pipe />

			{searchTokens.next && (
				<Button
					onClick={handleNext}
					className={styles.next}
					variant='inline'
				>
					{lang.NEXT}
				</Button>
			)}
		</div>
	);
}
