import React from 'react';
import styles from './subjects-view.module.scss';
import { FagDto } from 'api';

import { Button } from 'components/buttons/button/Button';
import { SUBJECTS } from './subjects.lang';

export function SubjectsView({
	name,
	subjects,
	setEditMode,
	userCanEdit,
}: {
	name: string;
	subjects?: FagDto[];
	setEditMode: (name: string) => void;
	userCanEdit?: boolean;
}) {
	const handleClick = () => setEditMode(name);

	const makeButtonText = () => {
		if (subjects?.length) {
			return subjects?.length > 1
				? `${subjects[0]?.navn?.toUpperCase()} + ${subjects?.length -
						1}`
				: subjects[0]?.navn?.toUpperCase();
		}

		return SUBJECTS.toUpperCase();
	};

	const makeHoverText = () => {
		if (!subjects?.length) {
			return;
		}

		if (subjects.length === 1) {
			return `${subjects[0].navn}`;
		}

		if (subjects.length === 2) {
			return `${subjects[0].navn} og ${subjects[1].navn}`;
		}

		return subjects.map((subject, key) => {
			if (key === subjects.length - 1) {
				return `og ${subject.navn}`;
			}

			return `${subject.navn}, `;
		}).join('');
	};

	const buttonVariant = subjects?.length ? 'roundedPrimary' : 'ghostRounded';
	const buttonStyle = !userCanEdit ? styles.notEditable : undefined;

	return (
		<div title={makeHoverText()} className={styles.subjectsView}>
			<Button
				className={buttonStyle}
				onClick={handleClick}
				variant={buttonVariant}
			>
				{makeButtonText()}
			</Button>
		</div>
	);
}
