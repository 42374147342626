import React, { useState } from 'react';

import { Editor } from '../../../../../../../components/editor/Editor';
import { TinyEditor } from '../../../../../../../components/tiny-editor/TinyEditor';
import { EditorTitle } from '../../../../../../../components/editor/components/editor-title/EditorTitle';

import { TextIcon } from '../../../../../../../components/svg/icons/TextIcon';
import * as lang from './teacher-note.lang';
import { SaveType } from 'modules/course/course.types';
import { ElementDto } from 'api';
import { Button } from 'components/buttons/button/Button';

import styles from './teacher-note-edit.module.scss';

const editorConfig = {
	placeholder: lang.PLACEHOLDER,
};

type TextEditProps = {
	name: string;
	text?: string;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement: () => void;
	element: ElementDto;
	save: SaveType;
}

export function TeacherNoteEdit({
	name,
	text,
	leaveEditMode,
	changeElement,
	deleteElement,
	element,
	save,
}: TextEditProps) {
	const [state, setState] = useState(text);

	const handleChangeText = (change: string) => {
		setState(change);
	};

	const handleSaveText = () => {
		if (state) {
			changeElement(state);
		}
		save(name);
		leaveEditMode(name);
	};
	
	const handleDeleteText = () => {
		deleteElement();
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => {
		leaveEditMode(name);
	};

	return (
		<Editor
			onCancel={handleLeaveEditMode}
			onSave={handleSaveText}
			title={<EditorTitle title={lang.TITLE} icon={<TextIcon />} />}
		>
			<TinyEditor
				config={editorConfig}
				model={state}
				onModelChange={handleChangeText}
			/>
			{element && (
				<Button
					onClick={handleDeleteText}
					variant='danger'
					className={styles.deleteButton}
				>
					{lang.DELETE_TEXT}
				</Button>
			)}
		</Editor>
	);
}
