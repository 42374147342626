import { useRef } from 'react';

export const useDebounced = (delay: number) => {
	const timeoutRef = useRef<NodeJS.Timeout>();

	return (callback: (...args: any[]) => void) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(callback, delay);
	};
};
