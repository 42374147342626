import React, { Children, isValidElement, cloneElement } from 'react';
import styles from './page.module.scss';
import { PageProps } from './types';

export function Page(props: PageProps) {
	const { backgroundColor, pageClass, children } = props;
	const className = [styles.page, pageClass].join(' ');
	
	return (
		<div style={{ backgroundColor }} className={className}>
			{Children.map(children, child => {
				if (isValidElement(child)) {
					return cloneElement<any>(child, { className: 'row' });
				}
	
				return child;
			})}
			</div>
		);
}
