import React, { useState } from 'react';
import styles from './image-edit.module.scss';
import { Editor } from '../../../../components/editor/Editor';
import { EditorTitle } from 'components/editor/components/editor-title/EditorTitle';
import { SaveType } from 'modules/course/course.types';
import {
	FileUpload,
	FileUploadStateType,
} from 'components/file/file-upload/FileUpload';
import { convertJsonToImageElement } from './utils/convert-json-to-image-element';
import { ElementDto } from 'api';
import { Button } from 'components/buttons/button/Button';
import { TextField } from 'components/text-field/TextField';
import { Icon } from 'components/svg/icons';

interface IImageEdit {
	name: string;
	image?: string;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement?: () => void;
	element?: ElementDto;
	save: SaveType;
	withDescription?: boolean;
}

export function ImageEdit({
	name,
	image,
	leaveEditMode,
	changeElement,
	deleteElement,
	element,
	save,
	withDescription,
}: IImageEdit) {
	const [
		{
			_base: { blobproviderMetadataGuid, filNavn, mimeType },
			url,
			beskrivelse,
		},
		setFileState,
	] = useState<FileUploadStateType>(convertJsonToImageElement(image));
	const [descriptionState, setDescription] = useState(beskrivelse);
	const [isUploading, setIsUploading] = useState(false);

	const handleChange = (state: FileUploadStateType) => setFileState(state);
	const handleDeleteImage = () => {
		deleteElement?.();
		leaveEditMode(name);
	};
	const handleLeaveEditMode = () => leaveEditMode(name);

	const handleSave = () => {
		if (url || blobproviderMetadataGuid) {
			changeElement(
				JSON.stringify(
					blobproviderMetadataGuid
						? {
								_base: {
									blobproviderMetadataGuid,
									filNavn,
									mimeType,
								},
								beskrivelse: descriptionState,
								source: 'upload',
								url,
						  }
						: {
								_base: {
									blobproviderMetadataGuid,
									filNavn,
									mimeType,
								},
								source: 'link',
								link: url,
								url,
								beskrivelse: descriptionState,
						  },
				),
			);
		}
		save(name);
		leaveEditMode(name);
	};

	const handleChangeDescription = (change: { [name: string]: string }) => {
		setDescription(change['image-description']);
	};

	return (
		<Editor
			onCancel={handleLeaveEditMode}
			onSave={handleSave}
			title={
				<EditorTitle
					title='Billede'
					icon={<Icon icon='billede' size='32px' />}
				/>
			}
			isSaveDisabled={isUploading}
		>
			<FileUpload
				onChange={handleChange}
				initialState={{ url, _base: { blobproviderMetadataGuid } }}
				addUrlTextArea
				accepted='images'
				invalidFileErrorMessage='Du kan ikke uploade billedfiler af denne type.'
				setIsUploading={setIsUploading}
			/>
			{withDescription ? (
				<div className={styles.descriptionFieldWrapper}>
					<TextField
						id='image-description'
						className={styles.descriptionField}
						onChange={handleChangeDescription}
						type='textarea'
						placeholder='Tilføj beskrivelse...'
						value={descriptionState}
					/>
				</div>
			) : (
				<span></span>
			)}
			{element ? (
				<Button
					onClick={handleDeleteImage}
					variant='danger'
					className={styles.deleteButton}
				>
					Slet billede
				</Button>
			) : (
				<span></span>
			)}
		</Editor>
	);
}
