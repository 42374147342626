import React, { useState, useRef, useEffect } from 'react';
import styles from './title.module.scss';

import { TitleView } from './TitleView';
import { TitleEdit } from './TitleEdit';
import { HeadingType } from './title.types';
import { EditControlsType, SaveType } from '../../course.types';
import { Button } from 'components/buttons/button/Button';
import { useClickOutside } from 'hooks/useClickOutside';
import { useClassName } from 'hooks/use-class-name';

const Wrapper = ({
	children,
	userCanEdit,
	isEditMode,
}: {
	children: React.ReactNode;
	userCanEdit?: boolean;
	isEditMode?: boolean;
}) => {
	const classNames = useClassName(
		styles.title,
		userCanEdit && styles.editable,
		isEditMode && styles.editing,
	);

	return <div className={classNames}>{children}</div>;
};

type TitleProps = {
	name: string;
	text: string;
	headingType?: HeadingType;
	editControls: EditControlsType;
	changeTitle: (title: string) => void;
	save: SaveType;
	deleteOptions?: {
		text: string;
		confirmText: string;
		onDelete: () => void;
	};
	placeholder?: string,
};

export function Title({
	name,
	text,
	headingType,
	editControls,
	changeTitle,
	save,
	deleteOptions,
	placeholder,
}: TitleProps) {
	const [confirmDelete, setConfirmDelete] = useState(false);
	const initialText = text;
	const [titleText, setTitleText] = useState(initialText);
	const { setEditMode, leaveEditMode, editMode, userCanEdit } = editControls(
		name,
	);

	useEffect(() => {
		setTitleText(text);
	}, [text]);

	const handleLeaveEditMode = () => {
		if (deleteOptions) {
			setConfirmDelete(false);
		}

		if (!titleText.length || titleText === initialText) {
			setTitleText(initialText);
			leaveEditMode(name);

			return;
		}

		changeTitle(titleText);
		leaveEditMode(name);
		save(name);
	};

	const textFieldRef = useRef(null);
	useClickOutside(textFieldRef, handleLeaveEditMode);

	const deleteClassNames = useClassName(
		!!deleteOptions && styles.titleEditWrapper,
	);

	const handleChangeTitle = (title: string) => {
		setTitleText(title);
	};

	useEffect(() => {
		return () => {
			setConfirmDelete(false);
		};
	}, []);

	if (!userCanEdit && !text?.length) {
		return null;
	}

	const handleDelete = () => {
		setConfirmDelete(true);
	};

	const handleConfirmDelete = () => {
		deleteOptions?.onDelete();
	};

	const onDelete = confirmDelete ? handleConfirmDelete : handleDelete;
	const deleteButtonVariant = confirmDelete ? 'ghostPrimary' : 'meta';

	if (editMode) {
		return (
			<Wrapper isEditMode>
				<div ref={textFieldRef} className={deleteClassNames}>
					<TitleEdit
						text={titleText}
						name={name}
						changeTitle={handleChangeTitle}
						placeholder={placeholder}
					/>
					{deleteOptions ? (
						<Button
							variant={deleteButtonVariant}
							onClick={onDelete}
						>
							{
								deleteOptions[
									confirmDelete ? 'confirmText' : 'text'
								]
							}
						</Button>
					) : null}
				</div>
			</Wrapper>
		);
	}

	return (
		<Wrapper userCanEdit={userCanEdit}>
			<TitleView
				name={name}
				text={text}
				headingType={headingType}
				setEditMode={setEditMode}
			/>
		</Wrapper>
	);
}
