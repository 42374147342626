import React from 'react';
import styles from './range.module.scss';
import { useClassName } from 'hooks/use-class-name';

interface IRangeElement {
	title: string;
	id: number;
	selection: number[];
	onClick: Function;
	isInRange: (index: number) => boolean;
}

export const RangeElement = ({
	id,
	selection,
	title,
	onClick,
	isInRange,
}: IRangeElement) => {
	const className = useClassName(
		styles.element,
		selection.includes(id) && styles.selected,
		isInRange(id) && styles.inRange,
	);

	const handleClick = () => onClick(id);

	return (
		<div className={className} onClick={handleClick}>
			<div className={styles.side}>
				<div className={styles.circle}></div>
			</div>
			<div className={styles.name}>{title}</div>
		</div>
	);
};
