import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './search-authors.module.scss';

export function LoadingAuthors() {
	return (
		<div className={styles.loadingAuthors}>
			<Skeleton circle={true} width={32} height={32} />
			<Skeleton height={32} width={140} />
			<Skeleton height={32} width={60} />
		</div>
	);
}