import React, { useCallback } from 'react';
import { RangeElement } from './RangeElement';

interface IRange {
	selection?: number[];
	range: { id: number; name: string }[];
	onChange: (selection: number[]) => void;
}

export const Range = ({ selection = [], range, onChange }: IRange) => {
	const isInRange = useCallback(
		(id: number) => {
			if (selection.length < 2) return false;

			return id >= selection[0] && id <= selection[1];
		},
		[selection],
	);

	const handleSelect = useCallback(
		(id: number) => {
			let newSelection;

			if (selection.includes(id)) {
				newSelection = selection.filter((selected) => selected !== id);
			} else if (selection.length === 2) {
				if (isInRange(id) || id < selection[0]) {
					newSelection = [id, selection[1]];
				} else {
					newSelection = [selection[0], id];
				}
			} else {
				newSelection = [...selection, id];
			}

			newSelection.sort((a, b) => a - b);
			onChange(newSelection);
		},
		[selection, isInRange, onChange],
	);

	return (
		<>
			{range.map((element) => (
				<RangeElement
					key={element.id}
					title={element.name}
					id={element.id}
					selection={selection}
					onClick={handleSelect}
					isInRange={isInRange}
				/>
			))}
		</>
	);
};
