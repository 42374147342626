import React from 'react';
import styles from './loading-thumbnail.module.scss';

import Skeleton from 'react-loading-skeleton';
import { useClassName } from 'hooks/use-class-name';

export function LoadingThumbnail() {
	const thumbnailClassNames = useClassName(
		styles.thumbnail,
		styles.thumbnailWithImage,
	);

	return (
		<div className={thumbnailClassNames}>
			<Skeleton height={150} className={styles.media} />
			<Skeleton height={150} className={styles.thumbnailContent} />
		</div>
	);
}
