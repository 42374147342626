import React from 'react';
import styles from './bullet.module.scss';
import { useClassName } from 'hooks/use-class-name';

export const Bullet = ({ className }: { className?: string }) => {
	const classNames = useClassName(styles.bullet, className);

	return (
		<div className={classNames}>
			<span>&bull;</span>
		</div>
	);
};
