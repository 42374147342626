import React from 'react';
import Gravatar from 'react-gravatar';

import styles from './avatar.module.scss';

export function Avatar({
	className = styles.avatar,
	email,
}: {
	className?: string;
	email?: string;
}) {
	return <Gravatar className={className} email={email} default='retro' />;
}
