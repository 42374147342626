import { useCallback, useReducer, useState, useEffect } from 'react';
import { reducer as courseReducer } from 'modules/course/state/reducer';
import {
	initialState as courseInitialState,
	StateType,
} from 'modules/course/state/initial-state';
import { ForloebListItemDto, ForloebListDto, IForloebSearchDto } from 'api';
import { globalActions } from 'modules/course/state/actions/global/actions';
import { courseLibraryActions } from 'modules/course/state/actions/course-library/actions';
import { EditControlsType } from 'modules/course/course.types';

interface IUseCourseLibraryPage {
	getCourses: (params?: IForloebSearchDto) => Promise<ForloebListDto>;
}

export function useCourseLibraryPage({
	getCourses,
}: IUseCourseLibraryPage): {
	state: StateType;
	actions: ReturnType<typeof globalActions> &
		ReturnType<typeof courseLibraryActions>;
	editControls: EditControlsType;
	isLoading: boolean;
	courses: ForloebListItemDto[] | undefined;
	showMore: boolean;
	handleShowMore: () => void;
} {
	const [isLoading, setIsloading] = useState(true);
	const [showMore, setShowMore] = useState(false);
	const [courses, setCourses] = useState<ForloebListItemDto[] | undefined>(
		[],
	);

	const [state, dispatch] = useReducer<typeof courseReducer>(
		courseReducer,
		courseInitialState,
	);

	const actions = {
		...globalActions(dispatch),
		...courseLibraryActions(dispatch),
	};

	const editControls: EditControlsType = useCallback(
		(name?: string) => {
			return {
				editMode: !!state._editing.find(
					(item: string) => item === name,
				),
				leaveEditMode: actions.leaveEditMode,
				setEditMode: actions.setEditMode,
				userCanEdit: true,
			};
		},
		[state, actions],
	);

	useEffect(() => {
		setIsloading(true);
		setCourses(undefined);

		getCourses({ ...state.courseLibrary, lastSeenId: undefined })
			.then(({ forloeb = [], lastSeenId }) => {
				setCourses(forloeb);
				setShowMore(forloeb.length === state.courseLibrary.limit);
				actions.setCourseLibraryLastSeenId(lastSeenId);
			})
			.then(() => setIsloading(false));
	}, [getCourses, state.courseSearchUpdateTimestamp]);

	const handleShowMore = useCallback(async () => {
		setIsloading(true);

		const { forloeb = [], lastSeenId } = await getCourses(
			state.courseLibrary,
		);

		setShowMore(forloeb.length === state.courseLibrary.limit);

		setCourses((courses = []) => {
			for (let index = 0; index < forloeb.length; index++) {
				courses.push(forloeb[index]);
			}

			return courses;
		});

		actions.setCourseLibraryLastSeenId(lastSeenId);

		setIsloading(false);
	}, [actions, getCourses, setCourses]);

	return {
		state,
		actions,
		editControls,
		isLoading,
		courses,
		showMore,
		handleShowMore,
	};
}
