/* eslint-disable no-magic-numbers */
import { ShadowElement, wcDefine } from './core';

class WcCourseImage extends ShadowElement {
	image = new Image();

	static ratio = 9 / 16;
	static get observedAttributes() {
		return ['imgsrc'];
	}

	getImageComponent(imgsrc: string) {
		return `<img src="${imgsrc}" alt="Klik på billedet for at forstørre" />`;
	}

	renderFancy(imgsrc: string, height: number) {
		this.shadow.innerHTML = /*html*/ `
		<style>
			:host {
				--height: ${height}px;
			}

			.container {
				position: relative;
				width: 100%;
				height: var(--height);
				cursor: pointer;
				margin-top: 15px;
			}

			.concealOverflow {
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				filter:brightness(0.8);
			}

			.blurryFiller {
				background-image: url('${imgsrc}');
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				filter: blur(8px);
				-webkit-filter: blur(8px);
				height: 140%;
				width: 140%;
				transition: 2s;
				transform: scale(1);
			}
			
			.container:hover .blurryFiller {
				opacity: 0.9;
				transform: scale(1.1);
			}

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: var(--height);
			}
		</style>

		<div class="container">
			<div class="container concealOverflow">
				<div class="blurryFiller"></div>
			</div>
			${this.getImageComponent(imgsrc)}
		</div>
		`;
	}

	displayFancy(imgsrc: string) {
		// Initial render to get container width;
		this.renderFancy(imgsrc, 250);

		const container = this.shadow.querySelector('.container') as HTMLDivElement;
		const newHeight = Math.floor(container.getBoundingClientRect().width * WcCourseImage.ratio);
		// Render result with calculated height;
		this.renderFancy(imgsrc, newHeight);
	}

	displaySimple(imgsrc: string) {
		this.shadow.innerHTML = /*html*/ `
			<style>
				img {
					width: 100%;
					cursor: pointer;
				}
			</style>

			${this.getImageComponent(imgsrc)}
		`;
	}

	connectedCallback() {
		this.image.src = this.getAttribute('imgsrc');

		if (!this.image.src) return;

		const { width, height } = this.image;

		if (!width && !height) return window.requestAnimationFrame(() => this.connectedCallback());

		if (height / width > WcCourseImage.ratio) {
			return this.displayFancy(this.image.src);
		}

		return this.displaySimple(this.image.src);
	}

	attributeChangedCallback() {
		this.connectedCallback();
	}
}

export const name = wcDefine('wc-course-image', WcCourseImage);