import { ShadowElement, wcDefine } from './core';

class WcDialogOverlay extends ShadowElement {
	connectedCallback() {
		const allowClick = this.getAttribute('allowClick') === 'true';

		// eslint-disable-next-line spaced-comment
		this.shadow.innerHTML = /*html*/ `
			<style>
				@keyframes fadeIn {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}

				div {
					position: fixed;
					top: 0;
					left: 0;
					height: 100vh;
					width: 100vw;
					background-color: rgba(255, 255, 255, 0.7);
					display: flex;
					align-items: center;
					justify-content: center;
					color: var(--ds-color-text);
					z-index: 10000;
					animation: fadeIn 300ms ease-in;
				}
				
				.clickable {
					cursor: pointer;
				}
			</style>

			<div>
				<slot></slot>	
			</div>
		`;

		const div = this.shadow.querySelector('div');

		if (allowClick) {
			div.classList.add('clickable');
		} else {
			div.onclick = (event) => event.stopImmediatePropagation();
		}
	}
}

export const name = wcDefine('wc-dialog-overlay', WcDialogOverlay);
