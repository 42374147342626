import React from 'react';
import styles from './login.module.scss';
import { Login } from '../../modules/login/Login';

export function LoginPage({ className }: { className: string }) {
	const classNames = [styles.loginPage, className].join(' ');

	return (
		<div className={classNames}>
			<Login />
		</div>
	);
}
