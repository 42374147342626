import {
	IConfig,
	ForloebClient,
	IForloebSearchDto,
	ForloebSearchDto,
} from 'api';

const forloebClient = new ForloebClient(new IConfig());

export const getMyCourses = (params: IForloebSearchDto = {}) => {
	const search = new ForloebSearchDto(params);

	return forloebClient.mineForloeb(search);
};
