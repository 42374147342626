import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import styles from './chapter.module.scss';

import { EditControlsType, SaveType } from '../../course.types';
import { CreatePage } from '../page/components/create-page/CreatePage';

import {
	KapitelDto,
	SideDto,
	ElementDto,
	LaeringsmaalDto,
} from '../../../../api';

import {
	DraggablePagesType,
	DropResult,
	DraggableElementsType,
} from './chapter.types';

import { Title } from '../title/Title';
import { LearningObjective } from '../learning-objective/LearningObjective';
import { CreateLearningObjective } from '../learning-objective/components/create-learning-objective/CreateLearningObjective';
import { Page } from '../page/Page';
import { Ruler } from '../ruler/Ruler';
import { GlobalActionTypes } from 'modules/course/state/actions/global/types';
import { useHistory } from 'hooks/useHistory';
import { routes } from 'routes';
import { ADD_TITLE_CHAPTER } from '../../course.lang';

const LearningObjectiveList = ({
	chapterId,
	learningObjectives,
	save,
	editControls,
	changeLearningObjective,
	deleteLearningObjective,
}: {
	chapterId: string;
	learningObjectives?: LaeringsmaalDto[];
	save: SaveType;
	editControls: EditControlsType;
	changeLearningObjective: () => void;
	deleteLearningObjective: () => void;
}) => {
	if (!learningObjectives?.length) {
		return null;
	}

	const objectives = learningObjectives?.map((learningObjective, key) => (
		<LearningObjective
			key={key}
			chapterId={chapterId}
			name={learningObjective.id}
			save={save}
			editControls={editControls}
			learningObjective={learningObjective}
			allowDelete={true}
			changeLearningObjective={changeLearningObjective}
			deleteLearningObjective={deleteLearningObjective}
		/>
	));

	return <>{objectives}</>;
};

const CHAPTER_NAME = 'dragging-page-element';

export function Chapter({
	id,
	courseId,
	editControls,
	videoControls,
	chapter,
	...props
}: {
	id: string;
	courseId: string;
	editControls: EditControlsType;
	chapter: KapitelDto;
	actions: { [name: string]: (...args: any) => any };
	globalActions: GlobalActionTypes;
	videoControls: any;
}) {
	const history = useHistory();

	const handleChangeChapterTitle = (title: string) => {
		props.actions.changeChapterTitle(id, title);
	};

	const makePagesData = useCallback(
		(pagesList: SideDto[]) => {
			const elementsList: ElementDto[] = pagesList
				.map((page) => page.elementer)
				.flat();

			const elements: DraggableElementsType = elementsList.reduce(
				(result, { id, ...elementProps }) => {
					return {
						...result,
						[id]: { id, props: elementProps },
					};
				},
				{},
			);

			const pages = pagesList.reduce((result, page) => {
				return {
					...result,
					[page.id]: {
						id: page.id,
						title: page.overskrift,
						elementIds: page.elementer.map(({ id }) => id),
					},
				};
			}, {});

			return {
				elements,
				pages,
				pageOrder: chapter.sider.map(({ id }) => id),
			};
		},
		[chapter.sider],
	);

	const pagesData = makePagesData(chapter.sider);
	const [pagesState, setPagesState] = useState<DraggablePagesType>(pagesData);

	useEffect(() => {
		setPagesState(makePagesData(chapter.sider));
	}, [makePagesData, chapter.sider]);

	const onDragStart = () => {
		editControls().setEditMode(CHAPTER_NAME);
	};

	const onDragEnd = (result: DropResult) => {
		const { destination, source, draggableId } = result;

		// dropped the draggable outside
		if (!destination) {
			return;
		}

		// dropped the draggable to its position origin
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			editControls().leaveEditMode(CHAPTER_NAME);

			return;
		}

		const start = pagesState.pages[source.droppableId];
		const finish = pagesState.pages[destination.droppableId];

		if (start === finish) {
			const newElementIds = Array.from(start.elementIds);
			newElementIds.splice(source.index, 1);
			newElementIds.splice(destination.index, 0, draggableId);

			const newPage = {
				...start,
				elementIds: newElementIds,
			};

			const newState = {
				...pagesState,
				pages: {
					...pagesState.pages,
					[newPage.id]: newPage,
				},
			};

			setPagesState(newState);
			props.actions.changeElementPosition(id, result, true);
			props.globalActions.setUpdated(CHAPTER_NAME);
			editControls().leaveEditMode(CHAPTER_NAME);

			return;
		}

		// Moving from one page to another
		const startElementIds = Array.from(start.elementIds);
		startElementIds.splice(source.index, 1);
		const newStart = { ...start, elementIds: startElementIds };

		const finishElementIds = Array.from(finish.elementIds);
		finishElementIds.splice(destination.index, 0, draggableId);
		const newFinish = { ...finish, elementIds: finishElementIds };

		const newState = {
			...pagesState,
			pages: {
				...pagesState.pages,
				[newStart.id]: newStart,
				[newFinish.id]: newFinish,
			},
		};

		setPagesState(newState);
		props.actions.changeElementPosition(id, result, false);
		props.globalActions.setUpdated('dragging-page-element');
	};

	const handleDeleteChapter = () => {
		props.actions.deleteChapter(id);
		props.globalActions.setUpdated(`deleted-chapter-${id}`);
		props.globalActions.leaveEditMode(id, true);

		history.push({ pathname: routes.course.setPath(courseId) });
	};

	const deleteOptions = {
		text: 'Slet kapitlet',
		confirmText: 'Bekræft slet',
		onDelete: handleDeleteChapter,
	};

	return (
		<div className={styles.chapter}>
			<Title
				name={`chapter-title-${chapter.id}`}
				text={chapter.overskrift}
				changeTitle={handleChangeChapterTitle}
				save={props.globalActions.setUpdated}
				editControls={editControls}
				deleteOptions={deleteOptions}
				placeholder={ADD_TITLE_CHAPTER}
			/>

			<Ruler />

			<LearningObjectiveList
				chapterId={chapter.id}
				learningObjectives={chapter.laeringsmaal}
				changeLearningObjective={props.actions.changeLearningObjective}
				deleteLearningObjective={props.actions.deleteLearningObjective}
				editControls={editControls}
				save={props.globalActions.setUpdated}
			/>

			<CreateLearningObjective
				chapterId={chapter.id}
				save={props.globalActions.setUpdated}
				changeLearningObjective={props.actions.changeLearningObjective}
				createLearningObjective={props.actions.createLearningObjective}
				editControls={editControls}
			/>

			<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
				{pagesState.pageOrder.map((pageId: string) => {
					const page = pagesState.pages[pageId];
					const elements = page.elementIds.map(
						(elementId) => pagesState.elements[elementId],
					);

					return (
						<Page
							chapterId={id}
							save={props.globalActions.setUpdated}
							editControls={editControls}
							createElement={props.actions.createElement}
							changeElement={props.actions.changeElement}
							changePageTitle={props.actions.changePageTitle}
							deleteElement={props.actions.deleteElement}
							deletePage={props.actions.deletePage}
							videoControls={videoControls}
							key={page.id}
							{...page}
							elements={elements}
							leaveEditMode={props.globalActions.leaveEditMode}
						/>
					);
				})}
			</DragDropContext>

			<CreatePage
				chapterId={id}
				userCanEdit={editControls().userCanEdit}
				createNewPage={props.actions.createNewPage}
				save={props.globalActions.setUpdated}
			/>

			<Ruler />
		</div>
	);
}
