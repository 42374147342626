import React, { useState } from 'react';
import styles from './duration-edit.module.scss';
import { TextField } from '../../../../components/text-field/TextField';
import { ADD_LESSONS_OF_45_MINUTES } from './duration.lang';
import { SaveType } from 'modules/course/course.types';

export function DurationEdit({
	name,
	duration,
	leaveEditMode,
	changeDuration,
	save,
}: {
	name: string;
	duration?: number,
	leaveEditMode: (name: string) => void;
	changeDuration: (duration: number) => void;
	save: SaveType;
}) {
	const [change, setChange] = useState(duration);

	const handleChangeDuration = (change: { [name: string]: number}) => {
		setChange(change[name]);
	};

	const handleLeaveEditMode = () => {
		if (change) {
			changeDuration(change);
		}

		if (change !== duration) {
			save(name);
		}

		leaveEditMode(name);
	};

	return (
		<div className={styles.durationEdit}>
			<TextField
				id={name}
				autoFocus
				type='number'
				onBlur={handleLeaveEditMode}
				onChange={handleChangeDuration}
				placeholder={ADD_LESSONS_OF_45_MINUTES}
				value={change}
				autoComplete='off'
			/>
		</div>
	);
}
