import React from 'react';
import { Editor } from 'components/editor/Editor';
import { EditorTitle } from 'components/editor/components/editor-title/EditorTitle';
import {
	FileUpload,
	FileUploadStateType,
	DocumentUploadFileStateType,
	emptyState,
	documentEmptyState,
} from 'components/file/file-upload/FileUpload';
import { TextField } from 'components/text-field/TextField';
import linkStyles from '../link/link-edit.module.scss';
import imageStyles from 'modules/course/components/image/image-edit.module.scss';
import { Button } from 'components/buttons/button/Button';
import { ElementDto } from 'api';
import { SaveType } from 'modules/course/course.types';
import { Icon } from 'components/svg/icons';
import { JSONTryParseWithDefaultValue } from 'utils/json';

interface IFileEdit {
	name: string;
	image?: string;
	leaveEditMode: (name: string) => void;
	changeElement: (content: string) => void;
	deleteElement?: () => void;
	element?: ElementDto;
	save: SaveType;
	withDescription?: boolean;
}

function convertElementToState(element): FileUploadStateType {
	if (!element?.indhold?.length) return emptyState;

	const fileDataState = JSONTryParseWithDefaultValue<
		DocumentUploadFileStateType,
		DocumentUploadFileStateType
	>(element.indhold, documentEmptyState);

	return fileDataState.data;
}

export function FileEdit({
	name,
	leaveEditMode,
	changeElement,
	deleteElement,
	save,
	element,
	withDescription,
}: IFileEdit) {
	const [fileState, setFileState] = React.useState<FileUploadStateType>(
		convertElementToState(element),
	);

	const {
		_base: { blobproviderMetadataGuid, filNavn, mimeType },
		url,
		beskrivelse,
	} = fileState;

	const [isUploading, setIsUploading] = React.useState(false);
	const [titleState, setTitle] = React.useState(filNavn);
	const [descriptionState, setDescription] = React.useState(beskrivelse);

	const handleDeleteFile = () => {
		deleteElement?.();
		leaveEditMode(name);
	};

	const handleLeaveEditMode = () => leaveEditMode(name);

	const handleSave = () => {
		if (url || blobproviderMetadataGuid) {
			changeElement(
				JSON.stringify({
					source: 'egetmateriale',
					data: {
						_base: {
							blobproviderMetadataGuid,
							filNavn,
							mimeType,
						},
						beskrivelse: descriptionState,
						emne: titleState,
						url,
					},
				}),
			);
		}
		save(name);
		leaveEditMode(name);
	};

	const handleChange = (state: FileUploadStateType) => {
		if (state?._base.filNavn && !titleState?.length) {
			setTitle(state._base.filNavn);
		}

		setFileState(state);
	};

	const handleChangeTitle = (change: { [name: string]: string }) => {
		setTitle(change['file-title']);
	};

	const handleChangeDescription = (change: { [name: string]: string }) => {
		setDescription(change['file-description']);
	};

	return (
		<Editor
			onCancel={handleLeaveEditMode}
			onSave={handleSave}
			isSaveDisabled={isUploading}
			title={
				<EditorTitle
					title='Upload fil'
					icon={<Icon icon='fil' size='32px' />}
				/>
			}
		>
			{element ? (
				<span />
			) : (
				<FileUpload
					onChange={handleChange}
					initialState={{ url, _base: { blobproviderMetadataGuid } }}
					placeholder='Vælg fil eller træk hertil'
					icon='fil'
					accepted='documents'
					invalidFileErrorMessage='Du kan kun uploade pdf-, word- og powerpointfiler.'
					setIsUploading={setIsUploading}
				/>
			)}
			{withDescription ? (
				<div className={linkStyles.contentContainer}>
					<TextField
						id='file-title'
						className={linkStyles.titleField}
						autoComplete='off'
						onChange={handleChangeTitle}
						placeholder='Tilføj titel...'
						value={titleState}
					/>
					<TextField
						id='file-description'
						className={linkStyles.descriptionField}
						onChange={handleChangeDescription}
						type='textarea'
						placeholder='Tilføj beskrivelse...'
						value={descriptionState}
					/>
				</div>
			) : (
				<span></span>
			)}
			{element ? (
				<Button
					onClick={handleDeleteFile}
					variant='danger'
					className={imageStyles.deleteButton}
				>
					Slet Fil
				</Button>
			) : (
				<span></span>
			)}
		</Editor>
	);
}
