import { v4 as uuidv4 } from 'uuid';
import { FagDto, LaeringsmaalDto, ForfatterDto } from 'api';
import { fetchCourse } from '../../../api/fetch-course';
import { CourseActionType } from './types';
import { DropResult } from 'react-beautiful-dnd';

export function courseActions(dispatch: (action: CourseActionType) => void) {
	return {
		loadCourse(id: string) {
			return fetchCourse(id).then(course =>
				dispatch({
					type: 'loadCourse',
					payload: { course },
				}),
			);
		},

		changeTitle(title: string) {
			dispatch({
				type: 'changeTitle',
				payload: { title },
			});
		},

		changeDescription(description: string) {
			dispatch({
				type: 'changeDescription',
				payload: { description },
			});
		},

		changeTeacherInstructions(teacherInstructions: string) {
			dispatch({
				type: 'changeTeacherInstructions',
				payload: { teacherInstructions },
			});
		},

		changeDuration(duration: number) {
			dispatch({
				type: 'changeDuration',
				payload: { duration },
			});
		},

		changeImage(image: string) {
			dispatch({
				type: 'changeImage',
				payload: { image },
			});
		},

		setSubjects(subjects: FagDto[]) {
			dispatch({
				type: 'setSubjects',
				payload: { subjects },
			});
		},

		removeSubjects(subjects: FagDto[]) {
			dispatch({
				type: 'setSubjects',
				payload: { subjects },
			});
		},

		addSubject(subject: FagDto) {
			dispatch({
				type: 'addSubject',
				payload: { subject },
			});
		},

		changeCourseCategories(categories: string[]) {
			dispatch({
				type: 'changeCourseCategories',
				payload: categories,
			});
		},

		publishToLibrary() {
			dispatch({
				type: 'publishToLibrary',
			});
		},

		removeFromLibrary() {
			dispatch({
				type: 'removeFromLibrary',
			});
		},

		publishToMinUddannelse() {
			dispatch({
				type: 'publishToMinUddannelse',
			});
		},

		removeFromMinUddannelse() {
			dispatch({
				type: 'removeFromMinUddannelse',
			});
		},

		changeLearningObjective(
			chapterId: string,
			learningObjective: LaeringsmaalDto,
		) {
			dispatch({
				type: 'changeLearningObjective',
				payload: { chapterId, learningObjective },
			});
		},

		createLearningObjective(
			chapterId: string,
			newLearningObjective: LaeringsmaalDto,
		) {
			dispatch({
				type: 'createLearningObjective',
				payload: { chapterId, newLearningObjective },
			});
		},

		deleteLearningObjective(
			chapterId: string,
			learningObjectiveId: string,
		) {
			dispatch({
				type: 'deleteLearningObjective',
				payload: { chapterId, learningObjectiveId },
			});
		},

		createNewChapter() {
			const id = uuidv4();
			
			dispatch({
				type: 'createNewChapter',
				payload: { id },
			});

			return id;
		},

		changeChapterTitle(chapterId: string, title: string) {
			dispatch({
				type: 'changeChapterTitle',
				payload: { chapterId, title },
			});
		},

		setSavedComplete() {
			dispatch({
				type: 'setSavedComplete',
			});
		},

		createElement(
			chapterId: string,
			pageId: string,
			elementType: number,
			isExercise: boolean,
			content: string,
		) {
			dispatch({
				type: 'createElement',
				payload: {
					chapterId,
					pageId,
					elementType,
					isExercise,
					content,
				},
			});
		},

		changeElement(
			chapterId: string,
			pageId: string,
			elementId: string,
			content: string,
		) {
			dispatch({
				type: 'changeElement',
				payload: {
					chapterId,
					pageId,
					elementId,
					content,
				},
			});
		},

		changeElementPosition(
			chapterId: string,
			dropResult: DropResult,
			isSamePage: boolean,
		) {
			dispatch({
				type: 'changeElementPosition',
				payload: { chapterId, dropResult, isSamePage },
			});
		},

		deleteElement(chapterId: string, pageId: string, elementId: string) {
			dispatch({
				type: 'deleteElement',
				payload: {
					chapterId,
					pageId,
					elementId,
				},
			});
		},

		deleteChapter(chapterId: string) {
			dispatch({
				type: 'deleteChapter',
				payload: { chapterId	},
			});
		},

		deletePage(chapterId: string, pageId: string) {
			dispatch({
				type: 'deletePage',
				payload: { chapterId, pageId	},
			});
		},

		createNewPage(title: string, chapterId: string) {
			dispatch({
				type: 'createNewPage',
				payload: { title, chapterId },
			});
		},

		setCourseGrade(from?: number, to?: number) {
			dispatch({
				type: 'setCourseGrade',
				payload: { from, to },
			});
		},

		changePageTitle(chapterId: string, pageId: string, title: string) {
			dispatch({
				type: 'changePageTitle',
				payload: { chapterId, pageId, title },
			});
		},

		changeAuthors(authors: ForfatterDto[]) {
			dispatch({
				type: 'changeAuthors',
				payload: { authors },
			});
		},
	};
}
