import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './subjects-list.module.scss';

export function SkeletonSubjectsList() {
	const rowsCount = 12;
	const rows: React.ReactNode[] = Array.from(Array(rowsCount))
		.map((element, key) => {
			return [
				<Skeleton
					key={`${key}-checkbox`}
					circle={true}
					width={28}
					height={28}
				/>,
				<Skeleton key={`${key}-text`} height={28} />,
			];
		})
		.flat();

	return <div className={styles.skeletonSubjectsList}>{rows}</div>;
}
