import { v4 as uuidv4 } from 'uuid';
import { initialState, StateType } from './initial-state';
import { GlobalActionType } from './actions/global/types';
import { CourseActionType } from './actions/course/types';
import { CourseLibraryActionType } from './actions/course-library/types';
import { KapitelDto, SideDto, ElementDto, LaeringsmaalDto } from '../../../api';

function shouldUpdateVisPaaMinUddannelse(state: StateType): boolean {
	return state.course.kapitler?.some((k) => k.sider.some((s) => s.elementer.length)) ? state.course.visPaaMinUddannelse : false;
}

export function reducer(
	state: StateType = initialState,
	action: GlobalActionType | CourseActionType | CourseLibraryActionType,
): StateType {
	switch (action.type) {
		// GLOBAL ==================================================================
		case 'setUpdated': {
			return {
				...state,
				isUpdated: true,
			};
		}
		case 'setEditMode': {
			const { name } = action.payload;

			const _editingUnique = Array.from(new Set([...state._editing, name]));

			return {
				...state,
				_editing: _editingUnique,
			};
		}
		case 'setSavedComplete': {
			return {
				...state,
				_editing: [],
				isUpdated: false,
			};
		}
		case 'leaveEditMode': {
			const { name, clearAllEditing } = action.payload;

			if (clearAllEditing) {
				return {
					...state,
					_editing: [],
				};
			}

			return {
				...state,
				_editing: state._editing.filter(item => item !== name),
			};
		}

		// COURSE ==================================================================
		case 'loadCourse': {
			return {
				...state,
				...action.payload,
			};
		}
		case 'changeTitle':
			const { title } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					titel: title,
				},
			};
		case 'changeDescription':
			const { description } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					beskrivelseTilElev: description,
					visIBiblioteket: description ? state.course.visIBiblioteket : false,
					visPaaMinUddannelse: description ? state.course.visPaaMinUddannelse : false,
				},
			};
		case 'changeTeacherInstructions':
			const { teacherInstructions } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					beskrivelseTilLaerer: teacherInstructions,
				},
			};
		case 'changeImage':
			const { image } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					billede: image,
				},
			};
		case 'changeDuration':
			const { duration } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					varighed: duration,
				},
			};

		// SETTINGS ================================================================
		case 'publishToLibrary':
			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					visIBiblioteket: true,
				},
			};
		case 'removeFromLibrary':
			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					visIBiblioteket: false,
					visPaaMinUddannelse: false,
				},
			};
		case 'publishToMinUddannelse':
			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					visPaaMinUddannelse: true,
				},
			};
		case 'removeFromMinUddannelse':
			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					visPaaMinUddannelse: false,
				},
			};

		// SUBJECTS ================================================================
		case 'setSubjects': {
			const { subjects } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					fag: subjects,
				},
			};
		}
		case 'loadSubjects': {
			const { _subjects } = action.payload;

			return {
				...state,
				isUpdated: true,
				_subjects,
			};
		}
		case 'changeCourseSubject':
			return initialState;
		case 'setCourseGrade': {
			const { from, to } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					klassetrinFra: from,
					klassetrinTil: to ?? from,
				},
			};
		}
		case 'changeCourseCategories': {
			const kategorier = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kategorier,
				},
			};
		}

		// CHAPTER =================================================================
		case 'createNewChapter': {
			const { id } = action.payload;

			const model = {
				id,
				overskrift: 'Nyt kapitel',
				laeringsmaal: [],
				sider: [
					new SideDto({
						id: uuidv4(),
						overskrift: 'Nyt afsnit',
						elementer: [],
					}),
				],
			};

			const kapitelModel = new KapitelDto(model);

			const kapitler = state.course.kapitler || [];

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: [...kapitler, kapitelModel],
				},
			};
		}
		case 'changeChapterTitle': {
			const { chapterId, title } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						return new KapitelDto({
							...chapter,
							overskrift: title,
						});
					}),
				},
			};
		}

		case 'deleteChapter': {
			const { chapterId } = action.payload;

			const newState = {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.filter(
						chapter => chapter.id !== chapterId,
					),
				},
			};

			return {
				...newState,
				course: {
					...newState.course,
					visPaaMinUddannelse: shouldUpdateVisPaaMinUddannelse(newState),
				},
			};
		}

		case 'deletePage': {
			const { chapterId, pageId } = action.payload;

			const newState = {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(
						(chapter) => {
							if (chapter.id !== chapterId) {
								return chapter;
							}

							return new KapitelDto({
								...chapter,
								sider: chapter.sider.filter((page) => page.id !== pageId),
							});
						},
					),
				},
			};

			return {
				...newState,
				course: {
					...newState.course,
					visPaaMinUddannelse: shouldUpdateVisPaaMinUddannelse(newState),
				},
			};
		}

		case 'changePageTitle': {
			const { chapterId, pageId, title } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						return new KapitelDto({
							...chapter,
							sider: chapter.sider?.map(page => {
								if (page.id !== pageId) {
									return page;
								}

								return new SideDto({
									...page,
									overskrift: title,
								});
							}),
						});
					}),
				},
			};
		}

		// LEARNING OBJECTIVE ======================================================
		case 'changeLearningObjective': {
			const { chapterId, learningObjective } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course?.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						const model = {
							...chapter,
							laeringsmaal: chapter?.laeringsmaal?.map(lm => {
								if (lm.id !== learningObjective.id) {
									return lm;
								}

								return new LaeringsmaalDto(learningObjective);
							}),
						};

						const kapitelModel = new KapitelDto(model);

						return kapitelModel;
					}),
				},
			};
		}
		case 'createLearningObjective': {
			const { chapterId, newLearningObjective } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						const learningObjectives = chapter.laeringsmaal || [];

						const newChapter = {
							...chapter,
							laeringsmaal: [
								...learningObjectives,
								newLearningObjective,
							],
						};

						return new KapitelDto(newChapter);
					}),
				},
			};
		}
		case 'deleteLearningObjective': {
			const { chapterId, learningObjectiveId } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(
						(chapter: KapitelDto) => {
							if (chapter.id !== chapterId) {
								return chapter;
							}

							const chapterModel = {
								...chapter,
								laeringsmaal: chapter.laeringsmaal?.filter(
									learningObjective =>
										learningObjective.id !==
										learningObjectiveId,
								),
							};

							return new KapitelDto(chapterModel);
						},
					),
				},
			};
		}

		case 'createElement': {
			const {
				chapterId,
				pageId,
				elementType,
				isExercise,
				content,
			} = action.payload;

			const newElement = new ElementDto({
				id: uuidv4(),
				elementType,
				indhold: content,
				erOpgave: isExercise,
			});

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						const chapterModel = {
							...chapter,
							sider: chapter.sider?.map(page => {
								if (page.id !== pageId) {
									return page;
								}

								const pageElements = page.elementer || [];

								const pageModel = {
									...page,
									elementer: [...pageElements, newElement],
								};

								return new SideDto(pageModel);
							}),
						};

						return new KapitelDto(chapterModel);
					}),
				},
			};
		}

		case 'changeElement': {
			const { chapterId, pageId, elementId, content } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						return new KapitelDto({
							...chapter,
							sider: chapter.sider?.map(page => {
								if (page.id !== pageId) {
									return page;
								}

								return new SideDto({
									...page,
									elementer: page.elementer?.map(element => {
										if (element.id !== elementId) {
											return element;
										}

										return new ElementDto({
											...element,
											indhold: content,
										});
									}),
								});
							}),
						});
					}),
				},
			};
		}

		case 'changeElementPosition': {
			const { chapterId, dropResult, isSamePage } = action.payload;

			const toPageId = dropResult.destination?.droppableId;
			const toElementIndex = dropResult.destination?.index;

			if (!toPageId || typeof toElementIndex !== 'number') {
				return initialState;
			}

			const fromPageId = dropResult.source.droppableId;
			const fromElementIndex = dropResult.source.index;

			const draggedElement = state.course.kapitler
				?.find(chapter => chapter.id === chapterId)
				?.sider?.find(page => page.id === fromPageId)?.elementer?.[
				fromElementIndex
			];

			if (!draggedElement) {
				return state;
			}

			let toPage = state.course.kapitler
				?.find(chapter => chapter.id === chapterId)
				?.sider?.find(page => page.id === toPageId);

			if (!toPage) {
				return state;
			}

			// i.e. we move an element to another position within the same page
			if (isSamePage) {
				let newToElements: ElementDto[] = toPage?.elementer || [];
				newToElements?.splice(fromElementIndex, 1);
				newToElements?.splice(
					toElementIndex,
					0,
					new ElementDto(draggedElement),
				);
				toPage = new SideDto({ ...toPage, elementer: newToElements });

				return {
					...state,
					isUpdated: true,
					course: {
						...state.course,
						kapitler: state.course.kapitler?.map(chapter => {
							if (chapter.id !== chapterId) {
								return chapter;
							}

							return new KapitelDto({
								...chapter,
								sider: chapter.sider?.map(page => {
									if (page.id !== toPage?.id) {
										return page;
									}

									return new SideDto(toPage);
								}),
							});
						}),
					},
				};
			}

			// Moving from one page to another
			let fromPage = state.course.kapitler
				?.find(chapter => chapter.id === chapterId)
				?.sider?.find(page => page.id === fromPageId);

			if (!fromPage) {
				return state;
			}

			let newToElements: ElementDto[] = toPage?.elementer || [];
			newToElements.splice(toElementIndex, 0, draggedElement);
			let newFromElements: ElementDto[] = fromPage?.elementer || [];
			newFromElements.splice(fromElementIndex, 1);

			const newToPage = { ...toPage, elementer: newToElements };
			const newFromPage = { ...fromPage, elementer: newFromElements };

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						return new KapitelDto({
							...chapter,
							sider: chapter.sider?.map(page => {
								if (page.id === newToPage.id) {
									return new SideDto(newToPage);
								}

								if (page.id === newFromPage.id) {
									return new SideDto(newFromPage);
								}

								return page;
							}),
						});
					}),
				},
			};
		}
		case 'createNewPage': {
			const { title, chapterId } = action.payload;

			const newPage = new SideDto({
				id: uuidv4(),
				overskrift: title,
				elementer: [],
			});

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						const chapterPages = chapter.sider || [];

						return new KapitelDto({
							...chapter,
							sider: [...chapterPages, newPage],
						});
					}),
				},
			};
		}
		case 'deleteElement': {
			const { chapterId, pageId, elementId } = action.payload;

			const newState = {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					kapitler: state.course.kapitler?.map(chapter => {
						if (chapter.id !== chapterId) {
							return chapter;
						}

						return new KapitelDto({
							...chapter,
							sider: chapter.sider.map(page => {
								if (page.id !== pageId) {
									return page;
								}

								return new SideDto({
									...page,
									elementer: page.elementer.filter(
										element => element.id !== elementId,
									),
								});
							}),
						});
					}),
				},
			};

			return {
				...newState,
				isUpdated: true,
				course: {
					...newState.course,
					visPaaMinUddannelse: shouldUpdateVisPaaMinUddannelse(newState),
				},
			};
		}

		// COURSE LIBRARY ==========================================================
		case 'setCourseLibrarySubject': {
			const subjectIds = action.payload
				?.map(({ id }) => id)
				.filter(Boolean) as string[];

			return {
				...state,
				isUpdated: true,
				courseSearchUpdateTimestamp: new Date().getTime(),
				courseLibrary: {
					...state.courseLibrary,
					fag: subjectIds,
					_subjects: action.payload,
				},
			};
		}

		case 'setCourseLibraryGrade': {
			const { from, to } = action.payload;
			let grades = [from, to].filter(
				grade => grade !== undefined,
			) as number[];

			if (grades.length === 2 && grades[1] - grades[0] > 1) {
				// Fill in the missing in between
				for (let grade = grades[1] - 1; grade > grades[0]; grade--) {
					grades.splice(1, 0, grade);
				}
			}

			return {
				...state,
				isUpdated: true,
				courseSearchUpdateTimestamp: new Date().getTime(),
				courseLibrary: {
					...state.courseLibrary,
					trin: grades.length ? grades : undefined,
				},
			};
		}

		case 'setCourseLibraryCategories': {
			return {
				...state,
				isUpdated: true,
				courseSearchUpdateTimestamp: new Date().getTime(),
				courseLibrary: {
					...state.courseLibrary,
					kategorier: action.payload,
				},
			};
		}

		case 'setCourseLibrarySearchTerms': {
			return {
				...state,
				isUpdated: true,
				courseSearchUpdateTimestamp: new Date().getTime(),
				courseLibrary: {
					...state.courseLibrary,
					term: action.payload,
				},
			};
		}

		case 'setCourseLibraryDraft': {
			return {
				...state,
				isUpdated: true,
				courseSearchUpdateTimestamp: new Date().getTime(),
				courseLibrary: {
					...state.courseLibrary,
					erKladde: action.payload || undefined,
				},
			};
		}

		case 'setCourseLibraryLastSeenId': {
			return {
				...state,
				isUpdated: true,
				courseLibrary: {
					...state.courseLibrary,
					lastSeenId: action.payload,
				},
			};
		}

		case 'changeAuthors': {
			const { authors } = action.payload;

			return {
				...state,
				isUpdated: true,
				course: {
					...state.course,
					forfattere: authors,
				}
			};
		}

		case 'setPlayingVideo': {
			const { id } = action.payload;

			return {
				...state,
				isUpdated: true,
				_playingVideo: id,
			};
		}

		default:
			return state;
	}
}
