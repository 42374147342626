import React from 'react';

import { CourseLibrary } from 'modules/course-library/CourseLibrary';
import { useCourseLibraryPage } from 'hooks/useCourseLibraryPage';
import { fetchCourses } from 'modules/course/api/fetch-courses';
import { BlogPosts } from 'modules/blog-posts/BlogPosts';

export function HomePage() {
	const {
		state,
		actions,
		editControls,
		isLoading,
		courses,
		showMore,
		handleShowMore,
	} = useCourseLibraryPage({ getCourses: fetchCourses });

	return (
		<div>
			<BlogPosts />
			<h2>Undervisningsforløb</h2>
			<CourseLibrary
				courseLibrary={state.courseLibrary}
				actions={actions}
				editControls={editControls}
				courses={courses}
				isLoading={isLoading}
				showMore={showMore}
				onShowMore={handleShowMore}
				canFavorite
			/>
		</div>
	);
}
