import React from 'react';
import { getFavoriteCourses } from 'modules/course/api/get-favorite-courses';
import { CourseLibrary } from 'modules/course-library/CourseLibrary';
import { useCourseLibraryPage } from 'hooks/useCourseLibraryPage';

export function FavoritesPage() {
	const {
		state,
		actions,
		editControls,
		isLoading,
		courses,
		showMore,
		handleShowMore,
	} = useCourseLibraryPage({ getCourses: getFavoriteCourses });

	return (
		<div>
			<div>
				<h2>Favoritter</h2>
			</div>
			<CourseLibrary
				courseLibrary={state.courseLibrary}
				actions={actions}
				editControls={editControls}
				courses={courses}
				isLoading={isLoading}
				showMore={showMore}
				onShowMore={handleShowMore}
				canFavorite
				isFavoritesPage
			/>
		</div>
	);
}
