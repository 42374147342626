import { useEffect, useState } from 'react';
import { getImageUrl } from 'utils/get-file-url';
import { FileUploadStateType } from 'components/file/file-upload/FileUpload';
import { convertJsonToImageElement } from 'modules/course/components/image/utils/convert-json-to-image-element';
import { useRecoilValue } from 'recoil';
import { appConfigState } from 'coils/useRecoilAppConfig';

export const useImage = (
	imageJson?: string,
	width?: number,
	height?: number,
) => {
	const [imgSrc, setImgSrc] = useState<string>();
	const appConfig = useRecoilValue(appConfigState);

	useEffect(() => {
		const getImgSrc = async (image: FileUploadStateType) => {
			if (image?._base?.blobproviderMetadataGuid) {
				setImgSrc(
					getImageUrl(
						appConfig.muBaseUrl,
						image._base.blobproviderMetadataGuid,
						width || 1024,
						height || 512,
					),
				);
			} else {
				setImgSrc(image?.url);
			}
		};

		getImgSrc(convertJsonToImageElement(imageJson));
	}, [imageJson, width, height, appConfig]);

	return imgSrc;
};
